import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { getOperator, addOperator, deleteOperator, updateOperator } from 'redux/actions/operators';
import axios from './axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/**PROXOLAB LOGGER **/
import sendLogService from './proxolabLogger/Logger.js';
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: '',
    text: text,
  });
};
export const getOperatorService = () => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('operator')
    .then(res => {
      dispatch(getOperator(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addOperatorService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post('operator', data)
    .then(res => {
      dispatch(addOperator(res));
      sweetAlerts('success', 'Operator created cuccessfully!');
      dispatch(fetchSuccess());
      sendLogService.sendOperatorLog(res, data);
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const updateOperatorService = data => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put('operator', data)
    .then(res => {
      dispatch(updateOperator(res));
      sweetAlerts('success', 'Operator updated successfully!');
      dispatch(fetchSuccess());
      sendLogService.sendOperatorLog(res, data);
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const deleteOperatorService = id => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`operator/${id}`)
    .then(res => {
      dispatch(deleteOperator(res));
      sweetAlerts('success', 'Operator deleted successfully!');
      dispatch(fetchSuccess());
      sendLogService.sendOperatorLog(res, id);
    })
    .catch(e => {
      sweetAlerts('error', 'Something went wrong!');
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, id);
    });
};
