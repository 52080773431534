import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Common from "./Common";
import Auth from "./Auth";
import allotmentUpdate from "./allotmentUpdate";
import hotels from "./hotels";
import Company from "./Company";
import productTypes from "./productTypes";
import stopSale from "./stopSale";
import rooms from "./rooms";
import destinations from "./destinations";
import AdminCompany from "./CompanyHasOffice";
import User from "./User";
import offers from "./offers";
import Permission from "./Permission";
import operators from "./operators";
import starRates from "./starRates";
import openSale from "./openSale";
import offerTypes from "./offerTypes";
import multiple from "./multiple";
import routes from "./routes";
import giataCodes from "./giataCodes";
import airportCode from "./airportCode";
import operatorHotels from "./operatorHotels";
import hotelMatch from "./hotelMatch";
import operatorRooms from "./operatorRooms";
import roomMatch from "./roomMatch";
import operatorContracts from "./operatorContracts";
import offerList from "./offerList";
import operatorGlobalRooms from "./operatorGlobalRooms";
import getReservationList from "./reservationList";
import hasLoaded from "./hasLoaded";

export default history =>
  combineReducers({
    router: connectRouter(history),
    airportCode,
    allotmentUpdate,
    auth: Auth,
    common: Common,
    company: Company,
    companyHasOffice: AdminCompany,
    destinations,
    giataCodes,
    hotels,
    hotelMatch,
    multiple,
    offers,
    offerTypes,
    offerList,
    openSale,
    operators,
    operatorHotels,
    operatorContracts,
    operatorRooms,
    operatorGlobalRooms,
    permission: Permission,
    productTypes,
    rooms,
    roomMatch,
    routes,
    starRates,
    stopSale,
    user: User,
    getReservationList,
    hasLoaded,
  });
