// hotels
export const GET_HOTELS = "GET_HOTELS";
export const ADD_HOTEL = "ADD_HOTEL";
export const UPDATE_HOTEL = "UPDATE_HOTEL";
export const DELETE_HOTEL = "DELETE_HOTEL";
export const SELECTED_HOTEL = "SELECTED_HOTEL";

// rooms
export const GET_ROOM = "GET_ROOM";
export const ADD_ROOMS = "ADD_ROOMS";
export const ADD_ROOM = "ADD_ROOM";
export const UPDATE_ROOM = "UPDATE_ROOM";
export const DELETE_ROOM = "DELETE_ROOM";
export const GET_EXCEL_ROOM = "GET_EXCEL_ROOM";
export const ADD_EXCEL_ROOM = "ADD_EXCEL_ROOM";
export const UPDATE_EXCEL_ROOM = "UPDATE_EXCEL_ROOM";
export const DELETE_EXCEL_ROOM = "DELETE_EXCEL_ROOM";

// operators
export const GET_OPERATOR = "GET_OPERATOR";
export const ADD_OPERATOR = "ADD_OPERATOR";
export const UPDATE_OPERATOR = "UPDATE_OPERATOR";
export const DELETE_OPERATOR = "DELETE_OPERATOR";

// product_types
export const GET_PRODUCT_TYPES = "GET_PRODUCT_TYPES";
export const ADD_PRODUCT_TYPE = "ADD_PRODUCT_TYPE";
export const UPDATE_PRODUCT_TYPE = "UPDATE__PRODUCT_TYPE";
export const DELETE_PRODUCT_TYPE = "DELETE_PRODUCT_TYPE";

// boards
export const GET_BOARDS = "GET_BOARDS";
export const ADD_BOARD = "ADD_BOARD";
export const UPDATE_BOARD = "UPDATE_BOARD";
export const DELETE_BOARD = "DELETE_BOARD";

// offices
/* export const GET_OFFICES = 'GET_OFFICES';
export const ADD_OFFICE = 'ADD_OFFICE';
export const UPDATE_OFFICE = 'UPDATE_OFFICE'; */

// Seasons
export const GET_SEASONS = "GET_SEASONS";
export const ADD_SEASONS = "ADD_SEASONS";
export const UPDATE_SEASONS = "UPDATE_SEASONS";
export const DELETE_SEASONS = "DELETE_SEASONS";

// AddPaxRates
export const GET_ADDPAXRATES = "GET_ADDPAXRATES";
export const ADD_ADDPAXRATES = "ADD_ADDPAXRATES";
export const UPDATE_ADDPAXRATES = "UPDATE_ADDPAXRATES";
export const DELETE_ADDPAXRATES = "DELETE_ADDPAXRATES";
export const ISITDONE = "ISITDONE";

// Dashboard
export const GET_DASHBOARD = "GET_DASHBOARD";
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD";

// Extras
export const GET_EXTRA = "GET_EXTRA";
export const ADD_EXTRA = "ADD_EXTRA";
export const UPDATE_EXTRA = "UPDATE_EXTRA";
export const DELETE_EXTRA = "DELETE_EXTRA";
export const GET_ALLEXTRA = "GET_ALLEXTRA";

// Offers
export const GET_OFFERS = "GET_OFFERS";
export const ADD_OFFER = "ADD_OFFER";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const DELETE_OFFER = "DELETE_OFFER";

// StopSale
export const GET_STOP_SALE = "GET_STOP_SALE";
export const ADD_STOP_SALE = "ADD_STOP_SALE";

// OpenSale
export const GET_OPEN_SALE = "GET_OPEN_SALE";
export const ADD_OPEN_SALE = "ADD_OPEN_SALE";

// Allotment Update
export const GET_ALLOTMENTUPDATE = "GET_ALLOTMENTUPDATE";
export const ADD_ALLOTMENTUPDATE = "ADD_ALLOTMENTUPDATE";
export const UPDATE_ALLOTMENTUPDATE = "UPDATE_ALLOTMENTUPDATE";

//OFFER LIST
export const GET_OFFER_LIST = "GET_OFFER_LIST";
export const DELETE_OFFER_LIST = "DELETE_OFFER_LIST";
export const CLEAR_OFFER_LIST = "CLEAR_OFFER_LIST";

// Reservations
export const GET_RESERVATIONS = "GET_RESERVATIONS";
export const ADD_RESERVATION = "ADD_RESERVATION";
export const UPDATE_RESERVATION = "UPDATE_RESERVATION";
export const DELETE_RESERVATION = "DELETE_RESERVATION";
export const SEND_HOTEL_MAIL = "SEND_HOTEL_MAIL";
export const SEND_HOTEL_RESPONSE = "SEND_HOTEL_RESPONSE";

// markups
export const GET_MARKUP = "GET_MARKUP";
export const ADD_MARKUP = "ADD_MARKUP";
export const UPDATE_MARKUP = "UPDATE_MARKUP";
export const DELETE_MARKUP = "DELETE_MARKUP";

// address
export const GET_REGIONS = "GET_REGIONS";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const GET_DIVISIONS = "GET_DIVISIONS";
export const GET_CITIES = "GET_CITIES";
export const GET_SUB_CITIES = "GET_SUB_CITIES";
export const RESET_COUNTRIES = "RESET_COUNTRIES";
export const RESET_DIVISIONS = "RESET_DIVISIONS";
export const RESET_CITIES = "RESET_CITIES";
export const RESET_SUB_CITIES = "RESET_SUB_CITIES";

// rateRanges
export const ADD_RATE_RANGE = "ADD_RATE_RANGE";
export const UPDATE_RATE_RANGE = "UPDATE_RATE_RANGE";
export const DELETE_RATE_RANGE = "DELETE_RATE_RANGE";

//contract
export const GET_CONTRACT = "GET_CONTRACT";
export const ADD_CONTRACT = "ADD_CONTRACT";
export const UPDATE_CONTRACT = "UPDATE_CONTRACT";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const IS_SAVED_CONTRACT = "IS_SAVED_CONTRACT";
export const IS_UPDATE_CONTRACT = "IS_UPDATE_CONTRACT";

//contract versions
export const GET_VERSIONS = "GET_VERSIONS";

//star rates
export const GET_STAR_RATES = "GET_STAR_RATES";

// boardSupplament
export const GET_BOARD_SUPPLAMENT = "GET_BOARD_SUPPLAMENT";
export const ADD_BOARD_SUPPLAMENT = "ADD_BOARD_SUPPLAMENT";
export const UPDATE_BOARD_SUPPLAMENT = "UPDATE_BOARD_SUPPLAMENT";
export const DELETE_BOARD_SUPPLAMENT = "DELETE_BOARD_SUPPLAMENT";
export const ADD_BOARD_SUPPLAMENT_REDUCTION = "ADD_BOARD_SUPPLAMENT_REDUCTION";
export const UPDATE_BOARD_SUPPLAMENT_REDUCTION =
  "UPDATE_BOARD_SUPPLAMENT_REDUCTION";
export const DELETE_BOARD_SUPPLAMENT_REDUCTION =
  "DELETE_BOARD_SUPPLAMENT_REDUCTION";

//period
export const GET_PERIOD = "GET_PERIOD";
export const ADD_PERIOD = "ADD_PERIOD";
export const UPDATE_PERIOD = "UPDATE_PERIOD";
export const DELETE_PERIOD = "DELETE_PERIOD";
export const PERIOD_ROW_STATUS = "PERIOD_ROW_STATUS";

//markets
export const GET_MARKETS = "GET_MARKETS";
export const ADD_MARKET = "ADD_MARKET";
export const UPDATE_MARKET = "UPDATE_MARKET";
export const DELETE_MARKET = "DELETE_MARKET";

//service
export const GET_SERVICES = "GET_SERVICES";
export const ADD_SERVICE = "ADD_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";

//contractRoom
export const GET_CONTRACT_ROOM = "GET_CONTRACT_ROOM";
export const ADD_CONTRACT_ROOM = "ADD_CONTRACT_ROOM";
export const UPDATE_CONTRACT_ROOM = "UPDATE_CONTRACT_ROOM";
export const DELETE_CONTRACT_ROOM = "DELETE_CONTRACT_ROOM";
//currency
export const GET_CURRENCY = "GET_CURRENCY";

//on sale contract
export const GET_ONSALE_CONTRACT = "GET_ONSALE_CONTRACT";
export const ADD_ONSALE_CONTRACT = "ADD_ONSALE_CONTRACT";

// User Permission
export const GET_USER_PERMISSION = "GET_USER_PERMISSION";
export const ADD_USER_PERMISSION = "ADD_USER_PERMISSION";
export const UPDATE_USER_PERMISSION = "UPDATE_USER_PERMISSION";
export const DELETE_USER_PERMISSION = "DELETE_USER_PERMISSION";

// Allotment Type
export const GET_ALLOTMENT_TYPE = "GET_ALLOTMENT_TYPE";

//rateType
export const GET_RATE_TYPES = "GET_RATE_TYPES";

//operatorCredential
export const GET_OPERATOR_CREDENTIAL = "GET_OPERATOR_CREDENTIAL";
export const ADD_OPERATOR_CREDENTIAL = "ADD_OPERATOR_CREDENTIAL";

//set selected contract
export const SET_SELECTED_CONTRACT = "SET_SELECTED_CONTRACT";
//Restrictions
export const GET_RESTRICTIONS = "GET_RESTRICTIONS";
export const ADD_RESTIRICTION = "ADD_RESTIRICTION";
export const UPDATE_RESTRICTIONS = "UPDATE_RESTRICTIONS";
export const DELETE_RESTRICTIONS = "DELETE_RESTRICTIONS";

export const ADD_OCCUPANCY = "ADD_OCCUPANCY";
export const ADD_CLOSEOUT = "ADD_CLOSEOUT";
export const ADD_RELEASE = "ADD_RELEASE";
export const ADD_ALLOTMENT = "ADD_ALLOTMENT";
export const ADD_CINCOUT = "ADD_CINCOUT";

export const UPDATE_OCCUPANCY = "UPDATE_OCCUPANCY";
export const UPDATE_CLOSEOUT = "UPDATE_CLOSEOUT";
export const UPDATE_RELEASE = "UPDATE_RELEASE";
export const UPDATE_ALLOTMENT = "UPDATE_ALLOTMENT";
export const UPDATE_CINCOUT = "UPDATE_CINCOUT";

export const DELETE_OCCUPANCY = "DELETE_OCCUPANCY";
export const DELETE_CLOSEOUT = "DELETE_CLOSEOUT";
export const DELETE_RELEASE = "DELETE_RELEASE";
export const DELETE_ALLOTMENT = "DELETE_ALLOTMENT";
export const DELETE_CINCOUT = "DELETE_CINCOUT";

//stayType
export const GET_STAY_TYPES = "GET_STAY_TYPES";

//offer type
export const GET_OFFER_TYPES = "GET_OFFER_TYPES";

// Special Notes
export const GET_SPECIAL_NOTES = "GET_SPECIAL_NOTES";
export const ADD_SPECIAL_NOTES = "ADD_SPECIAL_NOTES";
export const UPDATE_SPECIAL_NOTES = "UPDATE_SPECIAL_NOTES";
export const DELETE_SPECIAL_NOTES = "DELETE_SPECIAL_NOTES";

//Cancellation Policies Details
export const ADD_CANCELLATION_POLICIES_DETAIL =
  "ADD_CANCELLATION_POLICIES_DETAIL";
export const UPDATE_CANCELLATION_POLICIES_DETAIL =
  "UPDATE_CANCELLATION_POLICIES_DETAIL";
export const DELETE_CANCELLATION_POLICIES_DETAIL =
  "DELETE_CANCELLATION_POLICIES_DETAIL";

//Cancellation Policies
export const GET_CANCELLATION_POLICIES = "GET_CANCELLATION_POLICIES";
export const ADD_CANCELLATION_POLICIES = "ADD_CANCELLATION_POLICIES";
export const UPDATE_CANCELLATION_POLICIES = "UPDATE_CANCELLATION_POLICIES";
export const DELETE_CANCELLATION_POLICIES = "DELETE_CANCELLATION_POLICIES";

//Contract Cancellation Policies
export const GET_CONTRACT_CANCELLATION_POLICIES =
  "GET_CONTRACT_CANCELLATION_POLICIES";
export const ADD_CONTRACT_CANCELLATION_POLICIES =
  "ADD_CONTRACT_CANCELLATION_POLICIES";
export const UPDATE_CONTRACT_CANCELLATION_POLICIES =
  "UPDATE_CONTRACT_CANCELLATION_POLICIES";
export const DELETE_CONTRACT_CANCELLATION_POLICIES =
  "DELETE_CONTRACT_CANCELLATION_POLICIES";
export const GET_ALLCONTRACT_CANCELLATION_POLICIES =
  "GET_ALLCONTRACT_CANCELLATION_POLICIES";

//days
export const GET_DAYS = "GET_DAYS";
//RestrictionStatus
export const GET_STATUS = "GET_STATUS";
//RestrictionStatus
export const GET_TYPES = "GET_TYPES";
// Allotment Action Type
export const GET_ALLOTMENTACTIONTYPE = "GET_ALLOTMENTACTIONTYPE";

// Kickbacks

export const ADD_TURNOVER_BASIS = "ADD_TURNOVER_BASIS";
export const GET_TURNOVER_BASIS = "GET_TURNOVER_BASIS";
export const UPDATE_TURNOVER_BASIS = "UPDATE_TURNOVER_BASIS";
export const DELETE_TURNOVER_BASIS = "DELETE_TURNOVER_BASIS";

export const ADD_CONTRIBUTION_FEE = "ADD_CONTRIBUTION_FEE";
export const GET_CONTRIBUTION_FEE = "GET_CONTRIBUTION_FEE";
export const UPDATE_CONTRIBUTION_FEE = "UPDATE_CONTRIBUTION_FEE";
export const DELETE_CONTRIBUTION_FEE = "DELETE_CONTRIBUTION_FEE";

export const ADD_REPRESENTATIVE_FEE = "ADD_REPRESENTATIVE_FEE";
export const GET_REPRESENTATIVE_FEE = "GET_REPRESENTATIVE_FEE";
export const UPDATE_REPRESENTATIVE_FEE = "UPDATE_REPRESENTATIVE_FEE";
export const DELETE_REPRESENTATIVE_FEE = "DELETE_REPRESENTATIVE_FEE";

export const ADD_ADVERTISING_FEE = "ADD_ADVERTISING_FEE";
export const GET_ADVERTISING_FEE = "GET_ADVERTISING_FEE";
export const UPDATE_ADVERTISING_FEE = "UPDATE_ADVERTISING_FEE";
export const DELETE_ADVERTISING_FEE = "DELETE_ADVERTISING_FEE";

export const ADD_MARKETING_PROMOTION = "ADD_MARKETING_PROMOTION";
export const GET_MARKETING_PROMOTION = "GET_MARKETING_PROMOTION";
export const UPDATE_MARKETING_PROMOTION = "UPDATE_MARKETING_PROMOTION";
export const DELETE_MARKETING_PROMOTION = "DELETE_MARKETING_PROMOTION";

// Banks
export const GET_BANKS = "GET_BANKS";
// PAYMENT METHODS
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";

//Payment Conditions
export const GET_PAYMENT_CONDITIONS = "GET_PAYMENT_CONDITIONS";
export const ADD_PAYMENT_CONDITIONS = "ADD_PAYMENT_CONDITIONS";
export const UPDATE_PAYMENT_CONDITIONS = "UPDATE_PAYMENT_CONDITIONS";
export const DELETE_PAYMENT_CONDITIONS = "DELETE_PAYMENT_CONDITIONS";

//Terms & Conditions
export const GET_TERMS_CONDITIONS = "GET_TERMS_CONDITIONS";
export const ADD_TERMS_CONDITIONS = "ADD_TERMS_CONDITIONS";
export const UPDATE_TERMS_CONDITIONS = "UPDATE_TERMS_CONDITIONS";
export const DELETE_TERMS_CONDITIONS = "DELETE_TERMS_CONDITIONS";

export const ADD_CATALOGUE_CONTRIBUTION = "ADD_CATALOGUE_CONTRIBUTION";
export const GET_CATALOGUE_CONTRIBUTION = "GET_CATALOGUE_CONTRIBUTION";
export const UPDATE_CATALOGUE_CONTRIBUTION = "UPDATE_CATALOGUE_CONTRIBUTION";
export const DELETE_CATALOGUE_CONTRIBUTION = "DELETE_CATALOGUE_CONTRIBUTION";

//Contract Terms & Conditions
export const GET_CONTRACT_TERMS_CONDITIONS = "GET_CONTRACT_TERMS_CONDITIONS";
export const GET_ALLCONTRACT_TERMS_CONDITIONS =
  "GET_ALLCONTRACT_TERMS_CONDITIONS";
export const ADD_CONTRACT_TERMS_CONDITIONS = "ADD_CONTRACT_TERMS_CONDITIONS";
export const UPDATE_CONTRACT_TERMS_CONDITIONS =
  "UPDATE_CONTRACT_TERMS_CONDITIONS";
export const DELETE_CONTRACT_TERMS_CONDITIONS =
  "DELETE_CONTRACT_TERMS_CONDITIONS";

//FOR OFFER
//Apply Type for Free Night Offer
export const GET_APPLY_TYPE = "GET_APPLY_TYPE";
export const GET_ARRIVAL_STAY = "GET_ARRIVAL_STAY";
export const GET_MULTIPLE = "GET_MULTIPLE";
export const GET_UPGRADE_TYPES = "GET_UPGRADE_TYPES";

export const CHANGE_CONTRACT_STATUS = "CHANGE_CONTRACT_STATUS";

//routes
export const GET_ROUTES = "GET_ROUTES";

//Hotel Match
export const GET_HOTEL_MATCH = "GET_HOTEL_MATCH";
export const ADD_HOTEL_MATCH = "ADD_HOTEL_MATCH";
export const UPDATE_HOTEL_MATCH = "UPDATE_HOTEL_MATCH";
export const DELETE_HOTEL_MATCH = "DELETE_HOTEL_MATCH";

//Copy Contract
export const COPY_CONTRACT = "COPY_CONTRACT";

//Priority
export const GET_PRIORITY = "GET_PRIORITY";
export const ADD_PRIORITY = "ADD_PRIORITY";
export const UPDATE_PRIORITY = "UPDATE_PRIORITY";
export const DELETE_PRIORITY = "DELETE_PRIORITY";

//giata code
export const GET_GIATA_CODE = "GET_GIATA_CODE";

//airport code
export const GET_AIRPORT_CODE = "GET_AIRPORT_CODE";

//add pax eklendi mi special rate offerının içinden
export const ADDED_SPR_ADD_PAX = "ADDED_SPR_ADD_PAX";

//room Match
export const GET_ROOM_MATCH = "GET_ROOM_MATCH";
export const ADD_ROOM_MATCH = "ADD_ROOM_MATCH";
export const UPDATE_ROOM_MATCH = "UPDATE_ROOM_MATCH";
export const DELETE_ROOM_MATCH = "DELETE_ROOM_MATCH";

//operator hotels and rooms
export const GET_OPERATOR_HOTELS = "GET_OPERATOR_HOTELS";
export const GET_OPERATOR_ROOMS = "GET_OPERATOR_ROOMS";
export const UPDATE_OPERATOR_ROOMS = "UPDATE_OPERATOR_ROOMS";
export const GET_MATCHED_ROOMS_BY_HOTEL = "GET_MATCHED_ROOMS_BY_HOTEL";
export const GET_HOTEL_CONTRACTS = "GET_HOTEL_CONTRACTS";
export const GET_OPERATOR_GLOBAL_ROOMS = "GET_OPERATOR_GLOBAL_ROOMS";
export const ADD_OPERATOR_ROOMS = "ADD_OPERATOR_ROOMS";

//Help Mail
export const SEND_HELP_MAIL = "SEND_HELP_MAIL";

export const SPECIAL_REDUCTION_RESET = "SPECIAL_REDUCTION_RESET";
export const SPECICAL_REDUCTION_COPY = "SPECICAL_REDUCTION_COPY";

//get users
export const GET_ALL_USERS = "GET_ALL_USERS";

//check if contract data is updated
export const CHECK_CONTRACT_DATA_IS_UPDATE = "CHECK_CONTRACT_DATA_IS_UPDATE";

//get offer transaction onbot
export const GET_BOT_OFFER = "GET_BOT_OFFER";

//reservationOfferList
export const GET_RESERVATION_LIST = "GET_RESERVATION_LIST";

// Has Loaded
export const ADD_DASHBOARD_HAS_LOADED = "ADD_DASHBOARD_HAS_LOADED";
export const ADD_DEFINITIONS_HAS_LOADED = "ADD_DEFINITIONS_HAS_LOADED";
export const ADD_ADD_RESERVATIONS_HAS_LOADED =
  "ADD_ADD_RESERVATIONS_HAS_LOADED";
export const ADD_SHOW_RESERVATIONS_HAS_LOADED =
  "ADD_SHOW_RESERVATIONS_HAS_LOADED";
export const ADD_NEW_CONTRACT_HAS_LOADED = "ADD_NEW_CONTRACT_HAS_LOADED";
export const GET_DASHBOARD_HAS_LOADED = "GET_DASHBOARD_HAS_LOADED";
export const GET_DEFINITIONS_HAS_LOADED = "GET_DEFINITIONS_HAS_LOADED";
export const GET_ADD_RESERVATIONS_HAS_LOADED =
  "GET_ADD_RESERVATIONS_HAS_LOADED";
export const GET_SHOW_RESERVATIONS_HAS_LOADED =
  "GET_SHOW_RESERVATIONS_HAS_LOADED";

// MEETPOINT EXCEL
export const ADD_MEETPOINT_EXCEL = "ADD_MEETPOINT_EXCEL";

//BOARD MAP
export const GET_BOARD_MAP = "GET_BOARD_MAP";
export const ADD_BOARD_MAP = "ADD_BOARD_MAP";
export const DELETE_BOARD_MAP = "DELETE_BOARD_MAP";
