import { normalize, schema } from 'normalizr';

const users = new schema.Entity(
  'users',
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA, ...entityB };
    },
  },
);

const response = {
  users: [users],
  addUsers : [users],
};
export const normalizeUsers = data => normalize(data, [response]);
