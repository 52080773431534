import React, { useState, useEffect } from "react";
import axios from "services/axios";
import { Grid, Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green, red, orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
import axiosBot from "../../../services/axiosBot";
import { apihost } from "../../../services/axiosBot";

const theme = createTheme({
  palette: {
    backgroundColor: green[400],
  },
});

const AISelectedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[400]),
    backgroundColor: green[400],
    "&:hover": {
      backgroundColor: green[500],
    },
  },
}))(Button);
const NoReasonButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[400]),
    backgroundColor: red[400],
    "&:hover": {
      backgroundColor: red[500],
    },
  },
}))(Button);
const ResultWrongButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500],
    },
  },
}))(Button);

function AIDialog() {
  const [open, setOpen] = useState(false);
  const [AIData, setAIData] = useState();
  const [control, setControl] = useState(true);
  const [count, setCount] = useState(0);
  const [contract, setContract] = useState();
  const [operatorArray, setOperatorArray] = useState();
  const [WEBBEDScontract, setWEBBEDScontract] = useState("");
  const [PEAKPOINTcontract, setPEAKPOINTcontract] = useState("");
  const [LASTMINUTEcontract, setLASTMINUTEcontract] = useState("");
  const [openContract, setOpenContract] = useState(false);

  const [modalData, setModalData] = useState();
  const { authUser } = useSelector(({ auth }) => auth);

  // Her 10 saniyede bir istek atacak ve eğer istek atıldığında active data gelirse modal açık kaldığı sürece bekleyecek olan useEffect
  useEffect(() => {
    if (!open && control) {
      const interval = setInterval(() => {
        getAIData();
      }, 10 * 1000);
      return () => clearInterval(interval);
    }
  }, [control]);

  const getContractOptions = (name) => {
    let tempContract = [];
    if (contract?.length > 0) {
      contract.map((contract) => {
        if (contract.operator_name == name) {
          tempContract.push(contract);
        }
      });
    }

    return tempContract;
  };

  let tempOperatorArray = [];

  // Yukarıdaki useEffecte bağlı olarak çalışan ve istek atıp stateleri güncelleyen fonksiyon
  const getAIData = () => {
    tempOperatorArray = [];

    axios.get("activeAiResults").then(function(res) {
      if (res.data.code === 200) {
        if (res.data.data != null) {
          setAIData(res.data.data);
          setControl(false);
          setOpen(true);
          setModalData(res.data.data[0]);
          if (res.data.data[0].contracts)
            res.data.data[0].contracts.map((contract) => {
              if (!tempOperatorArray.includes(contract.operator_name)) {
                tempOperatorArray.push(contract.operator_name);
              }
            });
          setContract(res.data.data[0].contracts);
        }
        setOperatorArray(tempOperatorArray);
      }
    });
  };

  // İşlem yapılacak data bittiğinde yeni gelecek datalar ile karışmaması için stateyi sıfırlıyoruz
  const resetState = () => {
    setOpen(false);
    setCount(0);
    setControl(true);
    setAIData();
    setModalData();
    setContract([]);
    setOpenContract(false);
    setWEBBEDScontract("");
    setLASTMINUTEcontract("");
    setPEAKPOINTcontract("");
  };

  const handleClickAISelected = (data) => {
    if (contract.length > 0 && !openContract) {
      setOpenContract(true);
    } else {
      axios
        .put("aiResult", {
          _id: data?._id,
          status: 2,
          WEBBEDScontract: WEBBEDScontract,
          PEAKPOINTcontract: PEAKPOINTcontract,
          LASTMINUTEcontract: LASTMINUTEcontract,
          botEndpoint: axiosBot.defaults.baseURL,
          endpoint: apihost,
        })
        .then(function(res) {
          if (res.data.code === 200) {
            if (res.data.data != null) {
              setModalData(AIData[count + 1]);
              setCount(count + 1);
              setContract(AIData[count + 1]?.contracts);
              setOpenContract(false);
              setWEBBEDScontract("");
              setLASTMINUTEcontract("");
              setPEAKPOINTcontract("");
            }
          }
        });
      if (count + 1 == AIData.length) {
        resetState();
      }
    }
  };
  const handleClickNoReason = (data) => {
    axios.put("aiResult", { _id: data?._id, status: 3 }).then(function(res) {
      if (res.data.code === 200) {
        if (res.data.data != null) {
          setModalData(AIData[count + 1]);
          setCount(count + 1);
        }
      }
    });
    if (count + 1 == AIData.length) {
      resetState();
    }
  };

  const handleClickResultWrong = (data) => {
    axios.put("aiResult", { _id: data?._id, status: 4 }).then(function(res) {
      if (res.data.code === 200) {
        if (res.data.data != null) {
          setModalData(AIData[count + 1]);
          setCount(count + 1);
        }
      }
    });
    if (count + 1 == AIData.length) {
      resetState();
    }
  };

  // Seçilen kontratı setlediğimiz yer
  const handleChangeContract = (value) => {
    if (value.operator_name == "PEAKPOINT") {
      setPEAKPOINTcontract(value.contract_id);
    }
    if (value.operator_name == "WEBBEDS") {
      setWEBBEDScontract(value.contract_id);
    }
    if (value.operator_name == "LAST MINUTE") {
      setLASTMINUTEcontract(value.contract_id);
    }
  };

  return (
    authUser &&
    !!modalData && (
      <Dialog open={open} disableEscapeKeyDown maxWidth="md" fullWidth="on">
        <DialogTitle style={{ display: "flex", justifyContent: "flex-end" }}>
          <Stack direction="row" spacing={1}>
            <Chip label={AIData?.length - count} />
          </Stack>
        </DialogTitle>
        <DialogContent style={{ height: "auto", overflow: "scroll" }}>
          <Grid container spacing={10}>
            <Grid item xs={12} sx={{ marginBottom: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <Typography fontWeight={"bold"} variant="h6" gutterBottom>
                  E-MAIL
                </Typography>
                <Typography
                  fontWeight={"bold"}
                  variant="h6"
                  component="h4"
                  gutterBottom
                >
                  {modalData?.email}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider
            sx={{
              margin: "10px 0px",
              borderBottomWidth: 2,
              backgroundColor: "black",
            }}
          />
          {modalData?.processes?.map((data) => {
            return (
              <>
                <Grid container key={data.hotel_id}>
                  <Grid item xs={12} sx={{ padding: "10px !important" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        fontWeight={"bold"}
                        variant="body1"
                        gutterBottom
                      >
                        Hotel Name:
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {modalData?.hotel_name}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        fontWeight={"bold"}
                        variant="body1"
                        gutterBottom
                      >
                        Room Name:
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {data.room_name}
                      </Typography>
                    </Box>
                    {data.dates.map((date) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            fontWeight={"bold"}
                            variant="body1"
                            gutterBottom
                          >
                            Dates:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {`${date.start_date} -  ${date.end_date}`}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Grid>
                </Grid>
                <Divider
                  sx={{
                    margin: "10px 0px",
                    borderBottomWidth: 2,
                    backgroundColor: "black",
                  }}
                />
              </>
            );
          })}

          <Grid container spacing={10}>
            {openContract &&
              operatorArray?.map((op) => {
                return (
                  <Grid item xs={4}>
                    <Autocomplete
                      options={getContractOptions(op)}
                      onChange={(event, value) => {
                        handleChangeContract(value);
                      }}
                      getOptionLabel={(option) => option?.contract_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={op + " contracts"}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                );
              })}
            {openContract && (
              <Grid
                item
                sx={{ display: "flex", alignItems: "flex-end" }}
                xs={12}
              >
                <ThemeProvider theme={theme}>
                  <AISelectedButton
                    variant="contained"
                    color="backgroundColor"
                    disabled={!openContract}
                    onClick={() => handleClickAISelected(modalData)}
                  >
                    <IntlMessages id="save" />
                  </AISelectedButton>
                </ThemeProvider>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <ThemeProvider theme={theme}>
                <NoReasonButton
                  variant="contained"
                  color="backgroundColor"
                  onClick={() => handleClickNoReason(modalData)}
                >
                  <IntlMessages id="no.reason" />
                </NoReasonButton>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <AISelectedButton
                  variant="contained"
                  color="backgroundColor"
                  disabled={openContract}
                  onClick={() => handleClickAISelected(modalData)}
                >
                  <IntlMessages id="AI.selected" />
                </AISelectedButton>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <ResultWrongButton
                  variant="contained"
                  color="backgroundColor"
                  onClick={() => handleClickResultWrong(modalData)}
                >
                  <IntlMessages id="AI.result.is.wrong" />
                </ResultWrongButton>
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  );
}
export default AIDialog;
