// import axios from "axios";

// export default axios.create({
//     baseURL: "http://localhost:85/api/", //YOUR_API_URL HERE
//     headers: {
//         "Content-Type": "application/json",
//     },
// });

////stage api

import axios from "axios";

export default axios.create({
    baseURL: "https://stagebot.proxolab.com/api/", //YOUR_API_URL HERE
    headers: {
        "Content-Type": "application/json",
    },
});


// // live api (keyf)

// import axios from "axios";

// export default axios.create({
//     baseURL: "https://botapi.mundosai.com/api/", //YOUR_API_URL HERE
//     headers: {
//         "Content-Type": "application/json",
//     },
// });


// //meeting point api

// import axios from "axios";

// export default axios.create({
//     baseURL: "https://mpapi.mundosai.com/api/", //YOUR_API_URL HERE
//     headers: {
//         "Content-Type": "application/json",
//     },
// });