import React, { Fragment, useEffect, useState } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import {
  addOperatorService,
  deleteOperatorService,
  getOperatorService,
  updateOperatorService
} from "services/operator";
import { useDispatch, useSelector } from "react-redux";
import { getCountriesService } from "services/destinations";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import {
  createTheme,
  makeStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import { blue, orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import MultiSelect from "../Common/MultiSelect";
import { multiDataSetter } from "@jumbo/utils/commonHelper";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "./Loader";

const theme = createTheme({
  palette: {
    backgroundColor: orange[400]
  }
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500]
    }
  }
}))(Button);

const InfoButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(blue[400]),
    backgroundColor: blue[400],
    "&:hover": {
      backgroundColor: blue[500]
    }
  }
}))(Button);

const CountryModalStyles = makeStyles(theme => ({
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "1px solid #000",
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: 30,
    overflowY: "scroll",
    maxHeight: "500px",
    width: "500px"
  }
}));

const MySwal = withReactContent(Swal);

export default function NewOperator({ isAuthAdd, isAuthDelete, isAuthUpdate }) {
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [operatorCountries, setOperatorCountries] = useState([]);

  const options = [
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry", disabled: true }
  ];
  const [selected, setSelected] = useState([]);
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.values(operators).length === 0) {
      dispatch(getOperatorService());
    }
    if (Object.values(countriesState).length === 0) {
      dispatch(getCountriesService(["", 0]));
    }
  }, [dispatch]);

  const tableRef = React.createRef();
  const { operators } = useSelector(({ operators }) => operators);
  const countriesState = useSelector(state => state.destinations.countries);
  const checkCountryHandler = data => {
    const countryNames = countriesState.reduce((acc, country) => {
      return data.includes(country.id) ? [...acc, country.name] : acc;
    }, []);

    setOperatorCountries(countryNames);
    setShowCountryModal(!showCountryModal);
  };

  const countryModalClasses = CountryModalStyles();
  const tableColumns = [
    {
      title: intl.formatMessage({ id: "code" }),
      field: "code",
      width: "20%",
      validate: rowData => !!rowData.code
    },
    {
      title: intl.formatMessage({ id: "name" }),
      field: "name",
      width: "20%",
      validate: rowData => !!rowData.name
    },
    // {
    //   title: intl.formatMessage({ id: "countries" }),
    //   field: "countries",
    //   type: "string",
    //   //cellStyle: { wordBreak: 'break-all' },
    //   editComponent: ({ onChange, value, rowData }) => {
    //     if (
    //       value !== undefined &&
    //       value !== null &&
    //       value.length > 0 &&
    //       typeof value[0] !== "object"
    //     ) {
    //       value = countriesState.filter(country => value.includes(country.id));
    //     }

    //     return (
    //       countriesState.length > 0 && (
    //         <MultiSelect
    //           error={value == undefined || value?.length == 0}
    //           options={multiDataSetter("name", "id", countriesState)}
    //           value={multiDataSetter("name", "id", value)}
    //           handleChange={e => onChange(e)}
    //           labelledBy="Select"
    //           overrideStrings={{ selectSomeItems: "Select countries" }}
    //         />
    //         // <SelectAllTest
    //         //   options={countriesState}
    //         //   labelKey={"name"}
    //         //   valueKey={"id"}
    //         //   // data={data.rowData.countries}
    //         //   placeholder={"Countries"}
    //         // />
    //       )
    //     );
    //   },
    //   render: rowData => {
    //     rowData.countries = rowData.countries ? rowData.countries : [];
    //     if (rowData?.countries?.length === 0) {
    //       return "No Country Selected";
    //     }

    //     if (rowData?.countries?.length > 4) {
    //       return (
    //         <InfoButton
    //           onClick={() => checkCountryHandler(rowData.countries)}
    //           variant="contained"
    //           color="backgroundColor"
    //           startIcon={<InfoIcon />}
    //         >
    //           <IntlMessages id="show.countries" style={{ color: "#fff" }} />
    //         </InfoButton>
    //       );
    //     } else {
    //       let countryArr = [];
    //       rowData.countries.map((item, idx) => {
    //         if (idx !== rowData.countries.length - 1) {
    //           countryArr.push(
    //             countriesState.find(country => country.id === item)?.name + ", "
    //           );
    //         } else {
    //           countryArr.push(
    //             countriesState.find(country => country.id === item)?.name
    //           );
    //         }
    //       });
    //       return countryArr;
    //     }
    //   }
    // }
  ];

  return (
    <Fragment>
      <MaterialTable
        tableRef={tableRef}
        columns={tableColumns}
        data={Object.values(operators)}
        title=""
        options={{
          tableLayout: "fixed",
          actionsColumnIndex: -1,
          search: false,
          filtering: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40],
          addRowPosition: "first",
          rowStyle: {
            minHeight: "40px",
            maxHeight: "40px"
          },
          headerStyle: {
            fontSize: "16px",
            fontWeight: "bold"
          }
        }}
        actions={[
          isAuthAdd
            ? undefined
            : {
                icon: "add",
                disabled: true,
                position: "toolbar",
                tooltip: "You are not authorized"
              }
        ]}
        icons={{
          Add: props => (
            <ThemeProvider theme={theme}>
              <ColorButton
                variant="contained"
                color="backgroundColor"
                startIcon={<AddBoxIcon />}
              >
                <IntlMessages id="add" />
              </ColorButton>
            </ThemeProvider>
          ),
          Check: () => <ModeCheckIcon style={{ color: "green" }} />,
          Clear: () => <ModeCloseIcon style={{ color: "red" }} />
        }}
        components={{
          OverlayLoading: () => <Loader />,
          EditRow: props => (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
              onEditingCanceled={(mode, rowData) => {
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (mode === "update") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode === "add") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode, rowData);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode === "delete") {
                      props.onEditingCanceled(mode, rowData);
                    }
                  });
                });
              }}
            />
          ),
          onRowAdd: props => (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
            />
          )
        }}
        editable={{
          deleteTooltip: row =>
            isAuthDelete ? "Delete" : "You are not authorized",
          editTooltip: row =>
            isAuthUpdate ? "Update" : "You are not authorized",
          isDeletable: row => (isAuthDelete ? false : false),
          isEditable: row => (isAuthUpdate ? true : false),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const otherOperators = Object.values(operators).filter(
                  op => op.id !== oldData.id
                );
                if (newData.code && newData.name) {
                  if (
                    otherOperators.filter(
                      op => op.code === newData.code.toUpperCase()
                    ).length === 0 ||
                    otherOperators.filter(
                      op => op.name === newData.name.toUpperCase()
                    ).length === 0
                  ) {
                    newData.code = newData.code.toUpperCase();
                    newData.name = newData.name.toUpperCase();
                    // newData.countries =
                    //   typeof newData.countries === "string"
                    //     ? newData.countries?.split(",")
                    //     : newData.countries;
                    if (newData.countries.length > 0) {
                      if (typeof newData.countries[0] === "object") {
                        newData.countries = newData.countries.map(
                          country => country.id
                        );
                      }
                    }
                    dispatch(updateOperatorService(newData, oldData));
                    resolve();
                  } else {
                    MySwal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: intl.formatMessage({
                        id: "operator.already.exists"
                      })
                    });
                    resolve();
                  }
                } else {
                  MySwal.fire(
                    "Oops...",
                    intl.formatMessage({ id: "please.fill.in.all.fields" }),
                    "error"
                  );
                  reject();
                }
              }, 1000);
            }),

          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                  dispatch(deleteOperatorService(oldData.id));
                resolve();
              }, 1000);
            })
        }}
      />

      <Modal
        open={showCountryModal}
        onClose={() => setShowCountryModal(!showCountryModal)}
        aria-labelledby="country-detail-modal-title"
        aria-describedby="country-detail-modal-description"
      >
        <Box className={countryModalClasses.content}>
          <Typography
            id="country-detail-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: "30px" }}
          >
            OPERATOR COUNTRIES
          </Typography>
          {operatorCountries.map((country, idx) => (
            <Typography
              key={idx}
              className="country-detail-modal-description"
              sx={{ mt: 2 }}
              style={{
                marginBottom: "6px",
                color:
                  localStorage.getItem("theme-type") === "dark"
                    ? "grey"
                    : "black"
              }}
            >
              {country}
            </Typography>
          ))}
        </Box>
      </Modal>
    </Fragment>
  );
}
