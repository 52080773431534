import {
  GET_MASTER_USERS,
  SET_CURRENT_MASTER_USER,
  ADD_MASTER_USER,
  EDIT_MASTER_USER,
  DELETE_MASTER_USER
} from "@jumbo/constants/ActionTypes";
import { GET_ALL_USERS } from "redux/actions/types";

const INITIAL_STATE = {
  masterUsers: [],
  currentMasterUser: null,
  allUsers: []
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MASTER_USERS:
      return { ...state, masterUsers: action.payload };
    case SET_CURRENT_MASTER_USER:
      return { ...state, currentMasterUser: action.payload };
    case ADD_MASTER_USER:
      return { ...state, masterUsers: [...state.masterUsers, action.payload] };
    case EDIT_MASTER_USER:
      return {
        ...state,
        masterUsers: state.masterUsers.map(user =>
          user.id === action.payload.id ? action.payload : user
        )
      };
    case DELETE_MASTER_USER:
      return {
        ...state,
        masterUsers: state.masterUsers.filter(user => user.id != action.payload)
      };
    case GET_ALL_USERS:
      return { ...state, allUsers: action.payload };
    default:
      return state;
  }
};

export default usersReducer;
