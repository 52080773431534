import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Error404 from "./Pages/404";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import AdminOperations from "../@jumbo/components/AdminPanel/AdminOperations";
import ForgotPasswordPage from "./Auth/ForgotPassword";
import Dashboard from "../@jumbo/components/Contract/Dashboard";
import Master from "../@jumbo/components/MasterPanel/index";
import AllotmentListLoading from "../@jumbo/components/RoomActions/AllotmentListLoading";
import { PrivateRoute } from "./Auth/PrivateRoute";
import OpenStopSale from "../@jumbo/components/RoomActions/OpenStopSale";
import LoginChooseCompany from "../@jumbo/components/Common/authComponents/ChooseCompany";
import DefinitionsLoading from "@jumbo/components/Contract/DefinitionsLoading";
import HotelReport from "@jumbo/components/Reports/HotelReport";
// import Payment from "@jumbo/components/Payment";
// import TransactionInformation from "@jumbo/components/Payment/TransactionInformation";
import OperatorCredentialDetail from "@jumbo/components/Contract/OperatorCredentialDetail";
import HotelMatch from "@jumbo/components/Contract/HotelMatch";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import RoomMatch from "@jumbo/components/Contract/RoomMatch";
import Offers from "@jumbo/components/RoomActions/Offers";
import Email from "@jumbo/components/Help/Email";
import SSS from "@jumbo/components/Contract/SSS";
import { AuhMethods } from "../services/auth";
import { CurrentAuthMethod } from "../@jumbo/constants/AppConstants";
import Profile from "@jumbo/components/Profile/Profile";
import axios from "services/axios";
import { useIntl } from "react-intl";
import AINotification from "@jumbo/components/AINotification/AINotification";

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text,
  });
};

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { location } = rest;
  let { pathname } = location;
  let isAuth = "";
  let route_id = 0;
  const intl = useIntl();

  const pageName = pathname.split("/").filter((i) => i !== "");

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  const { routes } = useSelector(({ routes }) => routes);

  if (pathname.startsWith("/definition")) {
    pathname = "/product";
  }
  if (pathname.startsWith("/salemanagement")) {
    pathname = "/sale_management";
  }
  if (pathname.startsWith("/reports")) {
    pathname = "/reports_operations";
  }
  if (pathname.startsWith("/botmanagement")) {
    pathname = "/bot_management";
  }
  if (pathname.startsWith("/payment")) {
    pathname = "/payment_operations";
  }
  if (pathname.startsWith("/AInotification")) {
    pathname = "/AInotification";
  }

  axios.interceptors.response.use(
    function(config) {
      if (
        config.data.status == "Token is Expired" ||
        config.data.status == "Token is Invalid"
      ) {
        window.location.pathname = "/login";
      }
      return config;
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  if (permissionsByAuthUser.length > 0 && routes.length > 0) {
    route_id = routes.findIndex((i) => pathname.startsWith("/" + i.route_name));
    isAuth = permissionsByAuthUser.some(
      (permission) =>
        routes[route_id]?.id == permission?.route_id && permission?.get === 1
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth == true ? (
          <Component {...props} />
        ) : isAuth !== "" ? ( //isauth false olma durumunda
          JSON.parse(localStorage.getItem("language"))?.locale === "tr" ? (
            (sweetAlerts(
              "warning",

              pageName[0][0].toUpperCase() +
                pageName[0].substring(1) +
                " " +
                intl.formatMessage({
                  id: "you.do.not.have.permission.to.access.the.pages",
                })
            ),
            (<Redirect to="/dashboard" />))
          ) : (
            (sweetAlerts(
              "warning",

              intl.formatMessage({
                id: "you.do.not.have.permission.to.access.the.pages",
              }) +
                " " +
                pageName[0][0].toUpperCase() +
                pageName[0].substring(1) +
                " pages"
            ),
            (<Redirect to="/dashboard" />))
          )
        ) : (
          //isauth false ya da true dışında bir değere sahip olması durumunda
          <div></div>
        )
      }
    />
  );
};

const Routes = () => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { selectedCompany } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (
    /* !selectedCompany */ !localStorage.getItem("selectedCompany") &&
    authUser?.authority_level > 10 &&
    //authUser?.authority_level <= 30 &&
    authUser?.authority_level < 30 &&
    location.pathname === "/login"
  ) {
    return <Redirect to="/choosecompany" />;
  }
  if (
    !localStorage.getItem("selectedOffice") &&
    !localStorage.getItem("selectedCompany") &&
    authUser?.authority_level == 30 &&
    location.pathname === "/login"
  ) {
    return <Redirect to="/choosecompany" />;
  }

  if (
    (authUser &&
      authUser?.authority_level !== 0 &&
      location.pathname === "/login") ||
    location.pathname === "/"
  ) {
    return <Redirect to="/dashboard" />;
  }

  if (
    (!authUser || authUser.authority_level === 0) &&
    location.pathname !== "/login" &&
    !location.pathname.includes("/master")
  ) {
    return <Redirect to="/login" />;
  }

  if (
    authUser &&
    !localStorage.getItem("selectedCompany") &&
    authUser?.authority_level > 10 &&
    //authUser?.authority_level <= 30 &&
    authUser?.authority_level < 30 &&
    location.pathname !== "/choosecompany"
  ) {
    //!selectedCompany
    return <Redirect to="/choosecompany" />;
  }

  if (
    !localStorage.getItem("selectedOffice") &&
    !localStorage.getItem("selectedCompany") &&
    authUser?.authority_level == 30 &&
    location.pathname !== "/choosecompany"
  ) {
    //!selectedCompany
    return <Redirect to="/choosecompany" />;
  }

  if (
    (authUser &&
      authUser?.authority_level !== 0 &&
      location.pathname === "/salemanagement") ||
    location.pathname === "/salemanagement/"
  ) {
    return <Redirect to="/salemanagement/allotmentList" />;
  }

  function SaleManagementFunc() {
    let { saleMId } = useParams();
    const intl = useIntl();

    const { permissionsByAuthUser } = useSelector(
      ({ permission }) => permission
    );
    let isAuthGet = "";
    let isAuthAdd = "";

    if (permissionsByAuthUser.length > 0) {
      isAuthGet = permissionsByAuthUser.some(
        (permission) => 6 == permission.route_id && permission.get === 1
      );
      isAuthAdd = permissionsByAuthUser.some(
        (permission) => 6 == permission.route_id && permission.post === 1
      );
    }
    if (
      authUser &&
      isAuthGet &&
      authUser?.authority_level !== 0 &&
      saleMId === "openstopsale"
    ) {
      return <OpenStopSale />;
    }
    if (
      authUser &&
      isAuthGet &&
      authUser?.authority_level !== 0 &&
      saleMId === "allotmentList"
    ) {
      return <AllotmentListLoading />;
    }
    if (
      authUser &&
      authUser?.authority_level !== 0 &&
      isAuthGet &&
      saleMId === "offers"
    ) {
      return <Offers />;
    } else {
      sweetAlerts(
        "warning",
        intl.formatMessage({
          id: "the.page.you.were.looking.for.was.not.found",
        })
      );
      return <Redirect to="/dashboard" />;
    }
  }

  if (
    (authUser &&
      authUser?.authority_level !== 0 &&
      location.pathname === "/botmanagement") ||
    location.pathname === "/botmanagement/"
  ) {
    return <Redirect to="/botmanagement/extranetcredentials" />;
  }

  function BotManagementFunc() {
    let { botMId } = useParams();
    const intl = useIntl();
    const { permissionsByAuthUser } = useSelector(
      ({ permission }) => permission
    );
    let isAuthGet = "";
    let isAuthAdd = "";

    if (permissionsByAuthUser.length > 0) {
      isAuthGet = permissionsByAuthUser.some(
        (permission) => 5 == permission.route_id && permission.get === 1
      );
      isAuthAdd = permissionsByAuthUser.some(
        (permission) => 5 == permission.route_id && permission.post === 1
      );
    }
    if (
      authUser &&
      isAuthGet &&
      authUser?.authority_level !== 0 &&
      botMId === "extranetcredentials"
    ) {
      return <OperatorCredentialDetail />;
    }
    if (
      authUser &&
      isAuthGet &&
      authUser?.authority_level !== 0 &&
      botMId === "hotelmap"
    ) {
      return <HotelMatch />;
    }
    if (
      authUser &&
      authUser?.authority_level !== 0 &&
      isAuthGet &&
      botMId === "roommap"
    ) {
      return <RoomMatch />;
    } else {
      sweetAlerts(
        "warning",
        intl.formatMessage({
          id: "the.page.you.were.looking.for.was.not.found",
        })
      );
      return <Redirect to="/dashboard" />;
    }
  }
  function ReportsFunc() {
    let { reportId } = useParams();
    const intl = useIntl();

    const { permissionsByAuthUser } = useSelector(
      ({ permission }) => permission
    );
    let isAuthGet = "";
    let isAuthAdd = "";

    if (permissionsByAuthUser.length > 0) {
      isAuthGet = permissionsByAuthUser.some(
        (permission) => 7 == permission.route_id && permission.get === 1
      );
      isAuthAdd = permissionsByAuthUser.some(
        (permission) => 7 == permission.route_id && permission.post === 1
      );
    }

    if (
      authUser &&
      authUser?.authority_level !== 0 &&
      isAuthGet &&
      reportId === "hotelReport"
    ) {
      return <HotelReport />;
    } else {
      sweetAlerts(
        "warning",
        intl.formatMessage({
          id: "the.page.you.were.looking.for.was.not.found",
        })
      );
      return <Redirect to="/dashboard" />;
    }
  }
  // function PaymentFunc() {
  //   let { paymentId } = useParams();
  //   const intl = useIntl();

  //   const { permissionsByAuthUser } = useSelector(
  //     ({ permission }) => permission
  //   );
  //   let isAuthGet = "";
  //   let isAuthAdd = "";

  //   if (permissionsByAuthUser.length > 0) {
  //     isAuthGet = permissionsByAuthUser.some(
  //       permission => 8 == permission.route_id && permission.get === 1
  //     );
  //     isAuthAdd = permissionsByAuthUser.some(
  //       permission => 8 == permission.route_id && permission.post === 1
  //     );
  //   }

  //   if (
  //     authUser &&
  //     isAuthGet &&
  //     authUser?.authority_level !== 0 &&
  //     paymentId === "balanceTransactions"
  //   ) {
  //     return <Payment />;
  //   } else if (
  //     authUser &&
  //     authUser?.authority_level !== 0 &&
  //     isAuthGet &&
  //     paymentId === "transactionInformations"
  //   ) {
  //     return <TransactionInformation />;
  //   } else {
  //     sweetAlerts(
  //       "warning",
  //       intl.formatMessage({
  //         id: "the.page.you.were.looking.for.was.not.found"
  //       })
  //     );
  //     return <Redirect to="/dashboard" />;
  //   }
  // }

  const onLogoutClick = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    return <Redirect from="/logout" to="/login" />;
  };
  return (
    <Switch>
      <RestrictedRoute path="/definitions" component={DefinitionsLoading} />
      <RestrictedRoute path="/AInotification" component={AINotification} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <RestrictedRoute
        path="/salemanagement/:saleMId"
        component={SaleManagementFunc}
      />
      <RestrictedRoute path="/reports/:reportId" component={ReportsFunc} />
      <RestrictedRoute
        path="/botmanagement/:botMId"
        component={BotManagementFunc}
      />
      {/* <RestrictedRoute path="/contract/:contractId" component={ContractFunc} /> */}
      {/* <RestrictedRoute path="/payment/:paymentId" component={PaymentFunc} /> */}
      <PrivateRoute path="/signup" component={Register} />
      <PrivateRoute path="/forgot-password" component={ForgotPasswordPage} />
      <PrivateRoute path="/admin" component={AdminOperations} />
      <PrivateRoute path="/profile" component={Profile} />

      <Route path="/sendmail" component={Email} />
      <Route path="/sss" component={SSS} />
      <Route path="/choosecompany" component={LoginChooseCompany} />
      <Route path="/master" component={Master} />
      <Route path="/login" component={Login} />
      <Route from="/logout" component={onLogoutClick} />
      <PrivateRoute
        path="/whatsappsupport"
        component={() => {
          window.open("https://wa.me/+908507243375");
          return <Redirect to="/dashboard" />;
        }}
      />
      <Redirect from="*" to="/dashboard" />

      <Route component={Error404} />
    </Switch>
  );
};

export default Routes;
