import {
  Backdrop,
  Card,
  CardContent,
  Fade,
  Modal,
  makeStyles,
  IconButton
} from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row } from "react-grid-system";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";

const useStyles = makeStyles(theme => ({
  modal: {
    alignItems: "center",
    textAlign: "-webkit-center",
    paddingTop: "12%",
    justifyContent: "center",
    overflow: "scroll",
    backgroundColor:
      localStorage.getItem("theme-type") === "dark" ? "rgb(21 21 19 / 1%)" : ""
  },
  rootcard: {
    width: "95%",
    position: "center",
    backgroundColor:
      localStorage.getItem("theme-type") === "dark" ? "rgb(21 21 19 / 1%)" : ""
  },
  root: {
    width: "80%",
    position: "center"
  }
}));

export default function OfferDetailModal({
  openDetailModal,
  setOpenDetailModal,
  offerRowData,
  title
}) {
  const classes = useStyles();
  const columns = [
    {
      title: "Name",
      field: "offerName"
    },
    {
      title: "Rooms",
      field: "",
      render: rowData => {
        let roomString = "";
        let rooms = "";
        if (rowData.hasOwnProperty("offerRoom")) {
          rooms = rowData.offerRoom.map(item => {
            return item.label;
          });
        } else {
          rooms = rowData.from_room["name"];
        }
        roomString = rooms.toString();
        return roomString;
      }
    },
    {
      title: "Amount/Discount",
      field: "",
      render: rowData => {
        let result = "";
        if (rowData.hasOwnProperty("freeNight")) {
          result = rowData.stayNights + " x " + rowData.freeNight;
        } else if (rowData.hasOwnProperty("percent")) {
          result = rowData.percent;
        } else if (rowData.hasOwnProperty("amount")) {
          result = rowData.amount;
        } else {
          result = rowData.to_room["name"];
        }
        return result;
      }
    },
    {
      title: "Dates",
      field: "",
      render: rowData => {
        let opDates = "";
        let datesString = rowData.stayStartDate + " | " + rowData.stayEndDate;
        opDates = datesString.toString();
        return opDates;
      }
    }
  ];
  return (
    <>
      <Modal
        className={classes.modal}
        open={openDetailModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openDetailModal}>
          <Card className={classes.root} variant="outlined">
            <CardContent className={classes.rootcard}>
              <Row>
                <Col md={11}>
                  <CmtCardHeader title={title + " DETAIL"}></CmtCardHeader>
                </Col>
                <Col md={1}>
                  <IconButton
                    color="inherit"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => setOpenDetailModal(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Col>
              </Row>
              <MaterialTable
                data={offerRowData}
                columns={columns}
                options={{
                  search: false,
                  showTitle: false,
                  addRowPosition: "first",
                  toolbar: false
                  //   pageSize: 10,
                  //   pageSizeOptions: [10, 20, 30, 40],
                }}
              />
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

export const MemoizedOfferDetailModal = React.memo(OfferDetailModal);
