import { loggerEndpoint } from "../axiosLogger/index";
import jwt from "jwt-decode";
class SendLogService {
  constructor(user) {
    this.projectID = "bot_proxolab";
    this.location = window.location;
    this.token = localStorage.getItem("token");
    this.user =
      this.token && this.token != null
        ? {
            id: jwt(this.token).id,
            name: jwt(this.token).fullname,
            email: jwt(this.token).email
          }
        : "";
    this.header = {
      Authorization: this.token,
      Accept: "application/json",
      "Content-Type": "application/json"
    };
  }
  /************************************************/
  // async sendLogout() {
  //   try {
  //     await fetch(loggerEndpoint + "userlogoutinfo", {
  //       headers: {
  //         Authorization: this.token,
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       method: "POST",
  //       body: JSON.stringify({
  //         email: this.user.email,
  //         id: this.user.id,
  //         name: this.user.name,
  //         location: this.location,
  //         project: this.projectID,
  //       }),
  //     })
  //       .then(function(res) {
  //         console.log(res);
  //       })
  //       .catch(function(res) {
  //         console.log(res);
  //       });
  //   } catch (err) {}
  // }
  // async sendLogin(comingTokenFromOnLoginToJWTAuth) {
  //   try {
  //     await fetch(loggerEndpoint + "userlogininfo", {
  //       headers: {
  //         Authorization: comingTokenFromOnLoginToJWTAuth,
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       method: "POST",
  //       body: JSON.stringify({
  //         email: this.user.email,
  //         id: this.user.id,
  //         name: this.user.name,
  //         location: this.location,
  //         project: this.projectID,
  //       }),
  //     })
  //       .then(function(res) {
  //         console.log(res);
  //       })
  //       .catch(function(res) {
  //         console.log(res);
  //       });
  //   } catch (err) {}
  // }
  async sendCompanyLog(res, data) {
    try {
      await fetch(loggerEndpoint + "companyservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendHotelLog(res, data) {
    try {
      await fetch(loggerEndpoint + "hotelservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }


  async sendOfficeDestinationLog(res, data) {
    let deletedRes = {
      data: {
        status: res.status,
        message: res.message,
        company_id: res.company_id,
        created_at: res.created_at,
        updated_at: res.updated_at,
        data: data
      }
    };

    try {
      delete res.config.data;

      await fetch(loggerEndpoint + "officedestinationservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: deletedRes.data,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendOfficeLog(res, data) {
    try {
      await fetch(loggerEndpoint + "officeservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendOperatorLog(res, data) {
    try {
      await fetch(loggerEndpoint + "operatorservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendProductTypesLog(res, data) {
    try {
      await fetch(loggerEndpoint + "producttypesservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendRoomLog(res, data) {
    try {
      await fetch(loggerEndpoint + "roomservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async sendUserLog(res, data) {
    try {
      await fetch(loggerEndpoint + "userservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async masterCompanyServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "mastercompanyservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async masterUserServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "masteruserservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async allotmentServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "allotmentservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async stopSaleLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "stopsaleservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async openSaleLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "opensaleservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async userPermissionServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "userpermissionservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  async hotelMatchServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "hotelmatchservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }
  async roomMatchServiceLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "roommatchservice", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  /********************* EXTRANET LOGGER ************************/
  async SHLogs(res, data) {
    try {
      await fetch(loggerEndpoint + "sh", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }

  /**************************************************************/
  async errorlog(res, data) {
    try {
      await fetch(loggerEndpoint + "error", {
        headers: this.header,
        method: "POST",
        body: JSON.stringify({
          res: res,
          data: data,
          user: this.user,
          location: this.location,
          project: this.projectID
        })
      })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(res) {
          console.log(res);
        });
    } catch (err) {}
  }
}

let sendLogService = new SendLogService();

export default sendLogService;
