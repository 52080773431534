import React, { useEffect, useRef, useState } from "react";
import BigCalendar from "react-big-calendar-like-google";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllStopSaleMongoDBService } from "services/stopSale";
import { getRoomService } from "services/rooms";
import { getHotelService } from "services/hotels";
import "react-big-calendar-like-google/lib/css/react-big-calendar.css";
import { getMatchHotelDataService } from "services/hotelmatch";
import { getMatchedRoomsByHotelService } from "services/operatorRooms";
import AllotmentList from "./AllotmentList";
import { getAllOpenSaleMongoDBService } from "services/openSale";

export default function AllotmentListLoading() {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  let isFinished = useRef(false);

  const [filterState, setFilterState] = useState({
    hotel_id: "",
    room_id: "",
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD")
  });

  BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

  useEffect(() => {
    const firstLoader = async () => {
      Promise.all([
        dispatch(getHotelService()),
        dispatch(getRoomService()),
        dispatch(getMatchHotelDataService()),
        dispatch(
          getAllStopSaleMongoDBService({
            userEmail: authUser.email,
            filterState
          })
        ),
        dispatch(
          getAllOpenSaleMongoDBService({
            userEmail: authUser.email,
            filterState
          })
        )
      ]);
    };

    firstLoader().then((isFinished.current = true));
  }, [dispatch]);
  useEffect(() => {
    const matchedRoomsLoader = async () => {
      await Promise.all([
        dispatch(getMatchedRoomsByHotelService(filterState.hotel_id))
      ]);
    };
    matchedRoomsLoader().then((isFinished.current = true));
  }, [filterState.hotel_id]);
  return (
    <React.Fragment>
      {isFinished && (
        <AllotmentList
          filterState={filterState}
          setFilterState={setFilterState}
          isFinished={isFinished}
        />
      )}
    </React.Fragment>
  );
}
