import {
  getMasterUsers,
  addMasterUser,
  editMasterUser,
  deleteMasterUser,
  getAllUsers
} from "redux/actions/User";

import {
  addUser,
  updateUser,
  deleteUser,
  fetchCompanyError,
  fetchCompanySuccess
} from "redux/actions/CompanyHasOffice";
import { useIntl } from "react-intl";
import axios from "./axios";
import { fetchStart, fetchError, fetchSuccess } from "../redux/actions/Common";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
import Swal from "sweetalert2";
import { getMasterCompaniesService } from "./company";
/**PROXOLAB LOGGER **/

export const getMasterUsersService = callbackFunc => dispatch => {
  dispatch(fetchStart());
  return axios
    .get("/master/users")
    .then(({ data }) => {
      dispatch(getMasterUsers(data.data));
      dispatch(fetchSuccess());
      if (callbackFunc) callbackFunc();
    })
    .catch(error => {
      dispatch(fetchError(error));
    });
};

export const addMasterUserService = (user, callbackFunc) => dispatch => {
  dispatch(fetchStart());
  return axios
    .post("master/register", user)
    .then(({ data }) => {
      dispatch(addMasterUser(data.user));
      dispatch(fetchSuccess());
      dispatch(getMasterCompaniesService());
      if (callbackFunc) callbackFunc();
      sendLogService.masterUserServiceLogs(user, data);
    })
    .catch(e => {
      dispatch(fetchError("Something went wrong"));
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Username has been taken!"
      });
      if (callbackFunc) {
        setTimeout(() => {
          callbackFunc();
        }, 300);
      }
      sendLogService.errorlog(e, callbackFunc);
    });
};

export const editMasterUserService = (user, callbackFunc) => dispatch => {
  dispatch(fetchStart());

  return axios
    .put("master/users/", user)
    .then(({ data }) => {
      dispatch(editMasterUser(data.user));
      dispatch(fetchSuccess());
      dispatch(getMasterCompaniesService());
      if (callbackFunc) callbackFunc();
      sendLogService.masterUserServiceLogs(user, data);
    })
    .catch(error => {
      dispatch(fetchError(error));
      sendLogService.errorlog(error, callbackFunc);
    });
};

export const deleteMasterUserService = (user, callbackFunc) => dispatch => {
  return axios
    .delete(`master/users/${user.id}`)
    .then(({ data }) => {
      dispatch(deleteMasterUser(data.data));
      dispatch(getMasterCompaniesService());
    })
    .catch(error => {});
};

export const changeDbForSupport = company => dispatch => {
  return axios.put("master/change-sp-admin-db", company).then(({ data }) => {
    dispatch(fetchSuccess("Db changed successfully"));
  });
};

export const addAdminUserService = (user, callbackFunc) => dispatch => {
  dispatch(fetchStart());
  return axios
    .post("user", user)
    .then(({ data }) => {
      dispatch(addUser(data));
      dispatch(fetchSuccess());
      dispatch(fetchCompanySuccess("success"));
      sendLogService.sendUserLog(user, data);
      if (callbackFunc) callbackFunc();
    })
    .catch(error => {
      Swal.fire({
        title: "Error",
        text: JSON.parse(error.response.data).email
          ? "The email has already been taken."
          : "Something went wrong !",
        icon: "error",
        confirmButtonText: "Ok"
      });

      dispatch(fetchError());
      sendLogService.errorlog(error, callbackFunc);
    });
};

export const editAdminUserService = (user, callbackFunc) => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("user", user)
    .then(({ data }) => {
      dispatch(updateUser(data));
      dispatch(fetchSuccess());
      dispatch(fetchCompanySuccess("success"));
      sendLogService.sendUserLog(user, data);
      if (callbackFunc) callbackFunc();
    })
    .catch(error => {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok"
      });
      dispatch(fetchError());
      // dispatch(fetchCompanyError(error.response ? error.response.data : error));
      sendLogService.errorlog(error, callbackFunc);
    });
};

export const deleteAdminUserService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete(`user/${data.id}`)
    .then(res => {
      dispatch(deleteUser(res));
      dispatch(fetchSuccess());
      Swal.fire({
        title: "Success",
        text: "User deleted successfully",
        icon: "success",
        confirmButtonText: "Ok"
      });
      sendLogService.sendUserLog(data, res);
    })
    .catch(e => {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok"
      });
      dispatch(fetchError("error"));
      //dispatch(fetchError(e.message));
      //sendLogService.errorlog(e, data);
    });
};

export const getAllUsersService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("/getAllUsers")
    .then(res => {
      dispatch(getAllUsers(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
