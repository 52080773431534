import { GET_STOP_SALE, ADD_STOP_SALE, DENEME } from "../actions/types";

const initialState = {
  stopSale: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STOP_SALE:
      const isSameLog = state.stopSale.filter(
        log => log.pId == action.payload.pId
      );
      let stopLogs = state.stopSale;
      //eğer hiç log kaydı yoksa ve yenisi eklenmiyorsa
      if (stopLogs.length == 0 && !action.payload.hasOwnProperty("pId")) {
        stopLogs = action.payload;
      }
      //eğer hiç log kaydı yoksa ve ilk kayıt ekleniyorsa
      else if (stopLogs.length == 0 && action.payload.hasOwnProperty("pId")) {
        stopLogs[0] = action.payload;
      } else {
        //eğer aynı pId'ye sahip log kaydı yoksa ve yenisi ekleniyorsa
        if (isSameLog.length == 0 && action.payload.hasOwnProperty("pId")) {
          stopLogs.unshift(action.payload);
          if (state.stopSale.length > 5) {
            stopLogs.splice(-1, 1); //pop()???
          }
        } else if (
          isSameLog.length == 0 &&
          !action.payload.hasOwnProperty("pId")
        ) {
          stopLogs = action.payload;
        }
      }
      return { ...state, stopSale: stopLogs };
    case ADD_STOP_SALE:
      return { ...state, stopSale: [...state.stopSale, action.payload.data] };
    default:
      return state;
  }
};
