import { GET_STOP_SALE, ADD_STOP_SALE } from "./types";

export const getStopSale = (res) => (dispatch) => {
  dispatch({
    type: GET_STOP_SALE,
    payload: res.data.data,
  });
};

export const addStopSale = (res) => (dispatch) => {
  dispatch({
    type: ADD_STOP_SALE,
    payload: res.data,
  });
};
