import React, { useContext, useEffect } from "react";
import {
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography
} from "@material-ui/core";
import CmtAvatar from "../../../../@coremat/CmtAvatar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { AuhMethods } from "../../../../services/auth";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
//import PersonIcon from '@material-ui/icons/Person';
//import SettingsIcon from '@material-ui/icons/Settings';
import SidebarThemeContext from "../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext";
import { useDispatch, useSelector } from "react-redux";
import {
  companyData,
  nonEnterprise
} from "../../../../redux/selector/companyHasOfficeSelector";
import { useHistory, useLocation } from "react-router";
import { chooseCompany } from "redux/actions/Auth";
import LayoutContext from "@coremat/CmtLayouts/LayoutContext";
import SidebarToggleHandler from "../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler";
import Tooltip from "@mui/material/Tooltip";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "30px 16px 12px 16px",
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`
  },
  userInfo: {
    paddingTop: 24,
    transition: "all 0.1s ease",
    height: 75,
    opacity: 1,
    ".Cmt-miniLayout .Cmt-sidebar-content:not &": {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: "all 0.3s ease"
    }
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25
  }
}));

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const { authUser } = useSelector(({ auth }) => auth);
  //const { selectedCompany } = useSelector(({ auth }) => auth);
  const { isSidebarOpen, setSidebarOpen } = useContext(LayoutContext);

  const classes = useStyles({ sidebarTheme });
  const dispatch = useDispatch();

  const [selectedCompany, setSelectedCompany] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [companyAnchorEl, setCompanyAnchorEl] = React.useState(null);
  const [changeCompany, setChangeCompany] = React.useState(null);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setSelectedCompany(localStorage.getItem("selectedCompany"));
  }, []);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  /*   const onLogoutClick = () => {
    handlePopoverClose();
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    if (location.pathname.includes("master")) {
      history.push("/master/login");
    } else {
      history.push("/login");
    }
  }; */
  return (
    <div className={classes.root}>
      {isSidebarOpen ? (
        <Tooltip title="Close Sidebar">
          <span
            onClick={() => setSidebarOpen(!isSidebarOpen)}
            style={{
              display: "flex",
              width: "100%",
              cursor: "pointer",
              fontSize: 20
            }}
          >
            MUNDOSAI BOT
            <ArrowLeftIcon
              style={{ fontSize: 28 }}
              onClick={() => setSidebarOpen(!isSidebarOpen)}
            />
          </span>
        </Tooltip>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CmtAvatar
            src={"/images/proxo.jpg"}
            alt="User Avatar"
            onClick={() => setSidebarOpen(!isSidebarOpen)}
          />
          <ArrowRightIcon
            onClick={() => setSidebarOpen(!isSidebarOpen)}
            style={{ fontSize: "28px", cursor: "pointer" }}
          />
        </div>
      )}
      {/*   <div className={classes.userInfo} onClick={handlePopoverOpen}>
          <div
          className="pointer"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end"
          }}
        >
          <div className="mr-2">
            <Typography
              className={classes.userTitle}
              component="h3"
              variant="h6"
            >
              {authUser.fullname}
            </Typography>
            <Typography className={classes.userSubTitle}>
              {authUser.email}
            </Typography>
          </div>
          <ArrowDropDownIcon />

        </div> 
      </div>*/}

      {/*      {open && (

        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
        > */}
      {/*  <Paper elevation={8}>
            <MenuList>
               <MenuItem onClick={handlePopoverClose}>
                <PersonIcon />
                <div className="ml-2">Profile</div>
              </MenuItem> */}
      {/* <MenuItem onClick={handlePopoverClose}>
                <SettingsIcon />
                <div className="ml-2">Settings</div>
              </MenuItem> 
              <MenuItem onClick={onLogoutClick}>
                <ExitToAppIcon />
                <div className="ml-2">Logout</div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )}*/}
    </div>
  );
};

export default SidebarHeader;
