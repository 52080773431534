import {fetchError, fetchSuccess } from "redux/actions";
import { getOfferList } from "redux/actions/offerList";
import axios from "./axios";
import axiosBot from "./axiosBot";
import { apihost } from "./axiosBot";

export const sendOfferListService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  data["endpoint"] = apihost;
  data["botEndpoint"]=axiosBot.defaults.baseURL
  return axios
    .post("offerList", data)
    .then(function(res) {
      dispatch(getOfferList(res.data));
      dispatch(fetchSuccess());
    })
    .catch(function(e) {
      dispatch(fetchError(e.message));
    });
};

