import React, { useState, useEffect, useLayoutEffect } from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import PublicIcon from "@mui/icons-material/Public";
import WorkIcon from "@mui/icons-material/Work";
import ArticleIcon from "@mui/icons-material/Article";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import ApprovalIcon from "@mui/icons-material/Approval";
import CardHeader from "@mui/material/CardHeader";
import { getAdminCompaniesService } from "services/company";
import EmailIcon from "@mui/icons-material/Email";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IntlMessages from "../../utils/IntlMessages";
import LanguageSwitcher from "../AppLayout/partials/LanguageSwitcher";
import ThemeItem from "../AppLayout/partials/Header/HeaderTheme/ThemeItem";
import { IconButton, Popover, Tooltip, useTheme } from "@material-ui/core";
import PaletteIcon from "@material-ui/icons/Palette";
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import clsx from "clsx";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import DescriptionIcon from "@mui/icons-material/Description";

/***************** STYLES PROPERTIES ********************/
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiPaper-root": {
      backgroundColor: lighten(theme.palette.background.paper, 0.1)
    }
  },
  heading: {
    fontSize: "medium",
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  cardStyle: {
    height: "5rem",
    marginRight: "5rem"
    //margin: "auto",
    // paddingTop: "2rem",
    //  paddingLeft: "1rem",
  },
  gridStyle: {
    marginTop: "5rem"
  },
  textStyle: {
    fontSize: "medium"
  }
}));
/*************************************************************/

/********** CSS STYLES*******/
const defColor = "#33658a";
const title = {
  color: defColor,
  width: "100%",
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  flexDirection: "row",
  letterSpacing: "3px",
  fontWeight: "bolder",
  borderBottom: `1px solid ${defColor}`,
  padding: "1rem"
};
const titleText = {
  marginLeft: "1rem"
};
const profileSettings = {
  marginLeft: "76%"
};
const logo = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: ".2rem",
  border: `2px solid ${defColor}`,
  borderRadius: "50%"
};
const info = {
  display: "flex",
  justifyContent: "center",
  alignItems: "left",
  flexDirection: "column",
  marginTop: "1rem",
  width: "100%"
  //border: `2px solid ${defColor}`,
};
const userSection = {
  margin: "2rem"
  //border: `2px solid ${defColor}`,
};
const clientPermission = {
  width: "85%",
  margin: "auto"

  //border: `2px solid ${defColor}`,
};
const userName = {
  color: defColor,
  fontSize: "20px"
};
const itemIcon = {
  color: defColor,
  fontWeight: "bold"
};
const subheader = {
  color: "black",
  letterSpacing: "2px",
  fontSize: "14px"
};
const permissionsTitle = {
  color: defColor,
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  marginLeft: "10px"
};
const permissionsText = {
  marginLeft: "15px",
  fontFamily: "arial"
};

const permissionIcon = {
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  flexDirection: "row",
  color: defColor,
  fontWeight: "bolder"
};

/****************************/

export default function Profile() {
  //IMPORT CLASSESS
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();

  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const themed = localStorage.getItem("theme-type");

  const classes = useStyles();

  /**********************GET DATA WITH SELECTOR FROM REDUX***************************/
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminCompaniesService());
  }, [dispatch]);

  const { authUser } = useSelector(({ auth }) => auth);
  const { companiesId } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.result
  );
  const { companies } = useSelector(
    ({ companyHasOffice }) => companyHasOffice.entities
  );

  const permissionStateSelector = useSelector(
    state => state.companyHasOffice.entities.get_permission
  );
  /******************************************************************/

  //STATE AND VARIABLES SECTION

  const [companyName, setCompanyName] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  useEffect(() => {
    let compName = [];
    companiesId.map(id => {
      compName.push(companies[id].name);
    });
    setCompanyName(compName);
  }, [companies, isEmpty]);

  if (companies && isEmpty === true) {
    setIsEmpty(false);
  }

  const filterPermissionState = Object.values(permissionStateSelector).filter(
    suppRed => suppRed.user_id == authUser.id && suppRed.id !== 4
  );

  const routes = [
    {
      id: 1,
      name: <IntlMessages id="accounting" />,
      icon: <WorkIcon />
    },
    {
      id: 2,
      name: <IntlMessages id="contract" />,
      icon: <ArticleIcon />
    },
    {
      id: 3,
      name: <IntlMessages id="product" />,
      icon: <PublicIcon />
    },
    {
      id: 5,
      name: <IntlMessages id="bot.management" />,
      icon: <SwapVerticalCircleIcon />
    },
    {
      id: 6,
      name: <IntlMessages id="sale.management" />,
      icon: <SettingsIcon />
    },
    {
      id: 7,
      name: <IntlMessages id="reports.operations" />,
      icon: <DescriptionIcon />
    },
    {
      id: 8,
      name: <IntlMessages id="payment.operations" />,
      icon: <DescriptionIcon />
    }
  ];

  /*************** HANDLECHANGE SECTION****************************/
  let controlPermissionGet = [];
  let controlPermissionPost = [];
  let controlPermissionPut = [];
  let controlPermissionDelete = [];
  filterPermissionState.map(elem => {
    const routesItem = routes.find(route => route.id === elem.route_id);

    if (elem.user_id == authUser.id && routesItem !== undefined) {
      //GET PERMİSSION CONTROL
      if (routesItem.id == elem.route_id) {
        if (elem.get == 1) {
          controlPermissionGet[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}>&#x2713;</span>
          );
        } else {
          controlPermissionGet[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}> &#10006;</span>
          );
        }
        //POST PERMİSSİON CONTROL
        if (elem.post == 1) {
          controlPermissionPost[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}>&#x2713;</span>
          );
        } else {
          controlPermissionPost[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}> &#10006;</span>
          );
        }
        //UPDATE PERMİSSİON CONTROL
        if (elem.put == 1) {
          controlPermissionPut[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}>&#x2713;</span>
          );
        } else {
          controlPermissionPut[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}> &#10006;</span>
          );
        }
        //DELETE PERMİSSİON CONTROL
        if (elem.delete == 1) {
          controlPermissionDelete[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}>&#x2713;</span>
          );
        } else {
          controlPermissionDelete[elem.route_id] = (
            <span style={{ color: "green", fontSize: "20px" }}> &#10006;</span>
          );
        }
      }
    }
  });
  /*****************************************************************/
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item style={title}>
          <AccountCircleIcon style={{ fontSize: "xx-large" }} />
          <h3
            style={{
              color: "#33658A",
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
              width: "80%"
            }}
          >
            <IntlMessages id="user.profile" />
          </h3>
          <Grid item style={{ marginLeft: "auto" }}>
            <LanguageSwitcher />
          </Grid>
        </Grid>
      </Grid>

      {/************************ USERNAME - EMAIL ****************************/}
      <div style={info}>
        <div style={userSection}>
          <Grid container spacing={5}>
            <Grid xs={12} item>
              <Card className={classes.cardStyle}>
                <CardHeader
                  title={
                    <div style={userName}>
                      <IntlMessages id="user.name" />
                    </div>
                  }
                  avatar={
                    <div style={itemIcon}>
                      <BorderColorIcon />
                    </div>
                  }
                  titleTypographyProps={{ variant: "h6" }}
                  subheader={<span style={subheader}>{authUser.fullname}</span>}
                />
              </Card>
            </Grid>
            <Grid xs={12} item>
              <Card className={classes.cardStyle}>
                <CardHeader
                  title={
                    <div style={userName}>
                      <IntlMessages id="email" />
                    </div>
                  }
                  avatar={
                    <div style={itemIcon}>
                      <EmailIcon />
                    </div>
                  }
                  titleTypographyProps={{ variant: "h6" }}
                  subheader={<span style={subheader}>{authUser.email}</span>}
                />
              </Card>
            </Grid>

            <Grid xs={12} item>
              <Card className={classes.cardStyle}>
                <CardHeader
                  title={
                    <div style={userName}>
                      <IntlMessages id="companies" />
                    </div>
                  }
                  avatar={
                    <div style={itemIcon}>
                      <LocationCityIcon />
                    </div>
                  }
                  titleTypographyProps={{ variant: "h6" }}
                  subheader={<span style={subheader}>{companyName}</span>}
                />
              </Card>
            </Grid>
          </Grid>
        </div>
        <div style={clientPermission}>
          <Grid item>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: defColor, fontWeight: "900" }}>
                      {" "}
                      <IntlMessages id="user.permission" />
                    </TableCell>
                    <TableCell style={itemIcon} align="right">
                      <IntlMessages id="view" />
                    </TableCell>
                    <TableCell style={itemIcon} align="right">
                      <IntlMessages id="create" />
                    </TableCell>
                    <TableCell style={itemIcon} align="right">
                      <IntlMessages id="update" />
                    </TableCell>
                    <TableCell style={itemIcon} align="right">
                      <IntlMessages id="delete" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {routes.map(route => (
                    <TableRow
                      width="30%"
                      key={route.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div style={permissionIcon}>
                          {route.icon}
                          &nbsp;
                          <span
                            style={{ color: "black", fontWeight: "lighter" }}
                          >
                            {route.name}
                          </span>
                        </div>
                      </TableCell>

                      <TableCell align="right">
                        {controlPermissionGet[route.id]}
                      </TableCell>
                      <TableCell align="right">
                        {controlPermissionPost[route.id]}
                      </TableCell>
                      <TableCell align="right">
                        {controlPermissionPut[route.id]}
                      </TableCell>
                      <TableCell align="right">
                        {controlPermissionDelete[route.id]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </div>
      </div>
      {/**************************************************************/}
    </Box>
  );
}
