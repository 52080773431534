import React from "react";
import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  getHotelMatch,
  addHotelMatch,
  updateHotelMatch,
  deleteHotelMatch
} from "redux/actions/hotelMatch";
import axios from "./axios";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

export const getMatchHotelDataService = () => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("matchHotels")
    .then(res => {
      dispatch(getHotelMatch(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {});
};

export const addMatchHotelDataService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("matchHotel", data)
    .then(res => {
      sendLogService.hotelMatchServiceLogs(res, data);
      dispatch(addHotelMatch(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {});
};

export const updateMatchHotelDataService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("matchHotel", data)
    .then(res => {
      sendLogService.hotelMatchServiceLogs(res, data);
      dispatch(updateHotelMatch(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {});
};

export const deleteMatchHotelDataService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete("matchHotel", { params: { id: data } })
    .then(res => {
      sendLogService.hotelMatchServiceLogs(res, data);
      dispatch(deleteHotelMatch(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {});
};
