import { normalizeOperatorContracts } from "redux/schemas/operatorContracts";
import { GET_HOTEL_CONTRACTS } from "../actions/types";

const initialState = {
  contracts: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HOTEL_CONTRACTS:
      const normalizedData = normalizeOperatorContracts(action.payload);
      return { ...state, contracts: normalizedData.entities.contracts };
    default:
      return state;
  }
};
