// import axios from "axios";

// const token = localStorage.getItem("token");

// const axiosDefault = axios.create({
//   baseURL: "http://localhost:85/api/",
//   headers: {
//     "Content-Type": "application/json"
//   }
// });

// axiosDefault.interceptors.response.use(
//   function(config) {
//     if (
//       config.data.status == "Token is Expired" ||
//       config.data.status == "Token is Invalid" ||
//       config.data.status == "User is not active"
//     ) {

//       localStorage.removeItem("token");
//       window.location.pathname = "/login";
//     }
//     return config;
//   },
//   function(error) {
//     if (error?.response?.data?.status === "User is not active") {

//         localStorage.removeItem("token");
//         localStorage.removeItem("selectedCompany");
//         localStorage.removeItem("selectedCompanyName");

//         window.location.pathname = "/login";
//       }
    
//     //Canl覺daki config'de altaki kod sat覺r覺 olmamal覺.
//     return Promise.reject(error);
//   }
// );

// export default axiosDefault;


// //// stage api 

import axios from "axios";

const token = localStorage.getItem("token");

const axiosDefault =  axios.create({
  baseURL: "https://stagebot.proxolab.com/api/",
  headers: {
    "Content-Type": "application/json" 
  }
});

axiosDefault.interceptors.response.use(
  function(config) {
    if (
      config.data.status == "Token is Expired" ||
      config.data.status == "Token is Invalid" ||
      config.data.status == "User is not active"
    ) {

      localStorage.removeItem("token");
      window.location.pathname = "/login";
    }
    return config;
  },
  function(error) {
    if (error?.response?.data?.status === "User is not active") {

        localStorage.removeItem("token");
        localStorage.removeItem("selectedCompany");
        localStorage.removeItem("selectedCompanyName");

        window.location.pathname = "/login";
      }
    
    //Canl覺daki config'de altaki kod sat覺r覺 olmamal覺.
    return Promise.reject(error);
  }
);

export default axiosDefault;



// // live (keyf) api 

// import axios from "axios";

// const token = localStorage.getItem("token");

// const axiosDefault =  axios.create({
//   baseURL: "https://botapi.mundosai.com/api/",
//   headers: {
//     "Content-Type": "application/json" 
//   }
// });

// axiosDefault.interceptors.response.use(
//   function(config) {
//     if (
//       config.data.status == "Token is Expired" ||
//       config.data.status == "Token is Invalid" ||
//       config.data.status == "User is not active"
//     ) {

//       localStorage.removeItem("token");
//       window.location.pathname = "/login";
//     }
//     return config;
//   },
//   function(error) {
//     if (error?.response?.data?.status === "User is not active") {

//         localStorage.removeItem("token");
//         localStorage.removeItem("selectedCompany");
//         localStorage.removeItem("selectedCompanyName");

//         window.location.pathname = "/login";
//       }
    
//   }
// );

// export default axiosDefault;



// //// meetingpoint api 

// import axios from "axios";

// const token = localStorage.getItem("token");

// const axiosDefault =  axios.create({
//   baseURL: "https://mpapi.mundosai.com/api/",
//   headers: {
//     "Content-Type": "application/json" 
//   }
// });


// axiosDefault.interceptors.response.use(
//   function(config) {
//     if (
//       config.data.status == "Token is Expired" ||
//       config.data.status == "Token is Invalid" ||
//       config.data.status == "User is not active"
//     ) {

//       localStorage.removeItem("token");
//       window.location.pathname = "/login";
//     }
//     return config;
//   },
//   function(error) {
//     if (error?.response?.data?.status === "User is not active") {

//         localStorage.removeItem("token");
//         localStorage.removeItem("selectedCompany");
//         localStorage.removeItem("selectedCompanyName");

//         window.location.pathname = "/login";
//       }
    
//     //Canl覺daki config'de altaki kod sat覺r覺 olmamal覺.
//     return Promise.reject(error);
//   }
// );

// export default axiosDefault;