import React, { useState, useEffect, Suspense } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { getAdminCompaniesService } from "services/company";
import { Redirect, useLocation } from "react-router";
import Loader from "@jumbo/components/Contract/Loader";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",

    "& > *": {
      backgroundColor: lighten(theme.palette.background.paper, 0.1),
      margin: theme.spacing(2),
      width: theme.spacing(32),
      height: theme.spacing(32)
    }
  },
  paper: {
    position: "relative",
    alignItems: "center",
    marginLeft: theme.spacing(6.5),
    marginRight: theme.spacing(6.5)
  }
}));
// variant = 'default', 'standard'

const ChooseCompany = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminCompaniesService());
  }, [dispatch]);

  if (!localStorage.getItem("selectedCompany")) {
    localStorage.setItem("selectedCompany", 1);

    return (
      <Suspense fallback={<Loader />}>
        <Redirect to="/dashboard" />
      </Suspense>
    );
  }
};
export default ChooseCompany;
