import {
  GET_OFFER_LIST,
  DELETE_OFFER_LIST,
  CLEAR_OFFER_LIST,
} from "../actions/types";

const initialState = {
  offerList: {
    SH: [],
    DN: [],
    WB: [],
    LMX: [],
    FOR: [],
    SIT: [],
    PP: [],
    WEBBEDS: [],
    JUMBO: [],
    LMT: [],
    XPUR: [],
    IVC: [],
  },
};

const assignUId = (data, oprName) => {
  return data.map((value) => {
    const uid = (Math.random() + 1).toString(36).substring(7) + oprName;
    return { ...value, uid: uid };
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_LIST:
      console.log(action.payload);
      return {
        ...state,
        offerList: {
          DN: action.payload.DN
            ? assignUId(action.payload.DN, "DN")
            : state.offerList.DN,
          SH: action.payload.SH
            ? assignUId(action.payload.SH, "SH")
            : state.offerList.SH,
          WB: action.payload.WB
            ? assignUId(action.payload.WB, "WB")
            : state.offerList.WB,
          PP: action.payload.PP
            ? assignUId(action.payload.PP, " PP")
            : state.offerList.PP,
          LMX: action.payload.LMX
            ? assignUId(action.payload.LMX, "LMX")
            : state.offerList.LMX,
          FOR: action.payload.FOR
            ? assignUId(action.payload.FOR, "FOR")
            : state.offerList.FOR,
          SIT: action.payload.SIT
            ? assignUId(action.payload.SIT, "SIT")
            : state.offerList.SIT,
          WEBBEDS: action.payload.WEBBEDS
            ? assignUId(action.payload.WEBBEDS, "WEBBEDS")
            : state.offerList.WEBBEDS,
          JUMBO: action.payload.JUMBO
            ? assignUId(action.payload.JUMBO, "JUMBO")
            : state.offerList.JUMBO,
          LMT: action.payload.LMT
            ? assignUId(action.payload.LMT, "LMT")
            : state.offerList.LMT,
          XPUR: action.payload.XPUR
            ? assignUId(action.payload.XPUR, "XPUR")
            : state.offerList.XPUR,
          IVC: action.payload.IVC
            ? assignUId(action.payload.IVC, "IVC")
            : state.offerList.IVC,
        },
      };

    case DELETE_OFFER_LIST:
      let newState ,deletedUids
      newState = deletedUids = { SH: [],
        DN: [],
        WB: [],
        LMX: [],
        FOR: [],
        SIT: [],
        PP: [],
        WEBBEDS: [],
        JUMBO: [],
        LMT: [],
        XPUR: [],
        IVC: []};
      Object.keys(action.payload).map(operator=>{
        action.payload[operator].map(op=>{
          deletedUids[operator].push(op.uid)
        });
      })
      Object.keys(state.offerList).map((opr)=>{
        newState[opr]=state.offerList[opr].filter((offer)=>
            !deletedUids[opr].includes(offer.uid)
          )
      })
      return {
        ...state,
        offerList: newState ,
      };
    case CLEAR_OFFER_LIST:
      return {
        ...state,
        offerList: {
          SH: [],
          DN: [],
          WB: [],
          LMX: [],
          FOR: [],
          SIT: [],
          PP: [],
          WEBBEDS: [],
          LMT: [],
          XPUR: [],
          JUMBO: [],
          IVC: [],
        },
      };

    default:
      return state;
  }
};
