import { fetchStart, fetchError, fetchSuccess } from 'redux/actions';
import { getContractsByHotel } from 'redux/actions/operatorContracts';
import axios from './axios';

export const getContractsByHotelService = (operatorIds, hotelIds) => dispatch => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get('/hotelContracts', { params: { global_hotel_id: hotelIds, operator_ids: operatorIds } })
    .then(res => {
      dispatch(getContractsByHotel(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
