import React from "react";
import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import {
  getRoomMatch,
  addRoomMatch,
  updateRoomMatch,
  deleteRoomMatch
} from "redux/actions/roomMatch";
import axios from "./axios";

/**PROXOLAB LOGGER **/
import sendLogService from "./proxolabLogger/Logger.js";
/**PROXOLAB LOGGER **/

export const getMatchRoomsDataService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("matchRooms", {
      params: {
        global_hotel_id: data.hotel_id
        // global_room_id: data.room_id
      }
    })
    .then(res => {
      dispatch(getRoomMatch(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {});
};

export const addMatchRoomDataService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("matchRoom", data)
    .then(res => {
      res.data.data["global_room_name"] = data.selections.room_name;
      sendLogService.roomMatchServiceLogs(res, data);
      dispatch(addRoomMatch(res));
      dispatch(fetchSuccess());
      return res.data.code;
    })
    .catch(e => {});
};

export const updateMatchRoomDataService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .put("matchRoom", data)
    .then(res => {
      dispatch(updateRoomMatch(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {});
};

export const deleteMatchRoomDataService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .delete("matchRoom", { data })
    .then(res => {
      dispatch(deleteRoomMatch(data));
      dispatch(fetchSuccess());
      return res.data.code;
    })
    .catch(e => {});
};
