import React, { useState, useEffect, lazy, Suspense, Fragment } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from "@material-ui/core";
import {
  authUserMemo,
  countryMemo,
  regionsMemo,
  sub_cityMemo,
  cityMemo,
  // loginUser,
  usersObject,
  // officeMemo,
  companyData,
  companyMemo
} from "redux/selector/companyHasOfficeSelector";
// import { deleteAdminCompanyService } from "services/company";
// import Swal from "sweetalert2";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import IntlMessages from "@jumbo/utils/IntlMessages";
// import { useIntl } from "react-intl";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import EditLocationAltOutlinedIcon from "@mui/icons-material/EditLocationAltOutlined";
// import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import _ from "lodash";
import Loader from "../Contract/Loader";

const UpdateDialog = lazy(() =>
  import("./AdminCompanyDialog/UpdateCompanyDialog")
);
const DestinationDialog = lazy(() =>
  import("./AdminOfficeDialog/AddDestination")
);
const UpdateDestinationDialog = lazy(() =>
  import("./AdminOfficeDialog/UpdateDestination")
);
const ShowDestinationDialog = lazy(() =>
  import("./AdminOfficeDialog/ShowDestination")
);

const CustomTablePagination = props => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };
  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

CustomTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

export default function Company(props) {
  const {
    companyState,
    countryCache,
    cityCache,
    divisionCache,
    divisionCityCache,
    subCityCache,
    setCountryCache,
    setDivisionCache,
    setDivisionCityCache,
    setCityCache,
    setSubCityCache
  } = props;

  const [updateData, setUpdateData] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [companiesDestination, setCompaniesDestination] = useState([]);

  const [openDestinationDialog, setOpenDestinationDialog] = useState(false);
  const [openUpdateDesDialog, setOpenUpdateDesDialog] = useState(false);
  const [openShowDesDialog, setOpenShowDesDialog] = useState(false);
  const [updateDestination, setUpdateDestination] = useState(false);
  const [addDestination, setAddDestination] = useState(false);
  const [showDestination, setShowDestination] = useState(false);
  const [selectCompanyDestination, setSelectCompanyDestination] = useState({});
  const [selectCompanyUpdateDes, setSelectCompanyUpdateDes] = useState({});
  const companyOrEnterprise = useSelector(authUserMemo);
  const countryState = useSelector(countryMemo);
  const regionsState = useSelector(regionsMemo);
  const subCityState = useSelector(sub_cityMemo);
  const cityState = useSelector(cityMemo);
  const userData = useSelector(usersObject);
  const companyObject = useSelector(companyMemo);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let companyDestination = {};

    Object.values(companyObject).map(element => {
      if (element.get_destination?.destinations) {
        companyDestination[element.id] = JSON.parse(
          element.get_destination.destinations
        );
      }
    });

    if (openUpdateDesDialog) {
      setSelectCompanyUpdateDes(
        companyDestination[updateDestination.id]
          ? companyDestination[updateDestination.id]
          : []
      );
    }
    setCompaniesDestination(companyDestination);
    setCompaniesData(Object.values(companyData));
  }, [userData, companyObject, updateDestination, selectCompanyDestination]);

  return (
    <Fragment>
      {openUpdateDialog && (
        <Suspense fallback={<Loader />}>
          <UpdateDialog
            open={openUpdateDialog}
            setOpen={setOpenUpdateDialog}
            updateData={updateData}
            countryCache={countryCache}
            divisionCache={divisionCache}
            divisionCityCache={divisionCityCache}
            cityCache={cityCache}
            subCityCache={subCityCache}
            setCountryCache={setCountryCache}
            setDivisionCache={setDivisionCache}
            setDivisionCityCache={setDivisionCityCache}
            setCityCache={setCityCache}
            setSubCityCache={setSubCityCache}
          />
        </Suspense>
      )}

      {openDestinationDialog && (
        <Suspense fallback={<Loader />}>
          <DestinationDialog
            open={openDestinationDialog}
            setOpen={setOpenDestinationDialog}
            companyData={addDestination}
            selectCompanyDestination={selectCompanyDestination}
          />
        </Suspense>
      )}

      {openUpdateDesDialog && selectCompanyUpdateDes && (
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                top: 0,
                left: 0,
                position: "absolute",
                zIndex: 9999,
                width: "100%",
                height: "100%"
              }}
            >
              <Loader />
            </div>
          }
        >
          <UpdateDestinationDialog
            open={openUpdateDesDialog}
            setOpen={setOpenUpdateDesDialog}
            companyData={updateDestination}
            selectCompanyUpdateDes={selectCompanyUpdateDes}
          />
        </Suspense>
      )}

      {openShowDesDialog && (
        <Suspense fallback={<Loader />}>
          <ShowDestinationDialog
            open={openShowDesDialog}
            setOpen={setOpenShowDesDialog}
            companyData={showDestination}
          />
        </Suspense>
      )}

      <MaterialTable
        actions={[
          {
            icon: () => (
              <Button
                style={{ backgroundColor: "transparent" }}
                endIcon={<AddBusinessOutlinedIcon />}
              >
                <IntlMessages id="add.company" />
              </Button>
            ),
            title: "add",
            tooltip: "Add Company",
            hidden: companyOrEnterprise == 11 || companyOrEnterprise == 20,
            isFreeAction: true
          }
        ]}
        components={{
          OverlayLoading: () => <Loader />,
          Header: props => (
            <>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <IntlMessages id="company.name" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="official.title" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="tax.office" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="tax.no" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="email" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="phone" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="website" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="continent" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="country" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="city" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="sub.city" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="district" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="actions" />
                  </TableCell>
                </TableRow>
              </TableHead>
            </>
          ),
          Body: props => (
            <>
              <TableBody>
                {(rowsPerPage > 0
                  ? companyState?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : companyState
                ).map(element => {
                  return (
                    <TableRow>
                      <TableCell>
                        {element.get_destination ? (
                          <Button
                            // disabled={companyOrEnterprise !== 11}
                            onClick={(event, rowData) => {
                              if (companyOrEnterprise === 11) {
                                setUpdateDestination(element);
                                setOpenUpdateDesDialog(true);
                              } else {
                                setOpenShowDesDialog(true);
                                setShowDestination(element);
                              }
                            }}
                          >
                            <EditLocationAltOutlinedIcon />
                            <IntlMessages
                              id={
                                companyOrEnterprise === 11
                                  ? "update.destination"
                                  : "show.destination"
                              }
                            />
                          </Button>
                        ) : (
                          <Button
                            disabled={companyOrEnterprise !== 11}
                            onClick={(event, rowData) => {
                              setSelectCompanyDestination(
                                companiesDestination[element.company_id]
                                  ? companiesDestination[element.company_id]
                                  : []
                              );
                              setAddDestination(element);
                              setOpenDestinationDialog(true);
                            }}
                          >
                            <AddLocationAltOutlinedIcon />
                            <IntlMessages id="add.destination" />
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>{element.name}</TableCell>
                      <TableCell>{element.official_title}</TableCell>
                      <TableCell>{element.tax_office}</TableCell>
                      <TableCell>{element.tax_no}</TableCell>
                      <TableCell>
                        {element.email ? element.email : "---"}
                      </TableCell>
                      <TableCell>
                        {element.phone ? element.phone : "---"}
                      </TableCell>
                      <TableCell>
                        {element.website ? element.website : "---"}
                      </TableCell>
                      <TableCell>
                        {element.region_id
                          ? regionsState[element.region_id]?.name
                          : "---"}
                      </TableCell>
                      <TableCell>
                        {element.country_id
                          ? countryState[element.country_id]?.name
                          : "---"}
                      </TableCell>
                      <TableCell>
                        {element.city_id
                          ? cityState[element.city_id]?.name
                          : "---"}
                      </TableCell>
                      <TableCell>
                        {element.subcity_id
                          ? subCityState[element.subcity_id]?.name
                          : "---"}
                      </TableCell>
                      <TableCell>
                        {element.district ? element.district : "---"}
                      </TableCell>
                      <TableCell>
                        <Button
                          disabled={companyOrEnterprise !== 11}
                          onClick={(event, rowData) => {
                            setUpdateData(element);
                            setOpenUpdateDialog(true);
                          }}
                        >
                          <CreateOutlinedIcon />
                        </Button>
                      </TableCell>{" "}
                    </TableRow>
                  );
                })}
              </TableBody>
            </>
          ),
          Pagination: props => (
            <>
              <TablePagination
                style={{ display: "flex", justifyContent: "right" }}
                rowsPerPageOptions={[5, 10, 20, { label: "All", value: -1 }]}
                colSpan={3}
                count={companyState.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page"
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={CustomTablePagination}
              />
            </>
          )
        }}
        options={{
          showTitle: false,
          showSelectAllCheckbox: false,
          showTextRowsSelected: false,
          // actionsColumnIndex: -1,
          search: false,
          filtering: false,
          paging: true,
          addRowPosition: "first"
        }}
      />
    </Fragment>
  );
}
