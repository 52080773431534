import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  addMasterCompanyService,
  updateMasterCompanyService
} from "services/company";
import {
  getMasterCountriesService,
  getMasterRegionsService,
  getMasterDivisionsService,
  getMasterCitiesService,
  getMasterDivisionCitiesService,
  getMasterSubCitiesService,
  getSelectedCompanyRegions,
  getSelectedCompanyCountries,
  getSelectedCompanyCities,
  getSelectedCompanySubCities,
  getSelectedCompanyLocation
} from "services/destinations";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@material-ui/core";
import {
  emailNotValid,
  requiredMessage
} from "../../../../constants/ErrorMessages";
import AppSelectBox from "../../../Common/formElements/AppSelectBox";
import AppTextInput from "../../../Common/formElements/AppTextInput";
import GridContainer from "../../../GridContainer";
import { isValidEmail } from "../../../../utils/commonHelper";
import { PhoneFormatCustom } from "../../../Common/NumberFormat/NumberFormat";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 16,
      color: theme.palette.common.dark
    }
  },
  option: {
    fontSize: 15
  },
  form: {
    "& .MuiFormHelperText-contained": {
      color: "#f44336"
    },
    "& .MuiFormHelperText-root": {
      color: "#f44336"
    },
    "& .MuiInputBase-root": {
      color: "rgb(0 0 0 / 87%)"
    }
  }
}));

/* Bu fonksiyon kullanılmadığı ve sonrasından kullanılıp kullanılmayacağı belli olmadığı için Ahmet Karakuş tarafından comment'landı.
function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    } else if (!value) {
      setPhoneNumber("");
    }
  }, [phoneNumber, value]);

  const onNumberChange = (number) => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return (
    <NumberFormat
      {...other}
      onValueChange={onNumberChange}
      value={phoneNumber}
      format="(###) ###-####"
    />
  );
}
*/

const AddEditCompany = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentMasterCompany } = useSelector(({ company }) => company);
  const { regions, countries, cities, subCities, divisions } = useSelector(
    ({ destinations }) => destinations
  );

  const [companyData, setCompanyData] = useState({
    name: "",
    email: "",
    phone: "",
    website: "",
    officialTitle: "",
    taxOffice: "",
    taxNo: ""
  });

  const [errorsData, setErrorsData] = useState({
    name: "",
    email: "",
    phone: "",
    website: "",
    officialTitle: "",
    taxOffice: "",
    taxNo: ""
  });

  const [locationData, setLocationData] = useState({
    region: null,
    country: null,
    city: null,
    division: null,
    subcity: null,
    district: currentMasterCompany ? currentMasterCompany.district : null
  });

  const dispatch = useDispatch();

  // Website Validation
  const websiteExpression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const websiteRegex = new RegExp(websiteExpression);
  console.log(currentMasterCompany);
  useEffect(() => {
    if (currentMasterCompany) {
      setCompanyData({
        name: currentMasterCompany.name,
        email: currentMasterCompany.email,
        phone: currentMasterCompany.phone,
        website: currentMasterCompany.website,
        officialTitle: currentMasterCompany.official_title,
        taxOffice: currentMasterCompany.tax_office,
        taxNo: currentMasterCompany.tax_no
      });

      const adress = async () => {
        const location = {
          region: currentMasterCompany.region_id,
          country: currentMasterCompany.country_id,
          city: currentMasterCompany.city_id,
          division: currentMasterCompany.division_id
            ? currentMasterCompany.division_id
            : "",
          subcity: currentMasterCompany.subcity_id
        };
        const { data: selectedLocation } = await getSelectedCompanyLocation(
          location
        );
        setLocationData({
          ...locationData,
          region: selectedLocation.data.regions.find(
            item => item.id === currentMasterCompany.region_id
          ),
          country: selectedLocation.data.countries.find(
            item => item.id === currentMasterCompany.country_id
          ),
          city: selectedLocation.data.cities.find(
            item => item.id === currentMasterCompany.city_id
          ),
          division: selectedLocation.data.divisions
            ? selectedLocation.data.divisions.find(
                item => item.id == currentMasterCompany.division_id
              )
            : null,
          subcity: selectedLocation.data.subCities
            ? selectedLocation.data.subCities.find(
                item => item.id === currentMasterCompany.subcity_id
              )
            : null
        });
      };

      adress();
    }
  }, [currentMasterCompany]);

  useEffect(() => {
    dispatch(getMasterRegionsService());
  }, []);

  useEffect(() => {
    if (locationData.region) {
      dispatch(getMasterCountriesService(locationData.region));
    }
  }, [locationData.region]);

  useEffect(() => {
    if (locationData.country && locationData.country.has_division !== 1) {
      dispatch(getMasterCitiesService(locationData.country));
    } else if (locationData.country && locationData.country.has_division == 1) {
      dispatch(getMasterDivisionsService(locationData.country));
    }
  }, [locationData.country]);

  useEffect(() => {
    if (locationData.division) {
      dispatch(getMasterDivisionCitiesService(locationData.division));
    }
  }, [locationData.division]);

  useEffect(() => {
    if (locationData.city) {
      dispatch(getMasterSubCitiesService(locationData.city));
    }
  }, [locationData.city]);

  const onSubmitClick = () => {
    if (!companyData.email) {
      setErrorsData({ ...errorsData, email: requiredMessage });
    } else if (!isValidEmail(companyData.email)) {
      setErrorsData({ ...errorsData, email: emailNotValid });
    }
    if (!companyData.officialTitle) {
      setErrorsData({ ...errorsData, officialTitle: requiredMessage });
    }
    if (!companyData.taxOffice) {
      setErrorsData({ ...errorsData, taxOffice: requiredMessage });
    }
    if (!companyData.taxNo) {
      setErrorsData({ ...errorsData, taxNo: requiredMessage });
    }
    if (!companyData.name) {
      setErrorsData({ ...errorsData, name: requiredMessage });
    }

    if (!companyData.website || !companyData.website.match(websiteRegex)) {
      setErrorsData({
        ...errorsData,
        website: "Please enter valid website adress"
      });
    }
    if (!companyData.phone || companyData.phone.length < 18) {
      setErrorsData({
        ...errorsData,
        phone: "Please enter valid phone number"
      });
    }
    if (!companyData.website || !companyData.website.match(websiteRegex)) {
      setErrorsData({
        ...errorsData,
        website: "Please enter valid website adress"
      });
    }
    if (!companyData.phone || companyData.phone.length < 18) {
      setErrorsData({
        ...errorsData,
        phone: "Please enter valid phone number"
      });
    }
    if (
      companyData.name &&
      companyData.email &&
      isValidEmail(companyData.email) &&
      companyData.officialTitle &&
      companyData.taxOffice &&
      companyData.taxNo &&
      companyData.website &&
      companyData.phone &&
      companyData.phone.length >= 18 &&
      companyData.website.match(websiteRegex)
    ) {
      const companyInstance = {
        name: companyData.name,
        phone: companyData.phone,
        email: companyData.email,
        website: companyData.website,
        region_id: locationData.region ? locationData.region.id : null,
        country_id: locationData.country ? locationData.country.id : null,
        city_id: locationData.city ? locationData.city.id : null,
        division_id: locationData.division ? locationData.division.id : null,
        subcity_id: locationData.subcity ? locationData.subcity.id : null,
        district: locationData.district,
        tax_no: companyData.taxNo,
        tax_office: companyData.taxOffice,
        official_title: companyData.officialTitle
      };
      if (currentMasterCompany) {
        companyInstance.id = currentMasterCompany.id;

        dispatch(
          updateMasterCompanyService(companyInstance, () => {
            onCloseDialog();
          })
        );
      } else {
        dispatch(
          addMasterCompanyService(companyInstance, () => {
            onCloseDialog();
          })
        );
      }
    } else {
      MySwal.fire("Oops...", `Please fill the required fields !`, "error");
    }
  };

  const handleChange = (e, name) => {
    setCompanyData({ ...companyData, [name]: e.target.value });
    setErrorsData({ ...errorsData, [name]: "" });
  };

  return (
    <Dialog
      open={open}
      className={classes.dialogRoot}
      maxWidth="sm"
      fullWidth="on"
    >
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentMasterCompany ? "Edit Company Details" : "Create New Company"}
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }} sx={{ borderRadius: 0 }}>
          <AppTextInput
            variant="standard"
            id="standard-basic"
            label="Company Name"
            required
            value={companyData.name}
            autoComplete="off"
            onChange={e => {
              handleChange(e, "name");
            }}
            helperText={errorsData.name}
            error={!companyData.name}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            variant="standard"
            id="standard-basic"
            label="Email Address"
            autoComplete="new-password"
            value={companyData.email}
            required
            onChange={e => {
              handleChange(e, "email");
            }}
            helperText={errorsData.email}
            error={!isValidEmail(companyData.email)}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <TextField
            label="Phone"
            fullWidth
            variant="standard"
            id="standard-basic"
            size="small"
            required
            placeholder="+xx (xxx) xxx-xxxx"
            // placeholder="Phone Number"
            className={classes.form}
            value={companyData.phone ? companyData.phone : ""}
            onChange={e => {
              handleChange(e, "phone");
            }}
            helperText={errorsData.phone}
            name="tel"
            error={!companyData.phone || companyData.phone.length < 18}
            autoComplete="off"
            InputProps={{
              inputComponent: PhoneFormatCustom
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            variant="standard"
            id="standard-basic"
            label="Website"
            value={companyData.website ? companyData.website : ""}
            required
            autoComplete="nope"
            onChange={e => {
              handleChange(e, "website");
            }}
            helperText={errorsData.website}
            error={
              companyData.website?.match(websiteRegex) && companyData.website
                ? false
                : true
            }
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            variant="standard"
            id="standard-basic"
            label="Official Title"
            value={companyData.officialTitle}
            autoComplete="off"
            required
            onChange={e => {
              handleChange(e, "officialTitle");
            }}
            helperText={errorsData.officialTitle}
            error={!companyData.officialTitle}
          />
        </Box>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              variant="standard"
              id="standard-basic"
              required
              label="Tax Office"
              autoComplete="off"
              value={companyData.taxOffice}
              onChange={e => {
                handleChange(e, "taxOffice");
              }}
              helperText={errorsData.taxOffice}
              error={!companyData.taxOffice}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AppTextInput
              variant="standard"
              id="standard-basic"
              required
              label="Tax No"
              autoComplete="off"
              value={companyData.taxNo}
              onChange={e => {
                handleChange(e, "taxNo");
              }}
              helperText={errorsData.taxNo}
              error={!companyData.taxNo}
            />
          </Grid>
        </GridContainer>

        {/* <CmtList
          Tax No
          Tax Office
          data={phone}
          renderRow={(item, index) => (
            <GridContainer style={{ marginBottom: 2 }} key={index}>
              <Grid item xs={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Phone"
                  onChange={number => onPhoneNoAdd(number, index)}
                  helperText={phoneError}
                  InputProps={{
                    inputComponent: PhoneNumberInput,
                    inputProps: { value: item.phone },
                  }}
                />
              </Grid>
            </GridContainer>
          )}
        /> */}
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoHighlight
              classes={{
                option: classes.option
              }}
              options={regions}
              getOptionLabel={option => option.name}
              value={locationData.region}
              renderInput={params => (
                <AppTextInput
                  {...params}
                  label="Choose a region"
                  variant="standard"
                  id="standard-basic"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password"
                  }}
                />
              )}
              onChange={(event, value) => {
                setLocationData({
                  ...locationData,
                  region: value,
                  country: null,
                  city: null,
                  division: null,
                  subcity: null,
                  district: null
                });
              }}
            />
          </Grid>
          {locationData.region && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                autoHighlight
                classes={{
                  option: classes.option
                }}
                options={countries}
                value={locationData.country}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    label="Choose a country"
                    variant="standard"
                    id="standard-basic"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password"
                    }}
                  />
                )}
                onChange={(event, value) => {
                  setLocationData({
                    ...locationData,
                    country: value,
                    city: null,
                    division: null,
                    subcity: null,
                    district: null
                  });
                }}
              />
            </Grid>
          )}
        </GridContainer>
        <GridContainer style={{ marginBottom: 12 }}>
          {locationData.country &&
            divisions?.length > 0 &&
            locationData.country.has_division == 1 && (
              <>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    autoHighlight
                    classes={{
                      option: classes.option
                    }}
                    options={divisions}
                    getOptionLabel={option => option.name}
                    value={locationData.division}
                    renderInput={params => (
                      <AppTextInput
                        {...params}
                        label="Choose a division"
                        variant="standard"
                        id="standard-basic"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password"
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      setLocationData({
                        ...locationData,
                        division: value,
                        subcity: null,
                        division: null,
                        district: null
                      });
                    }}
                  />
                </Grid>
                {locationData.division && cities.length > 0 && (
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      autoHighlight
                      classes={{
                        option: classes.option
                      }}
                      options={cities ? cities : []}
                      value={locationData.city}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <AppTextInput
                          {...params}
                          label="Choose a city"
                          variant="standard"
                          id="standard-basic"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password"
                          }}
                        />
                      )}
                      onChange={(event, value) => {
                        setLocationData({
                          ...locationData,
                          city: value,
                          subcity: null,
                          district: null
                        });
                      }}
                    />
                  </Grid>
                )}
              </>
            )}
          <Grid item xs={12} sm={6}>
            {locationData.country &&
              cities.length > 0 &&
              !locationData.division &&
              locationData.country.has_division !== 1 && (
                <Autocomplete
                  autoHighlight
                  classes={{
                    option: classes.option
                  }}
                  options={cities}
                  value={locationData.city}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <AppTextInput
                      {...params}
                      label="Choose a city"
                      variant="standard"
                      id="standard-basic"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password"
                      }}
                    />
                  )}
                  onChange={(event, value) => {
                    setLocationData({
                      ...locationData,
                      city: value,
                      subcity: null,
                      district: null
                    });
                  }}
                />
              )}
          </Grid>

          {locationData.city && subCities.length > 0 && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                autoHighlight
                classes={{
                  option: classes.option
                }}
                options={subCities}
                getOptionLabel={option => option.name}
                value={locationData.subcity}
                renderInput={params => (
                  <AppTextInput
                    {...params}
                    label="Choose a sub city"
                    variant="standard"
                    id="standard-basic"
                  />
                )}
                onChange={(event, value) => {
                  setLocationData({
                    ...locationData,
                    subcity: value,
                    district: null
                  });
                }}
              />
            </Grid>
          )}
        </GridContainer>
        <GridContainer style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={12}>
            {locationData.region && (
              <TextField
                fullWidth
                label="District"
                valueKey="slug"
                variant="standard"
                autoComplete="off"
                id="standard-basic"
                labelKey="title"
                value={locationData.district}
                onChange={e =>
                  setLocationData({ ...locationData, district: e.target.value })
                }
              />
            )}
          </Grid>
        </GridContainer>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditCompany;

AddEditCompany.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func
};
