import React, { useState, useEffect, lazy, Suspense } from "react";
import MaterialTable from "material-table";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import Button from "@material-ui/core/Button";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import EditLocationAltOutlinedIcon from "@mui/icons-material/EditLocationAltOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import IconButton from "@material-ui/core/IconButton";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination
} from "@material-ui/core";
import {
  companyData,
  authUserMemo,
  officeMemo,
  countryMemo,
  regionsMemo,
  sub_cityMemo,
  cityMemo,
  loginUser,
  usersObject,
  companyMemo,
  nonEnterprise,
  userMemo
} from "redux/selector/companyHasOfficeSelector";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { deleteAdminOfficeService } from "services/office";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AddOfficeDialog from "./AdminOfficeDialog/AddOfficeDialog";
import _ from "lodash";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import Loader from "../Contract/Loader";

const UpdateOfficeDialog = lazy(() =>
  import("./AdminOfficeDialog/UpdateOfficeDialog")
);
const DestinationDialog = lazy(() =>
  import("./AdminOfficeDialog/AddDestination")
);
const UpdateDestinationDialog = lazy(() =>
  import("./AdminOfficeDialog/UpdateDestination")
);
const ShowDestinationDialog = lazy(() =>
  import("./AdminOfficeDialog/ShowDestination")
);

/* const useStyles = makeStyles(theme => ({
  footer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
})); */

const CustomTablePagination = props => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };
  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

CustomTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

export default function Office({
  countryCache,
  cityCache,
  divisionCache,
  divisionCityCache,
  subCityCache,
  setCountryCache,
  setDivisionCache,
  setDivisionCityCache,
  setCityCache,
  setSubCityCache
}) {
  //const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const companies = useSelector(companyData);
  //const companyOrEnterprise = useSelector(authUserMemo);
  const officeData = useSelector(officeMemo);
  const countryState = useSelector(countryMemo);
  const regionsState = useSelector(regionsMemo);
  const authUserData = useSelector(loginUser);
  const subCityState = useSelector(sub_cityMemo);
  const cityState = useSelector(cityMemo);
  const userData = useSelector(usersObject);
  //const companyState = useSelector(companyMemo);
  const companyState = useSelector(nonEnterprise);

  const [openDestinationDialog, setOpenDestinationDialog] = useState(false);
  const [openUpdateDesDialog, setOpenUpdateDesDialog] = useState(false);
  const [openShowDesDialog, setOpenShowDesDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [updateDestination, setUpdateDestination] = useState(false);
  const [addDestination, setAddDestination] = useState(false);
  const [showDestination, setShowDestination] = useState(false);
  const [offices, setOffices] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [companiesDestination, setCompaniesDestination] = useState([]);
  const [selectCompanyDestination, setSelectCompanyDestination] = useState({});
  const [selectCompanyUpdateDes, setSelectCompanyUpdateDes] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - offices.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteFunction = deleteOffice => {
    Swal.fire({
      title: intl.formatMessage({ id: "are.you.sure?" }),
      text: intl.formatMessage(
        {
          id: "you.want.to.delete.office"
        },
        { name: deleteOffice.name }
      ) /* 'You want to delete ' + deleteOffice.name + ' office ?', */,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: intl.formatMessage({ id: "cancel" }),
      confirmButtonText: intl.formatMessage({ id: "yes.delete.it" })
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteAdminOfficeService(deleteOffice));
        Swal.fire(
          "Deleted!",
          intl.formatMessage({ id: "office.has.been.deleted" }),
          "success"
        );
      }
    });
  };

  useEffect(() => {
    let newData = [];
    let userCompanyData = {};
    let companyDestination = {};
    let mergeCompany = {};
    if (authUserData.authority_level == 20) {
      if (userData[authUserData.id]?.pivot_office && officeData) {
        userData[authUserData.id].pivot_office.map(officeID => {
          newData.push(officeData[officeID]);
          if (!userCompanyData[officeData[officeID].company_id]) {
            userCompanyData[officeData[officeID].company_id] =
              companies[officeData[officeID].company_id];
          }
        });
        setCompaniesData(Object.values(userCompanyData));
        setOffices(newData);
      }
    } else {
      Object.values(officeData).map(element => {
        if (element.get_destination?.destinations) {
          if (companyDestination[element.company_id]) {
            mergeCompany = _.merge(
              companyDestination[element.company_id],
              JSON.parse(element.get_destination.destinations)
            );
            companyDestination[element.company_id] = mergeCompany;
          } else {
            companyDestination[element.company_id] = JSON.parse(
              element.get_destination.destinations
            );
          }
        }
      });
      if (openUpdateDesDialog) {
        setSelectCompanyUpdateDes(
          companyDestination[updateDestination.company_id]
            ? companyDestination[updateDestination.company_id]
            : []
        );
      }
      setCompaniesDestination(companyDestination);
      setCompaniesData(companyState);
      setOffices(Object.values(officeData));
    }
  }, [userData, officeData, updateDestination, selectCompanyDestination]);

  // const isAllCompanyDestinationsFull = () => {
  //   const isFullArray = Object.values(companiesDestination).map(element => {
  //     return Object.values(element).map(regionId => {
  //       return Object.values(regionId).reduce((acc, curr) => {
  //         acc.push(curr.all_select_region);
  //         console.log(acc);
  //         return acc;
  //       }, []);
  //     });
  //   });

  //   const isFull = isFullArray.reduce((acc, curr) => {
  //     curr.every(true)

  // };

  return (
    <div>
      {openAddDialog ? (
        <AddOfficeDialog
          open={openAddDialog}
          setOpen={setOpenAddDialog}
          countryCache={countryCache}
          cityCache={cityCache}
          divisionCache={divisionCache}
          divisionCityCache={divisionCityCache}
          subCityCache={subCityCache}
          setCountryCache={setCountryCache}
          setDivisionCache={setDivisionCache}
          setDivisionCityCache={setDivisionCityCache}
          setCityCache={setCityCache}
          setSubCityCache={setSubCityCache}
          companiesData={companiesData}
        />
      ) : (
        <div />
      )}
      {openUpdateDialog ? (
        <Suspense fallback={<Loader />}>
          {" "}
          <UpdateOfficeDialog
            open={openUpdateDialog}
            setOpen={setOpenUpdateDialog}
            officeData={updateData}
            countryCache={countryCache}
            divisionCache={divisionCache}
            divisionCityCache={divisionCityCache}
            cityCache={cityCache}
            subCityCache={subCityCache}
            setCountryCache={setCountryCache}
            setDivisionCache={setDivisionCache}
            setDivisionCityCache={setDivisionCityCache}
            setCityCache={setCityCache}
            setSubCityCache={setSubCityCache}
            companiesData={companiesData}
          />
        </Suspense>
      ) : (
        <div />
      )}

      {openDestinationDialog ? (
        <Suspense fallback={<Loader />}>
          <DestinationDialog
            open={openDestinationDialog}
            setOpen={setOpenDestinationDialog}
            officeData={addDestination}
            selectCompanyDestination={selectCompanyDestination}
          />
        </Suspense>
      ) : (
        <div />
      )}

      {openUpdateDesDialog && selectCompanyUpdateDes ? (
        <Suspense fallback={<Loader />}>
          <UpdateDestinationDialog
            open={openUpdateDesDialog}
            setOpen={setOpenUpdateDesDialog}
            officeData={updateDestination}
            selectCompanyUpdateDes={selectCompanyUpdateDes}
          />
        </Suspense>
      ) : (
        <div />
      )}

      {openShowDesDialog ? (
        <Suspense fallback={<Loader />}>
          <ShowDestinationDialog
            open={openShowDesDialog}
            setOpen={setOpenShowDesDialog}
            officeData={showDestination}
          />
        </Suspense>
      ) : (
        <div />
      )}

      <MaterialTable
        actions={[
          {
            icon: () => (
              <Button
                style={{ backgroundColor: "transparent" }}
                endIcon={<AddBusinessOutlinedIcon />}
              >
                <IntlMessages id="add.office" />
                {/* <IconButton>
                  <AddBusinessOutlinedIcon />
                </IconButton> */}
              </Button>
            ),
            tooltip: "Add Office",
            hidden:
              authUserData.authority_level == 30 ||
              authUserData.authority_level == 20,
            isFreeAction: true,
            onClick: (event, rowData) => {
              setOpenAddDialog(true);
            }
          }
          /* {
            icon: () => <CreateOutlinedIcon />,
            onClick: (event, rowData) => {
              setUpdateData(rowData);
              setOpenUpdateDialog(true);
            },
          }, */
        ]}
        components={{
          OverlayLoading: () => <Loader />,
          Header: props => (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <IntlMessages id="destinations" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="office" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="company" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="email" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="phone" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="continent" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="country" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="city" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="sub.city" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="district" />
                  </TableCell>
                  <TableCell>
                    <IntlMessages id="actions" />
                  </TableCell>
                </TableRow>
              </TableHead>
            </>
          ),
          Body: props => (
            <>
              <TableBody>
                {(rowsPerPage > 0
                  ? offices?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : offices
                ).map(element => {
                  return (
                    <TableRow>
                      <TableCell>
                        {authUserData.authority_level == 20 ? (
                          <Button
                            onClick={(event, rowData) => {
                              setShowDestination(element);
                              setOpenShowDesDialog(true);
                            }}
                          >
                            <FmdGoodOutlinedIcon />
                            {` Show Destination`}
                          </Button>
                        ) : element.get_destination ? (
                          <Button
                            onClick={(event, rowData) => {
                              setUpdateDestination(element);
                              setOpenUpdateDesDialog(true);
                            }}
                          >
                            <EditLocationAltOutlinedIcon />
                            <IntlMessages id="update.destination" />
                          </Button>
                        ) : (
                          <Button
                            onClick={(event, rowData) => {
                              setSelectCompanyDestination(
                                companiesDestination[element.company_id]
                                  ? companiesDestination[element.company_id]
                                  : []
                              );
                              setAddDestination(element);
                              setOpenDestinationDialog(true);
                            }}
                          >
                            <AddLocationAltOutlinedIcon />
                            <IntlMessages id="add.destination" />
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>{element.name}</TableCell>
                      <TableCell>
                        {companies[element.company_id].name}
                      </TableCell>
                      <TableCell>
                        {element.email ? element.email : "---"}
                      </TableCell>
                      <TableCell>
                        {element.phone ? element.phone : "---"}
                      </TableCell>
                      <TableCell>
                        {element.region_id
                          ? regionsState[element.region_id]?.name
                          : "---"}
                      </TableCell>
                      <TableCell>
                        {element.country_id
                          ? countryState[element.country_id]?.name
                          : "---"}
                      </TableCell>
                      <TableCell>
                        {element.city_id
                          ? cityState[element.city_id]?.name
                          : "---"}
                      </TableCell>
                      <TableCell>
                        {element.subcity_id
                          ? subCityState[element.subcity_id]?.name
                          : "---"}
                      </TableCell>
                      <TableCell>
                        {element.district ? element.district : "---"}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={(event, rowData) => {
                            setUpdateData(element);
                            setOpenUpdateDialog(true);
                          }}
                        >
                          <CreateOutlinedIcon />
                        </Button>

                        {element.user.length == 0 && (
                          <Button
                            onClick={(event, rowData) => {
                              deleteFunction(element);
                            }}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </>
          ),
          Pagination: props => (
            <>
              <TablePagination
                style={{ display: "flex", justifyContent: "right" }}
                rowsPerPageOptions={[8, 15, 20, { label: "All", value: -1 }]}
                colSpan={3}
                count={offices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page"
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={CustomTablePagination}
              />
            </>
          )
        }}
        options={{
          showTitle: false,
          showSelectAllCheckbox: false,
          showTextRowsSelected: false,
          search: false,
          filtering: false,
          paging: true,
          addRowPosition: "first"
        }}
      />
    </div>
  );
}
