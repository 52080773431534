import { GET_OPEN_SALE, ADD_OPEN_SALE } from "../actions/types";

const initialState = {
  openSale: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPEN_SALE:
      const isSameLog = state.openSale.filter(
        log => log.pId == action.payload.pId
      );
      let openLogs = state.openSale;
      //eğer hiç log kaydı yoksa ve yenisi eklenmiyorsa
      if (openLogs.length == 0 && !action.payload.hasOwnProperty("pId")) {
        openLogs = action.payload;
      }
      //eğer hiç log kaydı yoksa ve ilk kayıt ekleniyorsa
      else if (openLogs.length == 0 && action.payload.hasOwnProperty("pId")) {
        openLogs[0] = action.payload;
      } else {
        //eğer aynı pId'ye sahip log kaydı yoksa ve yenisi ekleniyorsa
        if (isSameLog.length == 0 && action.payload.hasOwnProperty("pId")) {
          openLogs.unshift(action.payload);
          if (state.openSale.length > 5) {
            openLogs.splice(-1, 1); //pop()???
          }
        } else if (
          isSameLog.length == 0 &&
          !action.payload.hasOwnProperty("pId")
        ) {
          openLogs = action.payload;
        }
      }
      return { ...state, openSale: openLogs };
    case ADD_OPEN_SALE:
      return { ...state, openSale: [...state.openSale, action.payload.data] };
    default:
      return state;
  }
};
