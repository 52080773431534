import React, { useEffect, useState } from "react";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { blue, green, red } from "@material-ui/core/colors";
import {
  addMatchHotelDataService,
  updateMatchHotelDataService,
} from "services/hotelmatch";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import UpdateIcon from "@mui/icons-material/Update";
import SaveIcon from "@mui/icons-material/Save";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
  },
  rootcard: {
    margin: "0 auto",
    width: "95%",
    position: "center",
    backgroundColor:
      localStorage.getItem("theme-type") === "dark" ? "rgb(21 21 19 / 1%)" : "",
    padding: "8px",
  },
  root: {
    width: "55%",
    position: "center",
  },
  button: {
    float: "right",
    margin: theme.spacing(2),
    fontSize: "12px!important",
    maxWidth: "50%",
  },
}));

export default function HotelMatchModal({
  openModal,
  setOpenModal,
  rowData,
  setShowFirstRoomMatchPage,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = createTheme({
    palette: {
      primary: green,
      secondary: blue,
    },
  });
  const themeCancel = createTheme({
    palette: {
      primary: red,
    },
  });
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { matched_hotels } = useSelector(({ hotelMatch }) => hotelMatch);
  const { operators } = useSelector(({ operators }) => operators);
  const newHotelObj = JSON.parse(localStorage.getItem("hotelMatch"));
  const [data, setData] = useState({});
  let newHotelObjKeys = {
    PP: "pphotels",
    SH: "sunHotels",
    DN: "dnhotels",
    WB: "wbhotels",
    LMX: "lmxhotels",
    FOR: "forhotels",
    SIT: "sithotels",
    WEBBEDS: "webbedshotels",
    JUMBO: "jumbohotels",
    LMT: "lmthotels",
    XPUR: "xpurhotels",
    IVC: "ıvchotels",
    SDN: "sdnhotels",
    JU: "juhotels",
  };
  useEffect(() => {
    setData(rowData);
  }, [rowData]);

  const filterMatchedHotels = (hotels, matched_hotels, operator) => {
    let filteredHotels = [];
    Object.values(hotels).map((hotel) => {
      let hasHotel;
      hasHotel = Object.values(matched_hotels).filter((matched_hotel) =>
        operator === "SH"
          ? matched_hotel["SH"]?.hotel_id === hotel.hotel_id
          : operator === "DN"
          ? matched_hotel["DN"]?.hotel_id === hotel.hotel_id
          : operator === "PP"
          ? matched_hotel["PP"]?.hotel_id === hotel.hotel_id
          : operator === "LMX"
          ? matched_hotel["LMX"]?.hotel_id === hotel.hotel_id
          : operator === "WB"
          ? matched_hotel["WB"]?.hotel_id === hotel.hotel_id
          : operator === "SIT"
          ? matched_hotel["SIT"]?.hotel_id === hotel.hotel_id
          : operator === "FOR"
          ? matched_hotel["FOR"]?.hotel_id === hotel.hotel_id
          : operator === "WEBBEDS"
          ? matched_hotel["WEBBEDS"]?.hotel_id === hotel.hotel_id
          : operator === "JUMBO"
          ? matched_hotel["JUMBO"]?.hotel_id === hotel.hotel_id
          : operator === "LMT"
          ? matched_hotel["LMT"]?.hotel_id === hotel.hotel_id
          : operator === "XPUR"
          ? matched_hotel["XPUR"]?.hotel_id === hotel.hotel_id
          : operator === "IVC"
          ? matched_hotel["IVC"]?.hotel_id === hotel.hotel_id
          : operator === "SDN"
          ? matched_hotel["SDN"]?.hotel_id === hotel.hotel_id
          : operator === "JU"
          ? matched_hotel["JU"]?.hotel_id === hotel.hotel_id
          : matched_hotel.global_hotel_id === hotel.id
      );
      if (hasHotel.length == 0) {
        filteredHotels.push(hotel);
      }
    });
    return filteredHotels;
  };
  const handleChange = (name, value) => {
    if (name != "global_hotel_id") {
      setData({ ...data, [name]: { hotel_id: value } });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const MySwal = withReactContent(Swal);
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text,
    });
  };

  const handleCancel = () => {
    setData(rowData);
    setOpenModal(false);
  };
  const saveFunc = (type) => {
    if (!(data.hasOwnProperty("global_hotel_id") && data.global_hotel_id)) {
      sweetAlerts("warning", "Please Select Global Hotel!");
    } else if (
      (data.hasOwnProperty("SH") && data.SH.hotel_id) ||
      (data.hasOwnProperty("DN") && data.DN.hotel_id) ||
      (data.hasOwnProperty("PP") && data.PP.hotel_id) ||
      (data.hasOwnProperty("WB") && data.WB.hotel_id) ||
      (data.hasOwnProperty("LMX") && data.LMX.hotel_id) ||
      (data.hasOwnProperty("FOR") && data.FOR.hotel_id) ||
      (data.hasOwnProperty("WEBBEDS") && data.WEBBEDS.hotel_id) ||
      (data.hasOwnProperty("JUMBO") && data.JUMBO.hotel_id) ||
      (data.hasOwnProperty("LMT") && data.LMT.hotel_id) ||
      (data.hasOwnProperty("SIT") && data.SIT.hotel_id) ||
      (data.hasOwnProperty("XPUR") && data.XPUR.hotel_id) ||
      (data.hasOwnProperty("IVC") && data.IVC.hotel_id) ||
      (data.hasOwnProperty("SDN") && data.SDN.hotel_id) ||
      (data.hasOwnProperty("JU") && data.JU.hotel_id)
    ) {
      if (type == "edit") {
        if (data.hasOwnProperty("DN")) {
          data["DN"] = { hotel_id: data.DN.hotel_id };
        }
        if (data.hasOwnProperty("SH")) {
          data["SH"] = { hotel_id: data.SH.hotel_id };
        }
        if (data.hasOwnProperty("LMX")) {
          data["LMX"] = { hotel_id: data.LMX.hotel_id };
        }
        if (data.hasOwnProperty("FOR")) {
          data["FOR"] = {
            hotel_id: data.FOR.hotel_id,
          };
        }
        if (data.hasOwnProperty("SIT")) {
          data["SIT"] = { hotel_id: data.SIT.hotel_id };
        }
        if (data.hasOwnProperty("PP")) {
          data["PP"] = { hotel_id: data.PP.hotel_id };
        }
        if (data.hasOwnProperty("WB")) {
          data["WB"] = { hotel_id: data.WB.hotel_id };
        }
        if (data.hasOwnProperty("WEBBEDS")) {
          data["WEBBEDS"] = { hotel_id: data.WEBBEDS.hotel_id };
        }
        if (data.hasOwnProperty("JUMBO")) {
          data["JUMBO"] = { hotel_id: data.JUMBO.hotel_id };
        }
        if (data.hasOwnProperty("LMT")) {
          data["LMT"] = { hotel_id: data.LMT.hotel_id };
        }
        if (data.hasOwnProperty("XPUR")) {
          data["XPUR"] = { hotel_id: data.XPUR.hotel_id };
        }
        if (data.hasOwnProperty("IVC")) {
          data["IVC"] = { hotel_id: data.IVC.hotel_id };
        }
        if (data.hasOwnProperty("SDN")) {
          data["SDN"] = { hotel_id: data.SDN.hotel_id };
        }
        if (data.hasOwnProperty("JU")) {
          data["JU"] = { hotel_id: data.JU.hotel_id };
        }
        dispatch(updateMatchHotelDataService(data));
      } else {
        if (setShowFirstRoomMatchPage !== undefined) {
          setShowFirstRoomMatchPage(true);
        }
        dispatch(addMatchHotelDataService(data));
      }
      setOpenModal(false);
    } else {
      sweetAlerts("warning", "Please Select A Hotel From The Operator!");
    }
  };

  return (
    <div>
      <Modal
        className={classes.modal}
        open={openModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Card className={classes.root} variant="outlined">
            <CardContent className={classes.rootcard}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                <b>
                  <IntlMessages id="hotel.map" />
                </b>
              </Typography>
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    variant={window.innerHeight <= 768 ? "h5" : "h6"}
                    component="div"
                  >
                    Global Hotel
                  </Typography>
                  <Autocomplete
                    options={filterMatchedHotels(hotels, matched_hotels)}
                    onChange={(event, value) => {
                      handleChange("global_hotel_id", value?.id);
                    }}
                    value={hotels[data["global_hotel_id"]]}
                    getOptionLabel={(option) => option?.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                  {Object.values(operators).map((opr) => (
                    <>
                      <Typography
                        variant={window.innerHeight <= 768 ? "h5" : "h6"}
                        gutterBottom
                        style={{ zIndex: 1 }}
                      >
                        {opr.name}
                      </Typography>
                      <Autocomplete
                        options={
                          newHotelObj
                            ? newHotelObj[newHotelObjKeys[opr.code]]
                              ? filterMatchedHotels(
                                  newHotelObj[newHotelObjKeys[opr.code]],
                                  matched_hotels,
                                  opr.code
                                )
                              : []
                            : []
                        }
                        disabled={opr.username == null && opr.password == null}
                        onChange={(event, value) => {
                          handleChange(opr.code, value?.hotel_id);
                        }}
                        value={
                          data[opr.code] && data[opr.code].hotel_id
                            ? newHotelObj[newHotelObjKeys[opr.code]][
                                Object.values(
                                  newHotelObj[newHotelObjKeys[opr.code]]
                                ).filter(
                                  (i) => i.hotel_id == data[opr.code].hotel_id
                                )[0].id
                              ]
                            : data[opr.code]?.hotel_id
                        }
                        getOptionLabel={(option) => option?.hotel_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </>
                  ))}
                  <FormControl className={classes.button}>
                    <ThemeProvider theme={theme}>
                      <Button
                        variant="contained"
                        color={
                          rowData.hasOwnProperty("type")
                            ? "secondary"
                            : "primary"
                        }
                        onClick={() =>
                          rowData.hasOwnProperty("type")
                            ? saveFunc("edit")
                            : saveFunc("add")
                        }
                        startIcon={
                          rowData.hasOwnProperty("type") ? (
                            <UpdateIcon />
                          ) : (
                            <SaveIcon />
                          )
                        }
                      >
                        <IntlMessages
                          id={
                            rowData.hasOwnProperty("type") ? "update" : "save"
                          }
                        />
                      </Button>
                    </ThemeProvider>
                  </FormControl>
                  <FormControl className={classes.button}>
                    <ThemeProvider theme={themeCancel}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleCancel()}
                      >
                        <IntlMessages id="cancel" />
                      </Button>
                    </ThemeProvider>
                  </FormControl>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
}
