import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { getHotelService, deleteHotelService } from "services/hotels";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedHotel } from "redux/actions/hotels";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { companyHasOfficeMemo } from "redux/selector/companyHasOfficeSelector";
import { getAdminCompaniesService } from "services/company";
import NewHotel from "./NewHotel";
import _ from "lodash";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";

import { useIntl } from "react-intl";

const MySwal = withReactContent(Swal);

const theme = createTheme({
  palette: {
    backgroundColor: orange[400]
  }
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500]
    }
  }
}))(Button);

export default function HotelTable({ isAuthAdd, isAuthUpdate, isAuthDelete }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.values(hotels).length === 0) {
      dispatch(getAdminCompaniesService());
      dispatch(getHotelService());
    }
  }, [dispatch]);
  const intl = useIntl();

  const stateCompanyHasOffice = useSelector(companyHasOfficeMemo);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { authUser } = useSelector(({ auth }) => auth);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (hotels.length != 0) {
      //Enterprise user ve Company companynin company level userı bütün otelleri görüntüleyebilir
      if (authUser.authority_level == 11 || authUser.authority_level == 20) {
        if (Object.values(stateCompanyHasOffice.companies).length != 0) {
          setFilteredData(Object.values(hotels));
        }
      }
    }
  }, [hotels, stateCompanyHasOffice]);
  const onRowUpdate = rowData => {
    setOpenModal(true);
    dispatch(setSelectedHotel(rowData.id));
  };

  const [openModal, setOpenModal] = useState(false);
  //To activate the add button
  //İs there any destinations for company

  const addButtonActive = () => {
    let destinations = [];
    Object.values(stateCompanyHasOffice["companies"]).map(data => {
      if (data.get_destination == null || data.get_destination == undefined) {
        Swal.fire({
          title: "Error",
          text: intl.formatMessage({
            id: "Please.add.destination.via.admin.menu"
          }),
          icon: "error",
          confirmButtonText: "Ok"
        });
        setOpenModal(false);
      } else {
        setOpenModal(true);
        dispatch(setSelectedHotel([]));
      }
    });
  };

  const tableColumns = [
    {
      title: <IntlMessages id="product.name" />,
      field: "name"
    },
    {
      title: <IntlMessages id="product.type" />,
      field: "product_type.name"
    },
    {
      title: <IntlMessages id="product.code" />,
      field: "hotel_code"
    },
    {
      title: <IntlMessages id="company" />,
      field: "company"
    },
    {
      title: <IntlMessages id="tax.no" />,
      field: "tax_no"
    },
    {
      title: <IntlMessages id="tax.office" />,
      field: "tax_office"
    },
    {
      title: <IntlMessages id="continent" />,
      field: "region.name"
    },
    {
      title: <IntlMessages id="country" />,
      field: "country.name"
    },
    {
      title: <IntlMessages id="city" />,
      field: "city.name"
    },
    {
      title: <IntlMessages id="sub.city" />,
      field: "sub_city.name"
    },
    {
      title: <IntlMessages id="district" />,
      field: "district"
    },
    {
      title: <IntlMessages id="phone" />,
      field: "tel"
    },
    {
      title: <IntlMessages id="fax" />,
      field: "fax"
    },
    {
      title: <IntlMessages id="email" />,
      field: "e_mail"
    },
    {
      title: <IntlMessages id="website" />,
      field: "web_page"
    },
    {
      title: <IntlMessages id="star.rate" />,
      field: "star_rate.value"
    },
    {
      title: <IntlMessages id="giata.code" />,
      field: "giata_codes.giata_id",
      render: rowData => {
        return rowData.giata_codes?.giata_id;
      }
    },
    {
      title: <IntlMessages id="airport.code" />,
      field: "airport_codes.code"
    }
  ];
  return (
    <Grid>
      <NewHotel openModal={openModal} setOpenModal={setOpenModal} />
      <MaterialTable
        columns={tableColumns}
        data={filteredData}
        icons={{
          Check: () => <ModeCheckIcon style={{ color: "green" }} />,
          Clear: () => <ModeCloseIcon style={{ color: "red" }} />
        }}
        title=""
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 30, 40],
          search: false,
          actionsColumnIndex: -1,
          addRowPosition: "first",
          headerStyle: {
            fontSize: "16px",
            fontWeight: "bold"
          }
        }}
        actions={[
          {
            icon: "edit",
            tooltip: isAuthUpdate ? "Edit Row" : "You are not authorized",
            disabled: isAuthUpdate ? false : true,
            onClick: (event, rowData) => {
              onRowUpdate(rowData);
            }
          },

          {
            icon: () => (
              <ThemeProvider theme={theme}>
                <ColorButton
                  variant="contained"
                  color="backgroundColor"
                  startIcon={<AddBoxIcon />}
                >
                  <IntlMessages id="add" />
                </ColorButton>
              </ThemeProvider>
            ),
            tooltip: isAuthAdd ? "Add Row" : "You are not authorized",
            disabled: isAuthAdd ? false : true,
            position: "toolbar",
            onClick: () => {
              addButtonActive();
            }
          }
        ]}
        editable={{
          deleteTooltip: row =>
            isAuthDelete ? "Delete" : "You are not authorized",
          isDeletable: row => (isAuthDelete ? true : false),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                  dispatch(deleteHotelService(oldData.id));
               
                resolve();
              }, 1000);
            })
        }}
      />
    </Grid>
  );
}
