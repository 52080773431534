import {
  GET_MASTER_USERS,
  SET_CURRENT_MASTER_USER,
  ADD_MASTER_USER,
  EDIT_MASTER_USER,
  DELETE_MASTER_USER
} from "@jumbo/constants/ActionTypes";
import { GET_ALL_USERS } from "./types";

export const getMasterUsers = users => dispatch => {
  dispatch({ type: GET_MASTER_USERS, payload: users });
};

export const setCurrentMasterUser = user => dispatch => {
  dispatch({ type: SET_CURRENT_MASTER_USER, payload: user });
};

export const addMasterUser = user => dispatch => {
  dispatch({ type: ADD_MASTER_USER, payload: user });
};

export const editMasterUser = user => dispatch => {
  dispatch({ type: EDIT_MASTER_USER, payload: user });
};

export const deleteMasterUser = userId => dispatch => {
  dispatch({ type: DELETE_MASTER_USER, payload: userId });
};

export const getAllUsers = res => dispatch => {
  dispatch({
    type: GET_ALL_USERS,
    payload: res.data.data
  });
};
