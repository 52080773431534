import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  Block,
  CheckCircleOutline,
  Delete,
  Edit,
  Mail,
  MoreHoriz,
  Visibility
} from "@material-ui/icons";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CmtDropdownMenu from "../../../../../@coremat/CmtDropdownMenu";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const actions = [
  { action: "edit", label: "Edit", icon: <Edit /> },
  { action: "delete", label: "Delete", icon: <Delete /> }
];

const UserListRow = props => {
  const { row, onUserEdit, onUserDelete, onUserView } = props;

  const classes = useStyles();

  const onUserMenuClick = menu =>
    menu.action === "edit" ? onUserEdit(row) : onUserDelete(row);

  const labelId = `enhanced-table-checkbox-${row.id}`;
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
      <TableCell padding="checkbox" />

      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Box display="flex" alignItems="center">
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.fullname || ""}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.owned_company.name ?? ""}</TableCell>
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          items={actions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
