import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import BigCalendar from "react-big-calendar-like-google";
import { Container, Row, Col } from "react-grid-system";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "react-big-calendar-like-google/lib/css/react-big-calendar.css";
import { Autocomplete } from "@material-ui/lab";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const useStyles = makeStyles(theme => ({
  selectBoxRoot: {
    marginBottom: 20,
    "& .MuiOutlinedInput-input": {
      backgroundColor: theme.palette.background.paper
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400]
    }
  },
  button: {
    height: "40px !important"
  }
}));
function CulturesCalendar({ filterState, setFilterState, isFinished }) {
  const classes = useStyles();
  const intl = useIntl();

  const [sales, setSales] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);

  const { hotels } = useSelector(({ hotels }) => hotels);
  const { matched_hotels } = useSelector(({ hotelMatch }) => hotelMatch);
  const { matched_rooms } = useSelector(({ operatorRooms }) => operatorRooms);
  const { stopSale } = useSelector(({ stopSale }) => stopSale);
  const { openSale } = useSelector(({ openSale }) => openSale);
  const {
    rooms: { roomType }
  } = useSelector(({ rooms }) => rooms);

  const newStopSales = () => {
    let newStopSales = [];
    stopSale.map(sSale => {
      let roomString = "";
      sSale.data.rooms.map(item => {
        roomString = roomString + roomType[item.id]?.code + " ";
        sSale.roomString = roomString;
      });
      sSale.data.dates.map(sDate => {
        let newStopSale = {};
        let start_date = sDate.startDate;
        let end_date = sDate.endDate;
        newStopSale = {
          ...sSale,
          start_date: start_date,
          end_date: end_date
        };
        delete newStopSale.dates;
        newStopSales.push(newStopSale);
      });
    });
    return newStopSales;
  };
  const newOpenSales = () => {
    let newOpenSales = [];
    openSale.map(oSale => {
      let roomString = "";
      oSale.data.rooms.map(item => {
        roomString = roomString + roomType[item.id]?.code + " ";
        oSale.roomString = roomString;
      });

      oSale.data.dates.map(sDate => {
        let newOpenSale = {};
        let start_date = sDate.startDate;
        let end_date = sDate.endDate;
        newOpenSale = {
          ...oSale,
          start_date: start_date,
          end_date: end_date
        };
        delete newOpenSale.dates;
        newOpenSales.push(newOpenSale);
      });
    });
    return newOpenSales;
  };
  const stopS = newStopSales();
  const openS = newOpenSales();
  let newHotel = [];
  let newRoom = [];

  if (Object.values(matched_hotels)) {
    Object.values(matched_hotels).map(item => {
      newHotel.push({
        id: hotels[item.global_hotel_id]?.id,
        name: hotels[item.global_hotel_id]?.name
      });
    });
  }
  if (matched_rooms) {
    if (Object.values(matched_rooms)) {
      Object.values(matched_rooms).map(item => {
        newRoom.push({
          id: roomType[item.global_room_id]?.id,
          name: roomType[item.global_room_id]?.room_name
        });
      });
      newRoom = newRoom.filter(
        (v, i, a) => a.findIndex(t => t.id == v.id) == i
      );
    }
  }

  const roomTypeControl = sale => {
    let sale_room_codes = sale.data.rooms;
    if (sale_room_codes.length > 0) {
      return sale_room_codes.filter(
        room => Number(room.id) == Number(filterState.room_id)
      ).length > 0
        ? true
        : false;
    } else {
      return sale_room_codes[0] == filterState.room_id ? true : false;
    }
  };
  const filterHandleChange = e => {
    const { name, value } = e.target;
    if (value !== "Invalid date") {
      setFilterState(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };
  const MySwal = withReactContent(Swal);
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text
    });
  };
  const filterSelected = () => {
    if (!(filterState.hotel_id && filterState.room_id)) {
      sweetAlerts(
        "warning",
        intl.formatMessage({ id: "select.hotel.and.room" })
      );
    } else {
      let newSales = [];
      let filteredStops = stopS.filter(
        stop =>
          filterState.startDate <= stop.start_date &&
          filterState.endDate >= stop.end_date &&
          Number(filterState.hotel_id) == stop.data.hotelId &&
          roomTypeControl(stop)
      );
      let filteredOpens = openS.filter(
        open =>
          filterState.startDate <= open.start_date &&
          filterState.endDate >= open.end_date &&
          Number(filterState.hotel_id) == open.data.hotelId &&
          roomTypeControl(open)
      );
      filteredOpens.map(open => {
        let openTitle =
          "Room Codes: " +
          open.roomString +
          " Hotel Name: " +
          hotels[filterState.hotel_id].name;
        newSales.push({
          title: openTitle,
          bgColor: "rgb(82 177 85)",
          start: open.start_date,
          end: open.end_date,
          allDay: true,
          type: "Open Sale"
        });
      });
      filteredStops.map(stop => {
        let stopTitle =
          "Room Codes: " +
          stop.roomString +
          " Hotel Name: " +
          hotels[filterState.hotel_id].name;
        newSales.push({
          title: stopTitle,
          bgColor: "rgb(181 54 54)",
          start: stop.start_date,
          end: stop.end_date,
          allDay: true,
          type: "Stop Sale"
        });
      });
      setSales(newSales);
      setSelectedActions({
        filteredStops: filteredStops,
        filteredOpens: filteredOpens
      });
    }
  };
  return (
    <PageContainer>
      <Container>
        <div className={classes.selectBoxRoot}>
          <Row xs="fixed">
            <Col item sm={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  name="startDate"
                  disablePast
                  autoOk="true"
                  label={intl.formatMessage({ id: "start.date" })}
                  value={
                    filterState.startDate == ""
                      ? moment(new Date()).format("YYYY-MM-DD")
                      : moment(filterState.startDate).format("YYYY-MM-DD")
                  }
                  onChange={date => {
                    let e = {
                      target: {
                        name: "startDate",
                        value: moment(date).format("YYYY-MM-DD")
                      }
                    };
                    filterHandleChange(e);
                  }}
                  format="dd/MM/yyyy"
                  animateYearScrolling
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </MuiPickersUtilsProvider>
            </Col>
            <Col item sm={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  name="endDate"
                  disablePast
                  format="dd/MM/yyyy"
                  autoOk="true"
                  label={intl.formatMessage({ id: "end.date" })}
                  minDate={moment(filterState.startDate).format("YYYY-MM-DD")}
                  value={
                    filterState.endDate == ""
                      ? moment(new Date()).format("YYYY-MM-DD")
                      : moment(filterState.endDate).format("YYYY-MM-DD")
                  }
                  onChange={date => {
                    let e = {
                      target: {
                        name: "endDate",
                        value: moment(date).format("YYYY-MM-DD")
                      }
                    };
                    filterHandleChange(e);
                  }}
                  animateYearScrolling
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </MuiPickersUtilsProvider>
            </Col>
            <Col item sm={3}>
              <Autocomplete
                options={newHotel}
                onChange={(e, value) => {
                  let event = {
                    target: {
                      name: "hotel_id",
                      value: value != null ? value.id : ""
                    }
                  };
                  filterHandleChange(event);
                }}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    error={filterState.hotel_id !== "" ? false : true}
                    placeholder={intl.formatMessage({ id: "select.product" })}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Col>
            <Col item sm={3}>
              <Autocomplete
                options={newRoom}
                onChange={(e, value) => {
                  let event = {
                    target: {
                      name: "room_id",
                      value: value != null ? value.id : ""
                    }
                  };
                  filterHandleChange(event);
                }}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    error={filterState.room_id !== "" ? false : true}
                    placeholder={intl.formatMessage({ id: "select.room" })}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Col>
            <Col item sm={2}>
              <Button
                variant="outlined"
                style={{ backgroundColor: "gray", color: "white" }}
                className={classes.button}
                onClick={() => filterSelected()}
              >
                {intl.formatMessage({ id: "filter" })}
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
      <div>
        <BigCalendar
          events={sales}
          defaultView="month"
          date={
            filterState.startDate ? new Date(filterState.startDate) : new Date()
          }
          onNavigate={date => {
            setFilterState(prev => {
              return { ...prev, startDate: date, endDate: date };
            });
          }}
        />
      </div>
    </PageContainer>
  );
}

export default React.memo(CulturesCalendar);
