import React, { useEffect, lazy, Suspense, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import OpenStopTabPanel from "./OpenStopTabPanel";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { getHotelService } from "services/hotels";
import { useDispatch } from "react-redux";
import { getRoomService } from "services/rooms";
import { getOperatorService } from "services/operator";
import { getMatchHotelDataService } from "services/hotelmatch";
import { getOperatorHotelsService } from "services/operatorhotels";
import Loader from "../Contract/Loader";

const StopSale = lazy(() => import("./StopSale"));
const OpenSale = lazy(() => import("./OpenSale"));

export default function OpenStopSale() {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  useEffect(async () => {
    await dispatch(getHotelService());
    await dispatch(getRoomService());
    await dispatch(getOperatorService());
    // await dispatch(getStopSaleService());
  }, [dispatch]);

  const [operatorIds, setOperatorIds] = useState([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12
  ]);
  useEffect(() => {
    dispatch(getMatchHotelDataService());
    dispatch(getOperatorHotelsService(operatorIds));
  }, [operatorIds]);
  return (
    <Paper>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab label={<IntlMessages id="stop.sale" />} />
        <Tab label={<IntlMessages id="open.sale" />} />
      </Tabs>
      <OpenStopTabPanel value={value} index={0}>
        <Suspense fallback={<Loader />}>
          <StopSale />
        </Suspense>
      </OpenStopTabPanel>
      <OpenStopTabPanel value={value} index={1}>
        <Suspense fallback={<Loader />}>
          <OpenSale />
        </Suspense>
      </OpenStopTabPanel>
    </Paper>
  );
}
