import {
  ADD_DASHBOARD_HAS_LOADED,
  ADD_DEFINITIONS_HAS_LOADED,
  ADD_ADD_RESERVATIONS_HAS_LOADED,
  ADD_SHOW_RESERVATIONS_HAS_LOADED,
  ADD_NEW_CONTRACT_HAS_LOADED
} from "../actions/types";

export const addDashboardLoaded = (res) => (dispatch) => {
  dispatch({
    type: ADD_DASHBOARD_HAS_LOADED,
    payload: true
  });
};
export const addDefinitionsLoaded = (res) => (dispatch) => {
  dispatch({
    type: ADD_DEFINITIONS_HAS_LOADED,
    payload: true
  });
};
export const addAddReservationsLoaded = (res) => (dispatch) => {
  dispatch({
    type: ADD_ADD_RESERVATIONS_HAS_LOADED,
    payload: true
  });
};
export const addShowReservationsLoaded = (res) => (dispatch) => {
  dispatch({
    type: ADD_SHOW_RESERVATIONS_HAS_LOADED,
    payload: true
  });
};
export const addNewContractLoaded = (res) => (dispatch) => {
  dispatch({
    type: ADD_NEW_CONTRACT_HAS_LOADED,
    payload: true
  });
};

export const getHasLoaded = (state) => state.hasLoaded;

export const getDashboardHasLoaded = (state) => state.hasLoaded.dashboard;
export const getDefinitionsHasLoaded = (state) => state.hasLoaded.definitions;
export const getAddReservationsHasLoaded = (state) =>
  state.hasLoaded.addReservations;
export const getShowReservationsHasLoaded = (state) =>
  state.hasLoaded.showReservations;
export const getNewContractHasLoaded = (state) => state.hasLoaded.newContract;
