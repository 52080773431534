import { fetchError, fetchSuccess } from "redux/actions";
import {
  getBotOffer,
} from "redux/actions/offers";
import axios from "./axios";
import axiosBot from "./axiosBot";
import axiosLogger from "./axiosLogger";
import { apihost } from "./axiosBot";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

/**PROXOLAB LOGGER **/
import { GET_BOT_OFFER } from "redux/actions/types";
/**PROXOLAB LOGGER **/

const MySwal = withReactContent(Swal);
const sweetAlerts = (variant, text) => {
  MySwal.fire({
    icon: variant,
    title: "",
    text: text,
  });
};

export const sendOfferService = (data)=> dispatch=>{
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_BOT_OFFER,
    payload: logDataForState(data),
  });
  data["endpoint"] = apihost;
  data["botEndpoint"]=axiosBot.defaults.baseURL
  return axios.post("offer",data).then(function(res) {
    dispatch(
      getOfferMongoDBService({
        userEmail: data.user.email,
          hotelId: data.hotelId,
          message: res.data.msg,
      })
    );
    if (res.status === 200) {
      dispatch(fetchSuccess());
    }
  })
  .catch(function(error) {
    Swal.fire({
      toast: "true",
      position: "top-end",
      icon: "error",
      title:
        "Operation could not be performed. Please try again!",
      showConfirmButton: false,
      timer: 1500
    });
    dispatch(fetchError());
  });
}

export const getOfferMongoDBService = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  axiosLogger.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axiosLogger({
    method: "post",
    url: `proxolab/api/v1/botclient/allOffer/`,
    data: data,
  })
    .then((res) => {
      dispatch(getBotOffer(res));
      dispatch(fetchSuccess());
    })
    .catch((e) => {
      dispatch(fetchError(e.message));
    });
};

const logDataForState = (data) => {
  data.allOperators.forEach((operator) => {
    return (operator["status"] = 300);
  });

  const sellDates = {
    sellStartDate: data["sellStartDate"],
    sellEndDate: data["sellEndDate"],
  };
  const temp = {
    ...temp,
    sellDates: sellDates,
    hotelId: data["hotelId"],
    offers: data["offers"],
    operators: data.allOperators,
  };
  const newData = {
    ...newData,
    data: temp,
    user: data["user"],
    pId: data["pId"],
    process: data,
    createdAt: new Date(),
  };
  return newData;
};
