import {
    GET_BOT_OFFER
  } from "../actions/types";
  
  const initialState = {
    botOffers: []
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_BOT_OFFER: {
        const isSameLog = state.botOffers.filter(
          log => log.pId == action.payload.pId
        );
        let offerLogs = state.botOffers;
        //eğer hiç log kaydı yoksa ve yenisi eklenmiyorsa
        if (offerLogs.length == 0 && !action.payload.hasOwnProperty("pId")) {
          offerLogs = action.payload;
        }
        //eğer hiç log kaydı yoksa ve ilk kayıt ekleniyorsa
        else if (offerLogs.length == 0 && action.payload.hasOwnProperty("pId")) {
          offerLogs[0] = action.payload;
        } else {
          //eğer aynı pId'ye sahip log kaydı yoksa ve yenisi ekleniyorsa
          if (isSameLog.length == 0 && action.payload.hasOwnProperty("pId")) {
            offerLogs.unshift(action.payload);
            if (state.botOffers.length > 5) {
              offerLogs.splice(-1, 1); //pop()???
            }
          }
          //eğer aynı pId'ye sahip log kaydı yoksa ve yenisi eklenmiyorsa
          else if (
            isSameLog.length == 0 &&
            !action.payload.hasOwnProperty("pId")
          ) {
            offerLogs = action.payload;
          }
        }
        return { ...state, botOffers: offerLogs };
      }
      default:
        return state;
    }
  };
  