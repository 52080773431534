import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import CmtSearch from "../../../../../@coremat/CmtSearch";
import useStyles from "./index.style";

const UserTableToolbar = props => {
  const { onUserAdd, searchTerm, setSearchTerm } = props;
  const classes = useStyles();
  return (
    <div>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          Users
          <Button
            style={{ marginLeft: 10 }}
            variant="contained"
            color="primary"
            onClick={() => onUserAdd(true)}
          >
            Add New User
          </Button>
        </Typography>
        <div>
          <CmtSearch
            onChange={e => setSearchTerm(e.target.value)}
            value={searchTerm}
            border={false}
            onlyIcon
          />
        </div>
      </Toolbar>
    </div>
  );
};

export default React.memo(UserTableToolbar);
