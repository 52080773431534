import { Box, Grid } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";

export default function Captcha({ login, captchaFlag, setCaptchaFlag }) {
  /************** CAPTCHA OPERATIONS*************/
  const [showCaptcha, setShowCaptcha] = useState("flex");
  const [refresh, setRefresh] = useState();
  const [newCaptcha, setNewCaptcha] = useState();
  const cnv = useRef(null);
  const conf = useRef(null);
  const chars = "123456789abcdefghjkmnpqrstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ";
  const charsArray = chars.split("");
  const captcha = [];
  const [captchaState, setCaptchaState] = useState([]);
  const [captchaMatch, setCapthcaMatch] = useState(false);
  const newRefresh = () => {
    setRefresh(Math.random());
  };

  useEffect(() => {
    const canvas = cnv.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    for (let i = 0; i < 6; i++) {
      const numb = Math.floor(Math.random() * charsArray.length);
      captcha.push(charsArray[numb]);
    }
    ctx.font = "70px Georgia";
    ctx.fillText(captcha.join(""), 15, 120);
    setCaptchaState(captcha);
  }, [refresh, newCaptcha]);

  useEffect(() => {
    if (captchaFlag == true) {
      newRefresh();
      setCaptchaFlag(false);
    }
  }, [captchaFlag]);

  const confirm = () => {
    const c = conf.current;
    if (c.value == captchaState.join("")) {
      setCapthcaMatch(false);
      login();
    } else {
      setCapthcaMatch(true);
      setTimeout(() => {
        conf.current.value = "";
        setNewCaptcha(Math.random());
      }, 500);
    }
  };

  /**********************************************/
  const handleKey = e => {
    if (e.key === "Enter") {
      if (
        window.location.pathname == "/login" ||
        window.location.pathname == "/master/login"
      ) {
        confirm();
      }
    }
  };
  /******************CAPTCHA HOVER EFFETC**************** */
  const hvr = e => {
    e.target.style.backgroundColor = "rgb(46, 162, 152, .4)";
    e.target.style.boxShadow = "3px 2px 6px rgba(0,0,0,.4)";
    e.target.style.color = "white";
    e.target.style.WebkitTextStrokeWidth = "0";
    e.target.style.WebkitTextStrokeColor = "white";
  };
  const out = e => {
    e.target.style.backgroundColor = "rgb(46, 162, 152, .2)";
    e.target.style.boxShadow = "3px 2px 6px rgba(0,0,0,.2)";
    e.target.style.color = "grey";
    e.target.style.WebkitTextStrokeWidth = ".3px";
    e.target.style.WebkitTextStrokeColor = "grey";
  };
  /******************************************************* */
  console.log(captchaMatch);
  return (
    <Grid
      style={{ display: showCaptcha }}
      container
      display="flex"
      direction="row"
      marginbottom="30px"
      spacing={3}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        {captchaMatch == true ? (
          <Grid
            style={{
              backgroundColor: "red",
              color: "white",
              letterSpacing: "3px",
              marginBottom: ".3rem",
              padding: "3px",
              borderRadius: "3px"
            }}
            item
            xs={12}
          >
            Captcha Did Not Match!
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              marginBottom: "1rem"
            }}
          >
            <canvas
              id="cnv"
              style={{ width: "10rem", height: "2.5rem" }}
              ref={cnv}
            ></canvas>
            <button
              onMouseOver={hvr}
              onMouseOut={out}
              onClick={() => newRefresh()}
              style={{
                backgroundColor: "rgb(46, 162, 152, .2)",
                fontSize: "13px",
                letterSpacing: "4px",
                color: "grey",
                WebkitTextStrokeWidth: ".3px",
                WebkitTextStrokeColor: "grey",
                textTransform: "uppercase",
                padding: "5px",
                marginLeft: "1rem",
                width: "50px",
                cursor: "pointer",
                boxShadow: "3px 2px 6px rgba(0,0,0,.2)",
                border: "none",
                borderRadius: "5px",
                transition: ".2s"
              }}
            >
              &#8634;
            </button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <input
              style={{
                marginBottom: "5px",
                width: "10rem",
                height: "2rem",
                outline: "none",
                border: "none",
                boxShadow: "0 0 1px black",
                borderRadius: "2px",
                color: "black",
                textAlign: "center",
                letterSpacing: "5px",
                fontSize: "18px"
              }}
              onKeyDown={handleKey}
              ref={conf}
              type="text"
            />
            <button
              onMouseOver={hvr}
              onMouseOut={out}
              onClick={confirm}
              style={{
                backgroundColor: "rgb(46, 162, 152, .2)",
                fontSize: "13px",
                letterSpacing: "4px",
                color: "grey",
                WebkitTextStrokeWidth: ".3px",
                WebkitTextStrokeColor: "grey",
                textTransform: "uppercase",
                padding: "5px",
                marginLeft: "1rem",
                width: "50px",
                cursor: "pointer",
                boxShadow: "3px 2px 6px rgba(0,0,0,.2)",
                border: "none",
                borderRadius: "5px",
                transition: ".2s"
              }}
            >
              &#x2713;
            </button>
          </div>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <img width="100" height="100" src="/images/captcha.png" />@ 2022
          Captcha
        </Box>
      </Grid>
    </Grid>
  );
}

export const MemoizedCaptcha = React.memo(Captcha);
