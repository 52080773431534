import React, { useEffect, useState } from "react";
import { getOperatorService } from "services/operator";
import { getHotelService } from "services/hotels";
import { useDispatch, useSelector } from "react-redux";
import Definitons from "./Definitions";
import { getRoomService } from "services/rooms";
import {
  getAllCountriesService,
  getCountriesService
} from "services/destinations";
import { getAdminCompaniesService } from "services/company";
import { getStarRatesService } from "services/starRate";
import { getProductTypesService } from "services/productTypes";
import { getRegionsService } from "services/destinations";
import { addDefinitionsLoaded } from "redux/actions/hasLoaded";
const DefinitionsLoading = () => {
  const dispatch = useDispatch();
  const [isFinished, setIsFinished] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);

  const hasLoaded = useSelector(state => state?.hasLoaded.hasLoaded); //prettier-ignore

  useEffect(() => {
    const firstLoader = async () => {
      await Promise.all([
        dispatch(getAdminCompaniesService()),
        dispatch(getHotelService()),
        dispatch(getProductTypesService()),
        dispatch(getRoomService()),
        dispatch(getOperatorService()),
        dispatch(getCountriesService(["", 0])),
        dispatch(getStarRatesService()),
        dispatch(getRegionsService()),
        dispatch(getAllCountriesService())
      ]);
    };
    if (hasLoaded.definitions === false && hasLoaded.dashboard === false) {
      firstLoader()
        .then(() => {
          dispatch(addDefinitionsLoaded(true));
          setIsFinished(true);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setIsFinished(true);
    }
  }, [dispatch, authUser]);
  return <React.Fragment>{isFinished && <Definitons />}</React.Fragment>;
};

export default DefinitionsLoading;
