import React, { useState, useEffect } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { useDispatch, useSelector } from "react-redux";
import {
  getRoomService,
  addRoomService,
  updateRoomService,
  deleteRoomService
} from "services/rooms";

import { getMatchHotelDataService } from "services/hotelmatch";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "./Loader";
import axios from "services/axios";

const theme = createTheme({
  palette: {
    backgroundColor: orange[400]
  }
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500]
    }
  }
}))(Button);

const MySwal = withReactContent(Swal);
const keysForRoomState = [
  "type_id",
  "grade_id",
  "base_id",
  "view_id",
  "sub_type_id",
  "promo_id"
];
const keysForRoomCode = [
  "types",
  "grades",
  "bases",
  "views",
  "subTypes",
  "promos"
];
export default function AddRoomType({ isAuthAdd, isAuthUpdate, isAuthDelete }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.values(rooms).length === 0) {
      dispatch(getRoomService());
    }
    dispatch(getMatchHotelDataService());
  }, [dispatch]);

  const intl = useIntl();

  const { rooms } = useSelector(({ rooms }) => rooms);
  const {
    rooms: { roomType }
  } = useSelector(({ rooms }) => rooms);
  const [all, setAll] = useState({});
  const [room, setRoom] = useState({});
  const [matchedHotelRooms, setMatchedHotelRooms] = useState([]);

  useEffect(() => {
    if (room.length > 0) {
      axios
        .post(
          "allHotelMatchedRooms",
          room.map(r => r.id)
        )
        .then(res => setMatchedHotelRooms(res.data.matchedRoomIds));
    }
  }, [room]);

  // Backendden gelirken bir array içinde birden fazla obje geliyodu ikisini de
  // obje yapıp lookup'a koymak için useEffect yapıldı
  useEffect(() => {
    if (rooms.roomType !== undefined) {
      Object.keys(rooms).forEach(key => {
        Object.values(rooms[key]).forEach(type => {
          setAll(prev => ({
            ...prev,
            [key]: { ...prev[key], [type.id]: type.name }
          }));
        });
      });
    }
  }, [rooms]);

  const hasRoomtype = room_type_id => {
    // DESC - Check if room is used in contract or matched

    const isMatchedRoom = matchedHotelRooms.includes(room_type_id);

    if (isMatchedRoom) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (roomType) {
      setRoom(Object.values(roomType));
    }
  }, [roomType]);

  // This array includes room names thats are created in the past to control new room
  let checkRoomIsCreatedAlready = [];
  for (let i in room) {
    checkRoomIsCreatedAlready.push(room[i].room_name);
  }

  const tableColumns = [
    {
      title: (
        <b>
          <IntlMessages id="code" />
        </b>
      ),
      field: "code",
      width: "20%",
      editComponent: props => (
        <TextField
          id="code"
          name="code"
          inputProps={{ style: { textTransform: "uppercase" }, readOnly: true }}
          defaultValue={props.rowData.code}
        />
      )
    },
    {
      title: (
        <b>
          <IntlMessages id="type" />
        </b>
      ),
      field: "type_id",
      id: "type_id",
      width: "20%",
      lookup: all.types,
      validate: rowData => (rowData.type_id ? true : false),
      editComponent: props => {
        return (
          <Autocomplete
            options={Object.values(rooms.types)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.types[props.rowData["type_id"]]}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        );
      }
    },
    {
      title: (
        <b>
          <IntlMessages id="grade" />
        </b>
      ),
      field: "grade_id",
      width: "20%",
      lookup: all.grades,
      validate: rowData => (rowData.grade_id ? true : false),
      editComponent: props => {
        return (
          <Autocomplete
            options={Object.values(rooms.grades)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.grades[props.rowData["grade_id"]]}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        );
      }
    },
    {
      title: (
        <b>
          <IntlMessages id="base" />
        </b>
      ),
      field: "base_id",
      width: "20%",
      lookup: all.bases,
      initialEditValue: 1,
      validate: rowData => (rowData.base_id ? true : false),
      editComponent: props => {
        return (
          <Autocomplete
            options={Object.values(rooms.bases)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.bases[props.rowData["base_id"]]}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                defaultValue="NotDefined"
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        );
      }
    },
    {
      title: (
        <b>
          <IntlMessages id="view" />
        </b>
      ),
      field: "view_id",
      width: "20%",
      lookup: all.views,
      initialEditValue: 1,
      validate: rowData => (rowData.view_id ? true : false),
      editComponent: props => {
        return (
          <Autocomplete
            options={Object.values(rooms.views)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.views[props.rowData["view_id"]]}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        );
      }
    },
    {
      title: (
        <b>
          <IntlMessages id="sub.type" />
        </b>
      ),
      field: "sub_type_id",
      width: "20%",
      lookup: all.subTypes,
      initialEditValue: 1,
      validate: rowData => (rowData.sub_type_id ? true : false),
      editComponent: props => {
        return (
          <Autocomplete
            options={Object.values(rooms.subTypes)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={rooms.subTypes[props.rowData["sub_type_id"]]}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        );
      }
    },
    {
      title: (
        <b>
          <IntlMessages id="promo" />
        </b>
      ),
      field: "promo_id",
      width: "20%",
      lookup: all.promos,
      initialEditValue: 1,
      validate: rowData => (rowData.promo_id ? true : false),
      editComponent: props => {
        return (
          <Autocomplete
            options={Object.values(rooms.promos)}
            onChange={(event, value) => {
              if (value !== null) {
                props.onChange(value.id);
              }
            }}
            value={
              props.rowData["promo_id"] !== ""
                ? rooms.promos[props.rowData["promo_id"]]
                : ""
            }
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        );
      }
    }
  ];
  return (
    <MaterialTable
      columns={tableColumns}
      data={room ? room : {}}
      title=""
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        tableLayout: "fixed",
        actionsColumnIndex: -1,
        search: false,
        filtering: true,
        addRowPosition: "first"
      }}
      actions={[
        isAuthAdd
          ? undefined
          : {
              icon: "add",
              disabled: true,
              position: "toolbar",
              tooltip: intl.formatMessage({ id: "you.are.not.authorized" })
            }
      ]}
      components={{
        OverlayLoading: () => <Loader />,
        EditRow: props => {
          return (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
              onEditingCanceled={(mode, rowData) => {
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (mode == "update") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "add") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode, rowData);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "delete") {
                      props.onEditingCanceled(mode, rowData);
                    }
                  });
                });
              }}
            />
          );
        },
        onRowAdd: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
          />
        )
      }}
      icons={{
        Add: props => (
          <ThemeProvider theme={theme}>
            <ColorButton
              variant="contained"
              color="backgroundColor"
              startIcon={<AddBoxIcon />}
              onClick={e => {}}
            >
              <IntlMessages id="add" />
            </ColorButton>
          </ThemeProvider>
        ),
        Check: () => <ModeCheckIcon style={{ color: "green" }} />,
        Clear: () => <ModeCloseIcon sx={{ color: "red" }} />
      }}
      editable={{
        deleteTooltip: row =>
          isAuthDelete
            ? intl.formatMessage({ id: "delete" })
            : "You are not authorized",
        editTooltip: row =>
          isAuthUpdate
            ? intl.formatMessage({ id: "update" })
            : "You are not authorized",
        isDeletable: row => (isAuthDelete ? true : false),
        isEditable: row => (isAuthUpdate && hasRoomtype(row.id) ? true : false),

        onRowAdd: isAuthAdd
          ? newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  var code = "";
                  var nameString = "";
                  keysForRoomState.forEach((element, key) => {
                    if (newData[element] !== 0 && newData[element] !== "code") {
                      if (
                        rooms[keysForRoomCode[key]][newData[element]].name ===
                        "NotDefined"
                      ) {
                      } else {
                        nameString +=
                          rooms[keysForRoomCode[key]][newData[element]].name +
                          " ";
                      }
                      code = code.concat(
                        rooms[keysForRoomCode[key]][newData[element]].code
                      );
                    }
                  });
                  newData["code"] = code;
                  newData["room_name"] = nameString.trimEnd(); // trimEnd => isimi oluştururken sondaki eklenen boşluğu JS fonksiyonu ile siliyorum.
                  if (
                    !newData.type_id ||
                    !newData.grade_id ||
                    !newData.base_id ||
                    !newData.view_id ||
                    !newData.sub_type_id ||
                    !newData.promo_id ||
                    !newData.room_name
                  ) {
                    MySwal.fire(
                      "Oops...",
                      intl.formatMessage({
                        id: "please.fill.all.the.fields"
                      }) /*  <IntlMessages id="please.fill.in.all.fields" /> */,
                      "error"
                    );
                    reject();
                  } else if (
                    checkRoomIsCreatedAlready.some(room => {
                      return room == newData.room_name;
                    })
                  ) {
                    MySwal.fire(
                      "Oops...",
                      intl.formatMessage({
                        id: "room.already.exists"
                      }),
                      /*  <>
                        <IntlMessages id="room" /> <IntlMessages id="already.exists" />
                      </> */ "error"
                    );
                    reject();
                  } else {
                    dispatch(addRoomService(newData));
                    Swal.fire({
                      text: intl.formatMessage({
                        id: "new.room.added.successfully"
                      }),
                      icon: "success",
                      confirmButtonColor: "#3085d6"
                    });
                    resolve();
                  }
                }, 1000);
              })
          : undefined,
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                var code = "";
                var nameString = "";
                keysForRoomState.forEach((element, key) => {
                  if (newData[element] !== 0 && newData[element] !== "code") {
                    if (
                      rooms[keysForRoomCode[key]][newData[element]].name ===
                      "NotDefined"
                    ) {
                    } else {
                      nameString +=
                        rooms[keysForRoomCode[key]][newData[element]].name +
                        " ";
                    }
                    code = code.concat(
                      rooms[keysForRoomCode[key]][newData[element]].code
                    );
                  }
                });
              }
              newData["code"] = code;
              newData["room_name"] = nameString.trimEnd(); // trimEnd => isimi oluştururken sondaki eklenen boşluğu JS fonksiyonu ile siliyorum.

              if (
                checkRoomIsCreatedAlready.some(room => {
                  return room == newData.room_name;
                }) &&
                newData.room_name !== oldData.room_name
              ) {
                MySwal.fire(
                  "Oops...",
                  intl.formatMessage({
                    id: "room.already.exists"
                  }),
                  "error"
                );
                reject();
              } else {
                dispatch(updateRoomService(newData, oldData));
                resolve();
              }
            }, 1000);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              //delete patlıyor olabilir siliyor ama stateten silemiyor sonra ayarlanacak.
              if (hasRoomtype(oldData.id)) {
                //roomtype var mı diye kontrol ediyoruz
                dispatch(deleteRoomService(oldData.id)).then(status => {
                  if (status === 400) {
                    MySwal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: intl.formatMessage({
                        id: "this.room.type.is.matched"
                      })
                    });
                  }
                });
              } else {
                MySwal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: intl.formatMessage({
                    id: "room.type.in.use"
                  })
                });
              }
              resolve();
            }, 1000);
          })
      }}
    />
  );
}
