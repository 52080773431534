import {
    GET_BOT_OFFER,
  } from "./types";
  
  export const getBotOffer = res => dispatch => {
    dispatch({
      type: GET_BOT_OFFER,
      payload: res.data.data
    });
  };
  