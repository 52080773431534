import { random } from "lodash";
import axios from "../../services/axios";
import axiosBot from "../../services/axiosBot";
import { apihost } from "../../services/axiosBot";


export const saveOperatorCredentials = (
  operatorCredentials,
  setOpen,
  setResponseStatus,
  setLoadingSave,
  setBookingListDisable
) => {

  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  if (operatorCredentials.operator_id == 1) {
    let botPayload = {
      user_name: operatorCredentials.user_name,
      password: operatorCredentials.password,
      pxoUser: operatorCredentials.pxoUser,
      operator_id: "1",
      operator_name: operatorCredentials.operator_name
    };
    axios
      .post("operator/credentials/1", botPayload)
      .catch(err => {
        err.response.data.message === "Cookie is invalid" &&
          window.open(
            "https://www.peakpoint.travel/Extranet/alojamiento/",
            "_blank"
          );
        operatorCredentials["isCredentialCorrect"] = false;
        function sleep(milliseconds) {
          const date = Date.now();
          let currentDate = null;
          do {
            currentDate = Date.now();
          } while (currentDate - date < milliseconds);
        }
        sleep(10000);
        runBot(operatorCredentials);

        
      })
      .then(res => {
        operatorCredentials["isCredentialCorrect"] = true;
        runBot(operatorCredentials);
        setBookingListDisable(false);
      });

  } else {
    operatorCredentials["botEndpoint"]=axiosBot.defaults.baseURL
    axios
      .post(
        `operator/credentials/${operatorCredentials.operator_id}`,
        operatorCredentials
      )
      .then(res => {
        setOpen(true);
        setLoadingSave(false);
        setResponseStatus(res.data.status);
        console.log(res.data);
        if(res.data.code==200){
          operatorCredentials["isCredentialCorrect"] =
          res.data.status == "success" ? true : false;
          runBot(operatorCredentials);
          setBookingListDisable(false);
        }
      
      });
  }
};

export const runBot = operatorCredentials => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  let botPayload = {
    username: operatorCredentials.user_name,
    password: operatorCredentials.password,
    endpoint: apihost,
    data_id: random(20 * 20 * 14, 20 * 52 * 87),
    pxoUser: operatorCredentials.pxoUser,
    isCredentialCorrect: operatorCredentials["isCredentialCorrect"],
    operator_name: operatorCredentials.operator_name,
    operator_id:operatorCredentials.operator_id,
    botEndpoint:axiosBot.defaults.baseURL
  };
  axios({
    method: "POST",
    // url: rq.session.bothost + '/bot/' + extranetId[data.extranet],
    url: "info/",
    data: botPayload,
    timeout: 30000
  })
    .then(function(response) {
      console.log(response);
    })
    .catch(function(error) {});
};
