import axios from "services/axios";

// export function peakpointCookieControllerOld() {
//   const token = localStorage.getItem("token");
//   axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//   axios.get("/operator/credentials/1").then(res => {
//     if (res.data.code !== 200) {
//       window.open(
//         "https://www.peakpoint.travel/Extranet/alojamiento/",
//         "_blank"
//       );
//     }
//   });
// }

export async function peakpointCookieController() {
  axios.post("operator/credentials/1").catch(err => {
    err.response.data.message === "Cookie is invalid" &&
      window.open(
        "https://www.peakpoint.travel/Extranet/alojamiento/",
        "_blank"
      );
  });
}
