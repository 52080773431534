import { normalize, schema } from "normalizr";

const roomData = new schema.Entity(
  "roomData",
  {},
  {
    mergeStrategy: (entityA, entityB) => {
      return { ...entityA, ...entityB };
    },
  }
);
const response = {
  roomData: [roomData],
  addRoomData: [roomData],
};

export const normalizeOperatorRooms = (data) => normalize(data, [response]);
