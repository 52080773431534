import { GET_OFFER_LIST, DELETE_OFFER_LIST,CLEAR_OFFER_LIST } from "./types";

export const getOfferList = res => dispatch => {
  dispatch({
    type: GET_OFFER_LIST,
    payload: res.data
  });
};
export const deleteOfferList = res => dispatch => {
  dispatch({
    type: DELETE_OFFER_LIST,
    payload: res.data
  });
};
export const clearOfferList = () => dispatch => {
  dispatch({
    type: CLEAR_OFFER_LIST
  });
};
