import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import { getStopSale, addStopSale } from "redux/actions/stopSale";
import axios from "./axios";
import axiosLogger from "./axiosLogger";
import axiosBot from "./axiosBot";
import {apihost} from "./axiosBot";

/** PROXOLAB LOGGER * */
import sendLogService from "./proxolabLogger/Logger.js";
import { GET_STOP_SALE } from "redux/actions/types";
import Swal from "sweetalert2";
/** PROXOLAB LOGGER * */

export const getStopSaleService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("stopsale", {
      params: {
        hotelId: id
      }
    })
    .then(res => {
      dispatch(getStopSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const addStopSaleService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("stopsale", data)
    .then(res => {
      dispatch(addStopSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const getStopSaleMongoDBService = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosLogger.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  return axiosLogger({
    method: "post",
    url: `proxolab/api/v1/botclient/lastStopSales`,
    data: data
  })
    .then(res => {
      dispatch(getStopSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const getAllStopSaleMongoDBService = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosLogger.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axiosLogger({
    method: "post",
    url: `proxolab/api/v1/botclient/allStopSales`,
    data: data
  })
    .then(res => {
      dispatch(getStopSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const sendStopSaleService =data=>dispatch=>{
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_STOP_SALE,
    payload: logDataForState(data)
  });
  data["endpoint"] = apihost;
  data["botEndpoint"]=axiosBot.defaults.baseURL
  return axios.post("stopSale",data).then(function(res) {
    dispatch(
      getStopSaleMongoDBService({
        userEmail: data.user.email,
        hotelId: data.hotelId
      })
    );
    if (res.status === 200) {
      dispatch(fetchSuccess());
    }
  })
  .catch(function(error) {
    Swal.fire({
      toast: "true",
      position: "top-end",
      icon: "error",
      title:
        "Operation could not be performed. Please try again!",
      showConfirmButton: false,
      timer: 1500
    });
    dispatch(fetchError());
  });
}

const logDataForState = data => {
  data.operators.forEach(operator => {
    return (operator["status"] = 100);
  });
  const temp = {
    ...temp,
    dates: data["dates"],
    rooms: data["rooms"],
    hotelId: data["hotelId"],
    operators: data.operators
  };
  const newData = {
    ...newData,
    data: temp,
    user: data["user"],
    pId: data["pId"],
    process: data,
    createdAt: new Date()
  };
  return newData;
};
