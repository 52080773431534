import React, { useEffect, useRef, useState } from "react";
import { Card, Grid, Button, Dialog } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { getHotelService } from "services/hotels";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getRoomService } from "services/rooms";
import { getOperatorService } from "services/operator";
import "antd/dist/antd.css";
import { getOperatorRoomsService } from "services/operatorRooms";
import {
  addMatchRoomDataService,
  deleteMatchRoomDataService,
  getMatchRoomsDataService,
} from "services/roomMatch";
import Radio from "@mui/material/Radio";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import MaterialTable, { MTableAction } from "material-table";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Loader from "./Loader";
import AddRoomType from "./AddRoomType";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import { orange, red, green } from "@material-ui/core/colors";
import _ from "lodash";
import { getMatchHotelDataService } from "services/hotelmatch";

const useStyles = makeStyles((theme) => ({
  root: {
    color: localStorage.getItem("theme-type") === "dark" ? "#000" : "#fff",
    //textAlign: '-webkit-center',
    maxWidth: "100%",
    marginLeft: theme.spacing(15),
    marginRight: theme.spacing(15),
    fontSize: "12px!important",
  },
  gridStyle: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: "18%",
    maxWidth: "18%",
  },
  containerGridMTable: {
    marginLeft: theme.spacing(11),
    margin: theme.spacing(5),
    align: "center",
    maxWidth: "80rem",
    //minWidth: "80rem",
    maxHeight: "120rem",
    minHemaxHeight: "120rem",
  },
  globalRoomStyle: {
    border: "2px solid rgba(0, 0, 0, 0.05)",
    borderRadius: "7px",
    margin: theme.spacing(1),
    align: "center",
  },
  containerGrid: {
    margin: theme.spacing(5),
  },
  transfer: {
    backgroundColor:
      localStorage.getItem("theme-type") === "dark" ? "#000" : "#fff",

    marginBottom: theme.spacing(5),
    "&-body": {
      width: "100%",
      display: "flex",
      flex: "auto",
      flexDirection: "column",
      overflow: "hidden",
      "&-search-wrapper": {
        position: "relative",
        flex: "none",
      },
    },
    position: "relative",
    width: "auto",
    height: "auto",

    display: "flex",
    alignItems: "stretch",
  },
  textStyle: {
    width: "100%",
    height: "15px",
    textAlign: "center",
  },
  autoCompleteStyle: {
    width: "100%",
    height: "15px",
    textAlign: "center",
  },
  saveButtonStyle: {
    float: "right",
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: "12px",
  },
  unmapButtonStyle: {
    float: "right",
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#f44336",
    color: "#fff",
  },
  dataGridStyle: {
    marginLeft: theme.spacing(12),
  },

  dialogRoot: {
    "& .MuiDialog-paper": {
      padding: "40px 20px",
    },
  },
  dialogGrid: {
    marginTop: "20px",
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500],
    },
  },
}))(Button);

export default function RoomMatch(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHotelService());
    dispatch(getRoomService());
    dispatch(getOperatorService());
    dispatch(getMatchHotelDataService());
  }, [dispatch]);

  const { roomType } = useSelector(({ rooms }) => rooms.rooms);
  const { types } = useSelector(({ rooms }) => rooms.rooms);
  const { promos } = useSelector(({ rooms }) => rooms.rooms);
  const { subTypes } = useSelector(({ rooms }) => rooms.rooms);
  const { views } = useSelector(({ rooms }) => rooms.rooms);
  const { grades } = useSelector(({ rooms }) => rooms.rooms);
  const { bases } = useSelector(({ rooms }) => rooms.rooms);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { operatorRooms } = useSelector(({ operatorRooms }) => operatorRooms);
  const { operators } = useSelector(({ operators }) => operators);

  const { SHhotels } = useSelector(({ operatorHotels }) => operatorHotels);
  const { DNhotels } = useSelector(({ operatorHotels }) => operatorHotels);

  const { matched_rooms } = useSelector(({ roomMatch }) => roomMatch);

  const [selections, setSelections] = useState({});
  const [selectionRoom, setSelectionRoom] = useState();
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [rooms_by_global, setRooms_by_global] = useState({});
  const [tempOperatorRooms, setTempOperatorRooms] = useState([]);
  const [tempRoomType, setTempRoomType] = useState([]);
  const [radiobutton, setradiobutton] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [flag, setFlag] = useState(false);
  const { matched_hotels } = useSelector(({ hotelMatch }) => hotelMatch);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  let isAuthAdd = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.post === 1
  );
  let isAuthUpdate = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.put === 1
  );
  let isAuthDelete = permissionsByAuthUser.some(
    (permission) => permission.route_id == 2 && permission.delete === 1
  );
  const handleOpen = () => {
    setFlag(true);
  };
  const handleClose = () => {
    setFlag(false);
  };
  const themeCancel = createTheme({
    palette: {
      primary: red,
    },
  });
  const theme = createTheme({
    palette: {
      primary: green,
    },
  });

  const MySwal = withReactContent(Swal);
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text,
    });
  };
  const [columnsRight, setColumnsRight] = useState([
    {
      title: <IntlMessages id="global.rooms" />,
      field: "room_name",
      cellStyle: {
        /* height: "3rem", lineHeight: "37px",*/
        fontSize: "15",
      },
    },
  ]);

  useEffect(() => {
    if (operatorRooms) {
      Object.values(operatorRooms).map((opp) => {
        opp["name"] = opp?.name + " / " + opp?.operatorName?.name;
        if (opp.global_room_id) {
          opp["global_room_name"] =
            types[roomType[opp.global_room_id].type_id].name +
            " " +
            grades[roomType[opp.global_room_id].grade_id].name +
            " " +
            (roomType[opp.global_room_id].base_id > 1
              ? bases[roomType[opp.global_room_id].base_id].name + " "
              : "") +
            (roomType[opp.global_room_id].view_id > 1
              ? views[roomType[opp.global_room_id].view_id].name + " "
              : "") +
            (roomType[opp.global_room_id].sub_type_id > 1
              ? subTypes[roomType[opp.global_room_id].sub_type_id].name + " "
              : "") +
            (roomType[opp.global_room_id].promo_id > 1
              ? promos[roomType[opp.global_room_id].promo_id].name
              : "");
        } else {
          opp["global_room_name"] = "";
        }
      });
      setTempOperatorRooms(operatorRooms);
    }
  }, [operatorRooms]);

  useEffect(() => {
    Object.values(roomType).map((type) => {
      type["room_name"] =
        types[roomType[type.id].type_id].name +
        " " +
        grades[roomType[type.id].grade_id].name +
        " " +
        (roomType[type.id].base_id > 1
          ? bases[roomType[type.id].base_id].name + " "
          : "") +
        (roomType[type.id].view_id > 1
          ? views[roomType[type.id].view_id].name + " "
          : "") +
        (roomType[type.id].sub_type_id > 1
          ? subTypes[roomType[type.id].sub_type_id].name + " "
          : "") +
        (roomType[type.id].promo_id > 1
          ? promos[roomType[type.id].promo_id].name
          : "");
    });
    setTempRoomType(roomType);
  }, [roomType]);

  //bu datanın içine extranetlerin odaları dolacak use effectin içi de ona göre ayarlanacak.
  const [data, setdata] = useState({});
  const classes = useStyles();

  const handleChange = (name, value) => {
    if (name == "hotel_id" && value == undefined) {
      setSelections({ ["hotel_id"]: value /*  ["room_id"]: value  */ });
    } else {
      setSelections({ ...selections, [name]: value });
    }
  };
  useEffect(() => {
    if (selections.hotel_id /* && selections.room_id */) {
      dispatch(
        getOperatorRoomsService(_.map(operators, "id"), selections.hotel_id)
      );
      dispatch(getMatchRoomsDataService(selections));
    }
    setTempOperatorRooms([]);
  }, [selections]);

  const columns = [
    {
      title: <IntlMessages id="room.name" />,
      field: "name",
      align: "left",
      type: "string",
    },
    {
      title: <IntlMessages id="global.room.name" />,
      field: "global_room_name",
      type: "string",
      align: "left",
    },
  ];

  // const handleChangeRadioButton = (event) => {
  //   let room_id = Number(event /* .target.value */);
  //   setSelectionRoom(room_id);
  // };

  const handleChangeOperatorRoom = (event) => {
    let ids = [];
    event.map((e) => {
      ids.push(e.id);
    });
    setTargetKeys({ ...targetKeys, ids });
  };

  const tableRef = React.useRef();

  function roomMatchFunc() {
    if (
      selections.hasOwnProperty("hotel_id") &&
      selectionRoom &&
      targetKeys.hasOwnProperty("ids") &&
      targetKeys.ids.length > 0
    ) {
      if (tableRef.current) {
        tableRef.current.onAllSelected(false);
      }
      dispatch(
        addMatchRoomDataService({
          selections: {
            hotel_id: selections.hotel_id,
            room_id: selectionRoom,
            room_name:
              types[roomType[selectionRoom].type_id].name +
              " " +
              grades[roomType[selectionRoom].grade_id].name +
              " " +
              (roomType[selectionRoom].base_id > 1
                ? bases[roomType[selectionRoom].base_id].name + " "
                : "") +
              (roomType[selectionRoom].view_id > 1
                ? views[roomType[selectionRoom].view_id].name + " "
                : "") +
              (roomType[selectionRoom].sub_type_id > 1
                ? subTypes[roomType[selectionRoom].sub_type_id].name + " "
                : "") +
              (roomType[selectionRoom].promo_id > 1
                ? promos[roomType[selectionRoom].promo_id].name
                : ""),
          },
          matchRooms: targetKeys.ids,
        })
      ).then((res) => {
        if (res === 201) {
          sweetAlerts("success", "Room Matched!");
          setSelectionRoom();
          rightRef.current.onAllSelected(false);
        }
      });
    } else {
      sweetAlerts("warning", "Please select Global Room and Target Room");
    }
  }

  let hotelOptions = Object.values(hotels).filter((item) => {
    return Object.values(matched_hotels).find(
      (m) => m.global_hotel_id == item.id
    );
  });
  let rightRef = useRef();
  return (
    <>
      <Card className={classes.root}>
        <Grid container className={classes.containerGridMTable}>
          <Grid item xs={4} md={8}>
            <Autocomplete
              style={{ paddingTop: "2%" }}
              freeSolo
              name="hotel_id"
              id="selectedHotel"
              options={hotelOptions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={<IntlMessages id="select.global.hotel" />}
                />
              )}
              onChange={(event, value) => handleChange("hotel_id", value?.id)}
            />
          </Grid>
          {/* <Grid
            item
            xs={2}
            md={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ColorButton
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#FFC107",
                color: "#fff",
                fontSize: 10,
                width: "120px",
                height: "35px",
              }}
              onClick={() => handleOpen()}
              color="backgroundColor"
            >
              <IntlMessages id="add.room" />
            </ColorButton>
          </Grid> */}

          {flag == true ? (
            <Dialog
              open={flag}
              className={classes.dialogRoot}
              scroll="body"
              maxWidth="lg"
              fullWidth="on"
              style={{ padding: 30 }}
            >
              <AddRoomType
                handleClose={handleClose}
                isAuthAdd={isAuthAdd}
                isAuthUpdate={isAuthUpdate}
                isAuthDelete={isAuthDelete}
                modalId="roomsModal"
                //paginationda seçilen row per page değeri modal kapanıp açıldığı için buradan props olarak göneriliyor.
                rowPerPage={rowPerPage}
                setRowPerPage={setRowPerPage}
              />
              <Grid
                item
                container
                xs={12}
                className={classes.dialogGrid}
                justifyContent="flex-end"
              >
                <ThemeProvider theme={themeCancel}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                  >
                    <IntlMessages id="close" />
                  </Button>
                </ThemeProvider>
              </Grid>
            </Dialog>
          ) : (
            ""
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.dataGridStyle}>
            <MaterialTable
              columns={columns}
              tableRef={tableRef}
              data={
                selections.hotel_id && selections.hotel_id != undefined
                  ? Object.values(tempOperatorRooms)
                  : []
              }
              options={{
                showTitle: false,
                pageSize: 5,
                pageSizeOptions: [5, 10, 15, 20],
                search: false,
                filtering: true,
                selection: true,
                addRowPosition: "first",
              }}
              onSelectionChange={(change) => {
                handleChangeOperatorRoom(change);
              }}
            />
          </Grid>
          <Grid item xs={4} className={classes.dataGridStyle}>
            <MaterialTable
              options={{
                showTitle: false,
                pageSize: 5,
                pageSizeOptions: [5, 10, 15, 20],
                search: false,
                filtering: true,
                addRowPosition: "first",
                selection: true,
                showSelectAllCheckbox: false,
                selectionProps: (rowData) => ({
                  disabled: selectionRoom ? selectionRoom != rowData.id : false,
                }),
              }}
              tableRef={rightRef}
              localization={{
                header: {
                  actions: "",
                },
              }}
              onSelectionChange={(rows) => {
                if (rows) {
                  setSelectionRoom(rows[0]?.id);
                }
              }}
              data={
                selections.hotel_id && selections.hotel_id != undefined
                  ? Object.values(tempRoomType)
                  : []
              }
              columns={columnsRight}
              actions={[
                {
                  icon: () => (
                    <ColorButton
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#FFC107",
                        color: "#fff",
                        fontSize: 10,
                        width: "120px",
                        height: "35px",
                      }}
                      onClick={() => handleOpen()}
                      color="backgroundColor"
                      disabled={isAuthAdd ? false : true}
                    >
                      <IntlMessages id="add.room" />
                    </ColorButton>
                  ),
                  position: "toolbar",
                  onClick: () => {
                    handleOpen();
                  },
                },
                // {
                //   icon: "radio",
                //   position: "row",
                // },
              ]}
              components={{
                OverlayLoading: () => <Loader />,
                Action: (props) => {
                  return <MTableAction {...props} />;
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <Button
              variant="contained"
              color="primary"
              className={classes.saveButtonStyle}
              disabled={isAuthAdd ? false : true}
              // disabled={
              //   selections.hasOwnProperty("hotel_id") &&
              //   selectionRoom &&
              //   targetKeys.hasOwnProperty("ids") &&
              //   targetKeys.ids.length > 0
              //     ? false
              //     : true
              // }
              onClick={() => {
                roomMatchFunc();
              }}
            >
              <IntlMessages id="SAVE" />
            </Button>
          </ThemeProvider>
          <ThemeProvider>
            <Button
              variant="contained"
              color="error"
              className={classes.unmapButtonStyle}
              disabled={
                targetKeys.hasOwnProperty("ids") && targetKeys.ids.length > 0
                  ? false
                  : true
              }
              onClick={() => {
                dispatch(deleteMatchRoomDataService(targetKeys.ids)).then(
                  (res) => {
                    if (res === 201) {
                      sweetAlerts("success", "Room Deleted Match!");
                      setTargetKeys([]);
                      tableRef.current.onAllSelected(false);
                    }
                  }
                );
              }}
            >
              <IntlMessages id="UNMAP" />
            </Button>
          </ThemeProvider>
        </Grid>
      </Card>
    </>
  );
}
