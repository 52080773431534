import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { useDispatch, useSelector } from "react-redux";
import { updateLoadUser } from "redux/actions/Auth";
import AppContext from "../contextProvider/AppContextProvider/AppContext";
import globalStyles from "../../../theme/GlobalCss";
import { AuhMethods } from "../../../services/auth";
import { CurrentAuthMethod } from "../../constants/AppConstants";
import { LAYOUT_TYPES } from "../../constants/ThemeOptions";
import VerticalDefault from "./VerticalLayouts/VerticalDefault";
import VerticalMinimal from "./VerticalLayouts/VerticalMinimal";
import MinimalNoHeader from "./VerticalLayouts/MinimalNoHeader";
import ModernSideBar from "./VerticalLayouts/ModernSidebar";
import { getRoutesService } from "services/route";
import Loader from "../Contract/Loader";

const useStyles = makeStyles(() => ({
  circularProgressRoot: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const AppLayout = ({ children }) => {
  const [showLayoutLoader, setLayoutLoader] = useState(true);
  const { layout } = useContext(AppContext);
  const { loadUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  globalStyles();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(AuhMethods[CurrentAuthMethod].getAuthUser());
      dispatch(getRoutesService());
    } else {
      dispatch(updateLoadUser(true));
    }
    setLayoutLoader(false);
  }, [dispatch]);

  if (showLayoutLoader || !loadUser) {
    return (
      <div className={classes.circularProgressRoot}>
        <img
          src="/images/plane.gif"
          alt="earth"
          style={{
            width: "200px",
            height: "200px"
          }}
        />
      </div>
    );
  }

  if (
    ["/login", "/master/login", "/choosecompany"].some(url =>
      location.pathname.startsWith(url)
    )
  ) {
    return (
      <div style={{ minHeight: "100vh", width: "100%", display: "flex" }}>
        {children}
      </div>
    );
  }

  switch (layout) {
    case LAYOUT_TYPES.VERTICAL_DEFAULT: {
      return <VerticalDefault children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MINIMAL: {
      return <VerticalMinimal children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MINIMAL_NO_HEADER: {
      return <MinimalNoHeader children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MODERN_SIDEBAR: {
      return <ModernSideBar children={children} />;
    }
    default:
      return <VerticalDefault children={children} />;
  }
};

export default AppLayout;
