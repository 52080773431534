import React from "react";
import { useState } from "react";
import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  Box,
  Toolbar,
  Container,
  Menu
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const DropdownSelection = props => {
  const {
    handleToggleAll,
    handleToggle,
    resetSelectDelete,
    text,
    textOnlyRow
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={e => handleClick(e)}
      >
        Select
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleToggleAll}>
          <ListItemIcon>
            <CopyAllIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={text} />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleToggle}>
          <ListItemIcon>
            <CheckBoxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={textOnlyRow} />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

export default DropdownSelection;
