import React, { useState, useEffect } from "react";
import axios from "services/axios";
import { Grid, Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { style } from "./style";
import MaterialTable from "material-table";
import { createTheme, ThemeProvider, } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { TablePagination } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";



const theme = createTheme({
  palette: {
    backgroundColor: green[400],
  },
});
;
const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[400]),
    backgroundColor: red[400],
    "&:hover": {
      backgroundColor: red[500],
    },
  },
}))(Button);



function AINotification() {

  const [alData, setAlData] = useState();
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count,setCount]=useState(0)


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    axios.get("aiResults", { params: { page: 1 } }).then(function (res) {
      if (res.data.code === 200) {
        setAlData(res.data.data.data)
        setCount(res.data.countData)
      }
    })
    if (!open) {
      setOpen(true);
    }
  }, []);

  const CustomTablePagination = props => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  console.log({ count, page, rowsPerPage, onPageChange })
    const handleFirstPageButtonClick = event => {
      onPageChange(event, 0);
    };
    const handleBackButtonClick = event => {
      axios.get("aiResults", { params: { page: page-1 } }).then(function (res) {
        console.log("resss",res)
        if (res.status === 200) {
          if(res.data.data!=null){
            setAlData(res.data.data.data)
            onPageChange(event, page - 1);
            setCount(res.data.countData)


          }
          
          else{
            setAlData([])
            onPageChange(event, page - 1);

          }
        }
      })
    };
  
    const handleNextButtonClick = event => {
      axios.get("aiResults", { params: { page: page==0 ? page+2:page+1 } }).then(function (res) {
        console.log("resss",res)
        if (res.status === 200) {
          if(res.data.data!=null){
            setAlData(res.data.data.data)
            onPageChange(event, page + 1);
            setCount(res.data.countData)

          }
          
          else{
            setAlData([])
            onPageChange(event, page + 1);
          }
        }
      })
    };
  
    const handleLastPageButtonClick = event => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  
  CustomTablePagination.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  const handleClose = () => {
    setOpen(false);
  };
  const detailHandleClick = (rowData) => {
    setModalData(rowData);
    setOpen(true);
  };

  const tableColumns = [
    {
      title: <IntlMessages id="details" />,
      field: "details",
      render: (rowData) => {
        return (
          <>
            <Button
              variant="contained"
              onClick={() => detailHandleClick(rowData)}
              color="primary"
            >
              <IntlMessages id="details" />
            </Button>
          </>
        );
      },
    },
    {
      title: <IntlMessages id="type" />,
      field: "process_type",
    },
    {
      title: <IntlMessages id="hotel.name" />,
      field: "hotel_name",
    },
    {
      title: <IntlMessages id="status" />,
      field: "status_desc",

    },
  ];

  return (
    <React.Fragment>
      <Grid contianer>
        <Grid item xs={12} className={style.main}>
          <MaterialTable
            columns={tableColumns}
            data={alData}
            title="AI Data"
            components={{
              Pagination: props => (
                <>
                  <TablePagination
                    style={{ display: "flex", justifyContent: "right" }}
                    count={count}
                    rowsPerPage={10}
                    page={page}
                    onPageChange={handleChangePage}
                    ActionsComponent={CustomTablePagination}
                  />
                </>
              )
            }}
            options={{
              search: false,
              actionsColumnIndex: 1,
              addRowPosition: "first",
              pageSize: 10,
              headerStyle: {
                fontSize: "16px",
                fontWeight: "bold",
              },
            }}
          />
        </Grid>
      </Grid>
      {modalData && (
        <Dialog open={open} disableEscapeKeyDown maxWidth="md" fullWidth="on">
          <DialogContent style={{ height: "auto", overflow: "hidden" }}>
            <Grid container spacing={10}>
              <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography fontWeight={"bold"} variant="h6" gutterBottom>
                    Email:
                  </Typography>
                  <Typography
                    fontWeight={"bold"}
                    variant="h6"
                    component="h4"
                    gutterBottom
                  >
                    {modalData?.email}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {modalData?.processes?.map((data) => {
              return (
                <>
                  <Grid container key={data.hotel_id}>
                    <Grid item xs={12} sx={{ padding: "10px !important" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          fontWeight={"bold"}
                          variant="body1"
                          gutterBottom
                        >
                          Hotel Name:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {modalData?.hotel_name}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          fontWeight={"bold"}
                          variant="body1"
                          gutterBottom
                        >
                          Room Name:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.room_name}
                        </Typography>
                      </Box>
                      {data.dates.map((date) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <Typography
                              fontWeight={"bold"}
                              variant="body1"
                              gutterBottom
                            >
                              Dates:
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {`${date.start_date} -  ${date.end_date}`}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Divider
                    sx={{
                      margin: "10px 0px",
                      borderBottomWidth: 2,
                      backgroundColor: "black",
                    }}
                  />
                </>
              );
            })}
            <Grid container spacing={10}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <ThemeProvider theme={theme}>
                  <ThemeProvider theme={theme}>
                    <ColorButton
                      variant="contained"
                      color="backgroundColor"
                      onClick={handleClose}
                    >
                      <IntlMessages id="close" />
                    </ColorButton>
                  </ThemeProvider>
                </ThemeProvider>
                
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
}

export default AINotification;
