import React from "react";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router";
import Login from "routes/Auth/Login";
import { useDispatch, useSelector } from "react-redux";
import Main from "./Main";
import CompanyList from "./CompanyList";
import { AuhMethods } from "services/auth";
import { CurrentAuthMethod } from "@jumbo/constants/AppConstants";
import Button from "@mui/material/Button";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import ProxoLogger from "./ProxoLogger/index";

const modalStyle = {
  main: {
    width: window.innerWidth < 768 ? "90%" : "25vw",
    height: "300px",
    boxShadow: "5px 6px 15px grey",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "10px"
  },
  modalHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "50%",
    fontSize: "20px",
    letterSpacing: "3px",
    textAlign: "center",
    lineHeight: "2.5",
    paddingTop: "20px"
  },
  modalContent: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    height: "50%"
  },
  modalIcon: {
    borderRadius: "50%",
    boxShadow: "0 0 5px #ed6c02, inset 0 0 2px #ed6c02"
  }
};

const Master = ({ match }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const path = location.pathname;
  const logoutPath = path.split("/");

  const masterLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("selectedCompany");
    localStorage.removeItem("userCompanies");
    localStorage.removeItem("selectedOffice");
    localStorage.removeItem("userOffices");
    window.location = "/login";
  };

  const masterLogin = () => {
    history.push("/master");
  };

  if (logoutPath.includes("logout")) {
    return (
      <div style={modalStyle.main}>
        <div style={modalStyle.modalHeader}>
          <QuestionMarkIcon
            style={modalStyle.modalIcon}
            fontSize="large"
            color="warning"
          />
          Do You Want To Logout <br />
          Master Panel{" "}
        </div>
        <div style={modalStyle.modalContent}>
          <Button variant="contained" onClick={masterLogout}>
            Yes
          </Button>
          <Button variant="contained" color="error" onClick={masterLogin}>
            No
          </Button>
        </div>
      </div>
    );
    /*  dispatch(AuhMethods[CurrentAuthMethod].onLogout());
     */
  }

  const onLogoutClick = async () => {
    /* dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    return <Redirect from="master/logout" to="/master/login" />; */
  };

  if (
    location.pathname.startsWith("/master") &&
    location.pathname !== "/master/login" &&
    (!authUser || authUser.authority_level !== 0)
  ) {
    return <Redirect to="/master/login" />;
  }
  if (
    authUser &&
    authUser.authority_level === 0 &&
    location.pathname === "/master/login"
  ) {
    return <Redirect to="/master" />;
  }
  const requestUrl = match.url.replace(/\/$/, "");

  return (
    <Switch>
      <Redirect exact path={requestUrl} to={`${requestUrl}/company`} />
      <Route path={`${requestUrl}/user`} component={Main} />
      <Route path={`${requestUrl}/company`} component={CompanyList} />
      <Route path={`${requestUrl}/login`} component={Login} />
      <Route path={`${requestUrl}/logger`} component={ProxoLogger} />
      {/* <Route path={`${requestUrl}/logout`} render={onLogoutClick} /> */}
    </Switch>
  );
};

export default Master;
