import React from "react";
import TableCell from "@material-ui/core/TableCell";

import TableRow from "@material-ui/core/TableRow";
import {
  CheckCircleOutline,
  Delete,
  Edit,
  MoreHoriz
} from "@material-ui/icons";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { changeDbForSupport } from "services/user";
import { useDispatch } from "react-redux";

import CmtDropdownMenu from "../../../../../@coremat/CmtDropdownMenu";


const useStyles = makeStyles((theme) => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));


const actions = [
  // { action: 'view', label: 'View', icon: <Visibility /> },
  // { action: 'email', label: 'Email', icon: <Mail /> },
  { action: "edit", label: "Edit", icon: <Edit /> },
  {
    action: "changeSupportDB",
    label: "Change DB For Support",
    icon: <CheckCircleOutline />
  },
  { action: "delete", label: "Delete", icon: <Delete /> }
];

const CompanyListRow = props => {
  const {
    row,
    isSelected,
    onCompanyEdit,
    onCompanyDelete,
    onCompanyView
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const onUserMenuClick = (menu) => {
    /* if (menu.action === 'view') {
      onCompanyView(row);
    }  else if (menu.action === 'email') {
    } else if (menu.action === 'suspend') {
    } */

    if (menu.action === "edit") {
      onCompanyEdit(row);
    } else if (menu.action === "changeSupportDB") {
      dispatch(changeDbForSupport(row));
    } else if (menu.action === "delete") {
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
    >
      <TableCell padding="checkbox" />
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Box display="flex" alignItems="center">
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.phone}</TableCell>
      <TableCell>{row.website}</TableCell>
      <TableCell>{row.owner ? row.owner.fullname : "Not"}</TableCell>

      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          items={actions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(CompanyListRow);
