import { normalizeMatchedRooms } from "redux/schemas/matchedRooms";
import { normalizeOperatorRooms } from "redux/schemas/operatorRooms";
import {
  GET_OPERATOR_ROOMS,
  GET_MATCHED_ROOMS_BY_HOTEL,
  UPDATE_OPERATOR_ROOMS,
  ADD_OPERATOR_ROOMS,
} from "../actions/types";

const initialState = {
  operatorRooms: [],
  matched_rooms: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPERATOR_ROOMS:
      const getData = [];
      getData["roomData"] = action.payload;
      const normalizedData = normalizeOperatorRooms([getData]);
      return { ...state, operatorRooms: normalizedData.entities.roomData };
    case GET_MATCHED_ROOMS_BY_HOTEL:
      const normalizedMatchedData = normalizeMatchedRooms(action.payload);
      return {
        ...state,
        matched_rooms: normalizedMatchedData.entities.matchedRooms
          ? normalizedMatchedData.entities.matchedRooms
          : state.matched_rooms,
      };
    case UPDATE_OPERATOR_ROOMS:
      let data = [];
      if (action.payload.key == "add") {
        delete action.payload["key"];
        action.payload.map((action_room) => {
          Object.values(state.operatorRooms).forEach((state_room) => {
            if (state_room.id == action_room.id) {
              state_room["global_room_name"] = action.payload.global_room_name;
            }
          });
          // console.log(
          //   "state.operatorRooms",
          //   state.operatorRooms[action_room.id]
          // );
          // action_room["operatorName"] =
          //   state.operatorRooms[action_room.id].operatorName;
          // action_room["global_room_name"] = action.payload.global_room_name;
          // state.operatorRooms[action_room.id] = action_room;
          // console.log("action_room", action_room);
        });
        data = state.operatorRooms;
      } else {
        delete action.payload["key"];
        action.payload.map((room) => {
          state.operatorRooms[room].global_room_id = null;
          state.operatorRooms[room].global_room_name = null;
        });
        data = state.operatorRooms;
      }
      return { ...state, operatorRooms: data };
    case ADD_OPERATOR_ROOMS:
      const addData = [];
      addData["roomData"] = action.payload;
      addData["addRoomData"] = state.operatorRooms;
      const addNormalizedData = normalizeOperatorRooms([addData]);
      return {
        ...state,
        operatorRooms: addNormalizedData.entities.roomData,
      };

    default:
      return state;
  }
};
