import { fetchStart, fetchError, fetchSuccess } from "redux/actions";
import { getOpenSale, addOpenSale } from "redux/actions/openSale";
import axios from "./axios";
import Swal from "sweetalert2";
import axiosBot from "./axiosBot";
import axiosLogger from "./axiosLogger";
import { apihost } from "./axiosBot";

/** PROXOLAB LOGGER * */
import sendLogService from "./proxolabLogger/Logger.js";
import { GET_OPEN_SALE } from "redux/actions/types";
/** PROXOLAB LOGGER * */

export const getOpenSaleService = id => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .get("opensale", {
      params: {
        hotelId: id
      }
    })
    .then(res => {
      dispatch(getOpenSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const addOpenSaleService = data => dispatch => {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchStart());
  return axios
    .post("opensale", data)
    .then(res => {
      dispatch(addOpenSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      sendLogService.errorlog(e, data);
    });
};

export const getOpenSaleMongoDBService = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosLogger.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axiosLogger({
    method: "post",
    url: `proxolab/api/v1/botclient/lastOpenSales`,
    data: data
  })
    .then(res => {
      dispatch(getOpenSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};
export const getAllOpenSaleMongoDBService = data => dispatch => {
  const token = localStorage.getItem("token");
  axiosLogger.defaults.headers.common.Authorization = `Bearer ${token}`;
  // dispatch(fetchStart());
  return axiosLogger({
    method: "post",
    url: `proxolab/api/v1/botclient/allOpenSales`,
    data: data
  })
    .then(res => {
      dispatch(getOpenSale(res));
      dispatch(fetchSuccess());
    })
    .catch(e => {
      dispatch(fetchError(e.message));
    });
};

export const sendOpenSaleService =data=>dispatch=>{
  console.log(data);
  const token = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch({
    type: GET_OPEN_SALE,
    payload: logDataForState(data)
  });
  data["endpoint"] = apihost;
  data["botEndpoint"]=axiosBot.defaults.baseURL
  return axios.post("openSale",data).then(function(res) {
    dispatch(
      getOpenSaleMongoDBService({
        userEmail: data.user.email,
        hotelId: data.hotelId
      })
    );
    if (res.status === 200) {
      dispatch(fetchSuccess());
    }
  })
  .catch(function(error) {
    Swal.fire({
      toast: "true",
      position: "top-end",
      icon: "error",
      title:
        "Operation could not be performed. Please try again!",
      showConfirmButton: false,
      timer: 1500
    });
    dispatch(fetchError());
  });
}

const logDataForState = data => {
  let temp_operators = [];
  let temp_rooms = [];
  Object.keys(data.operators).forEach(operator => {
    data.operators[operator]["operators"].status = 200;
    temp_operators.push(data.operators[operator]["operators"]);
    data.operators[operator]["rooms"].map(room => {
      if (!temp_rooms.find(troom => troom.id == room.id)) temp_rooms.push(room);
    });
  });
  const temp = {
    ...temp,
    dates: data["dates"],
    //allocation: data["allocation"],
    rooms: temp_rooms,
    hotelId: data["hotelId"],
    operators: temp_operators
  };
  const newData = {
    ...newData,
    data: temp,
    user: data["user"],
    pId: data["pId"],
    process: data,
    createdAt: new Date()
  };
  return newData;
};
