import { GET_RESERVATION_LIST } from "redux/actions/types";
const initialState = {
  reservations: {
    PP: [],
    SH: [],
    DN: [],
    WB: [],
    WEBBEDS: [],
    IVC: [],
    LMT: [],
    JU: []
  }
};

const getReservationList = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESERVATION_LIST:
      return {
        ...state,
        reservations: {
          PP: action.payload ? action.payload.PP : state.reservationList.PP,
          SH: action.payload ? action.payload.SH : state.reservationList.SH,
          DN: action.payload ? action.payload.DN : state.reservationList.DN,
          WB: action.payload ? action.payload.WB : state.reservationList.WB,
          WEBBEDS: action.payload
            ? action.payload.WEBBEDS
            : state.reservationList.WEBBEDS,
          IVC: action.payload ? action.payload.IVC : state.reservationList.IVC,
          LMT: action.payload ? action.payload.LMT : state.reservationList.LMT,
          JU: action.payload ? action.payload.JU : state.reservationList.JU
        }
      };
    default:
      return state;
  }
};

export default getReservationList;
