import { normalize, schema } from "normalizr";

/* const promos = new schema.Array('promos');
const subTypes = new schema.Array('subTypes');
const types = new schema.Array('types');
const views = new schema.Array('views');
const grades = new schema.Array('grades');
const bases = new schema.Array('bases'); */

const promo = new schema.Entity("promos");
const subType = new schema.Entity("subTypes");
const view = new schema.Entity("views");
const grade = new schema.Entity("grades");
const base = new schema.Entity("bases");
const type = new schema.Entity("types");
const roomType = new schema.Entity("roomType", {
  promo_id: [promo],
  sub_type_id: [subType],
  type_id: [type],
  view_id: [view],
  grade_id: [grade],
  base_id: [base]
});

const deneme = new schema.Entity(
  "xyz",
  {},
  {
    fallbackStrategy: (key, schema) => {}
  }
);
const rooms = {
  roomType: [roomType],
  promos: [promo],
  subTypes: [subType],
  types: [type],
  views: [view],
  grades: [grade],
  bases: [base],
  deneme: [deneme]
};

export const normalizeTasks = data => normalize(data, [rooms]);
